import {
  Header,
  Group,
  Burger,
  createStyles,
  Image,
  Button,
  Breadcrumbs,
  Anchor,
  ActionIcon,
  Modal,
  TextInput,
  Tooltip,
  Flex,
} from "@mantine/core";
import { FC, useState, useContext, useEffect, Fragment } from "react";
import { AdjustmentsHorizontal, Flame, Tool } from "tabler-icons-react";
import storeContext from "../store/store";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import useAnalyticsEventTracker from "../hooks/useGoogleAnalyticsTracker";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

interface HeaderMainProps {
  links: { link: string; label: string }[];
  toggleNavbar: () => void;
  toggleFiltersBar: () => void;
  toggleHeatMap: () => void;
  showLinks: boolean;
}
const HeaderMain: FC<HeaderMainProps> = ({
  links,
  toggleNavbar,
  toggleFiltersBar,
  toggleHeatMap,
  showLinks,
}) => {
  const [isFiltersBarActive, setIsFiltersBarActive] = useState(false);
  const [isHeatMapActive, setIsHeatMapActive] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [saveMapName, setSaveMapName] = useState<string>("");

  const { state, dispatch } = useContext<any>(storeContext);
  const { user, teamName } = useAuth();
  const { guid } = useParams();
  const gaEventTracker = useAnalyticsEventTracker("Home Page");
  const isDrawingNewPolygon = !(state.jobId > 0 && state.jobId.length > 0);

  useEffect(() => {
    if (
      !isFiltersBarActive &&
      Object.keys(state.filterDataAlternative).length > 0
    ) {
      setIsFiltersBarActive(() => true);
      setIsHeatMapActive(false);
      toggleFiltersBar();
    }
  }, [state.filterDataAlternative]);

  const openSaveModal = () => {
    setSaveMapName(state.jobName);
    setOpenModal(true);
  };

  const removeInfoWindowFromMapPolygons = () => {
    state.mapPolygons.forEach((polygon: any) => {
      const pPolygon = polygon.polygon;
      delete pPolygon.infoWindow;
    });
  };

  const saveMap = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpenModal(false);
    // if (state.polygons.length || state.annotations.length) {
      let polygonsToSave = state.polygons;
      let encodedCoords: any[] = [];
      polygonsToSave.forEach((polygon: any) => {
        encodedCoords.push(
          google.maps.geometry.encoding.encodePath(polygon.getPath())
        );
      });

      let annotationList: any[] = [];
      state.annotations.forEach((annotation: any) => {
        let annotationCoordinates = annotation["marker"].getPosition();
        annotationList.push({
          id: 0,
          lat: annotationCoordinates.lat(),
          lng: annotationCoordinates.lng(),
          comment: annotation["comment"],
        });
      });

      try {
        let formData = new FormData();
        let filtersData: any = {};
        let languages: any[] = [];

        filtersData.languages = languages;

        Object.keys(state.filterChangeData).find((key: any) => {
          state.filtersDataToSave.forEach((item: string) => {
            let label = item.split(" ");
            let firstPart: string = label[0]?.toLowerCase();
            let secondPart: string = label[1]?.toLowerCase();
            let key1 = key.toLowerCase();
            if (item === "Weekly Income" || item === "Uni Degree") {
              if (key1.includes(secondPart)) {
                filtersData[key] = state.filterChangeData[key];
              }
              return;
            }

            if (item === "Units") {
              if (key1.includes("percentage")) {
                filtersData[key] = state.filterChangeData[key];
              }
              return;
            }

            if (key1.includes(firstPart)) {
              filtersData[key] =
                state.filterChangeData[key] ||
                state.filterChangeData?.languages[key];
            }

            if (key1 === "languages") {
              let langName = item.split(" ")[0];
              state.filterChangeData[key].forEach(
                (lang: {
                  name: string;
                  minValue: number;
                  maxValue: number;
                }) => {
                  if (lang.name === langName) {
                    languages.push(lang);
                  }
                }
              );
            }
            return null;
          });
          return null;
        });
        formData.append("job_id", state.jobId || 0);
        formData.append("job_name", saveMapName);
        formData.append("encoded_polygons", encodedCoords.join(","));
        formData.append("annotationList", JSON.stringify(annotationList));
        formData.append("filter_data", JSON.stringify(state.filterDataAlternative));
        formData.append("demographics_settings", JSON.stringify(state.checkedOutCatalog));

        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}save_job/`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `token ${user.key}`,
          },
        });
        if (state.jobId != response.data.job_id)
          dispatch({ type: "addJobId", payload: response.data.job_id });
        try {
          let hrefParts = window.location.href.split("/");
          if (
            hrefParts?.length < 4 ||
            parseInt(hrefParts[3]) !== response.data.job_id
          )
            window.location.href = "/" + response.data.job_id.toString();
        } catch (e) {
          console.log(e);
        }

        let now = new Date();
        dispatch({
          type: "addUserMap",
          payload: {
            created: now,
            created_by: user.username,
            edited: now,
            id: response.data.job_id,
            job_name: saveMapName,
            last_edited_by: user.username,
          },
        });
      } catch (e) {
        console.log(e);
      }
    // }
  };

  const handleDemographicBuilderClick = () => {
    dispatch({ type: "setDemographicBuilderOpen", payload: true });
  };

  const teamNameToDisplay = () => {
    return (
      <Breadcrumbs
        styles={{
          breadcrumb: { color: "#fff" },
          separator: { color: "#fff" },
        }}
      >
        <Anchor href="/team" key="1">
          {/* Sydney City Council */}
          {teamName?.length > 20 ? teamName.slice(0, 21) + "..." : teamName}
        </Anchor>
        <Anchor
          className={"test"}
          href="#"
          key="2"
          sx={(theme) => ({
            [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
              width: "70px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          })}
        >
          {state.jobName?.length > 20
            ? state.jobName?.slice(0, 21) + "..."
            : state.jobName}
          {/* Inner West Boundary Map */}
        </Anchor>
      </Breadcrumbs>
    );
  };

  return (
    <>
      <Modal
        opened={openModal}
        onClose={() => setOpenModal(false)}
        title="Save This Map"
      >
        <form onSubmit={saveMap} style={{ display: "flex", width: "100%" }}>
          <TextInput
            value={saveMapName}
            onChange={(e) => setSaveMapName(e.target.value)}
            sx={{ width: "80%" }}
            placeholder="Map Name"
            required
          />
          <Button
            type="submit"
            color="#FF8E16"
            sx={(theme) => ({
              backgroundColor: theme.colors.allColors[7],
              "&:hover": {
                backgroundColor: "#f76707",
              },
            })}
          >
            Save
          </Button>
        </form>
      </Modal>

      <Header
        height={{
          base: "8rem",
          xs: "7rem",
          lg: "6.625rem",
          xl: "5.625rem",
        }}
        sx={(theme) => ({
          display: "flex",
          width: "100%",
          padding: "0px 24px",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: theme.colors.allColors[2],
        })}
        fixed={false}
        id="header-main"
      >
        <Group
          sx={(theme) => ({
            display: "flex",
            gap: "3.75rem",
            alignItems: "center",
            flex: "1 0 0",
            [`@media (max-width: 92.5rem)`]: {
              gap: "1rem",
              justifyContent: "flex-start",
            },
          })}
        >
          <Flex align="center" gap="1.5rem">
            <Burger
              opened={false}
              onClick={() => {
                toggleNavbar();
              }}
              size="sm"
              color="white"
              id="toggle-menu"
            />

            <div
              style={{ cursor: "pointer", width: "10rem", height: "2.125rem" }}
              onClick={() => {
                if (!isDrawingNewPolygon) {
                  dispatch({ type: "resetJobId" });
                  dispatch({ type: "resetSummaryStats" });
                  window.polygons = [];
                }
              }}
            >
              <Link to="/">
                <Image
                  src={
                    process.env.REACT_APP_PUBLIC_FOLDER +
                    "Scopomap_Wide_Dark.svg"
                  }
                  alt="scopomap-logo"
                />
              </Link>
            </div>
          </Flex>

          {showLinks && (
            <Flex
              style={{
                display: "flex",
                gap: "1.5rem",
                alignItems: "flex-start",
              }}
            >
              <Group
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  gap: "0",
                }}
              >
                <ActionIcon
                  sx={(theme) => ({
                    display: "flex",
                    width: "3.75rem",
                    height: "2rem",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.625rem",
                    borderRadius: "0.25rem 0rem 0rem 0.25rem",
                    background: isFiltersBarActive ? "#FF7223" : "#333333",
                    "&:hover": {
                      background: "#FF7223",
                    },
                  })}
                  onClick={() => {
                    setIsFiltersBarActive(() => !isFiltersBarActive);
                    setIsHeatMapActive(false);
                    toggleFiltersBar();
                    gaEventTracker("toggling filters", "toggling filters");
                    removeInfoWindowFromMapPolygons();
                  }}
                >
                  <AdjustmentsHorizontal size={24} color="white" />
                </ActionIcon>
                <ActionIcon
                  sx={(theme) => ({
                    display: "flex",
                    width: "3.75rem",
                    height: "2rem",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.625rem",
                    borderRadius: "0rem 0.25rem 0.25rem 0rem",
                    background: isHeatMapActive ? "#FF7223" : "#333333",
                    "&:hover": {
                      background: "#FF7223",
                    },
                  })}
                  onClick={() => {
                    setIsHeatMapActive(() => !isHeatMapActive);
                    setIsFiltersBarActive(false);
                    toggleHeatMap();
                    gaEventTracker("toggling heatmap", "toggling heatmap");
                  }}
                >
                  <Flame size={24} color="white" />
                </ActionIcon>
              </Group>

              <Group
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  gap: "0",
                })}
              >
                <ActionIcon
                  sx={(theme) => ({
                    display: "flex",
                    width: "3.75rem",
                    height: "2rem",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.625rem",
                    borderRadius: "0.25rem",
                    background: "#333333",
                    "&:hover": {
                      background: "#f76707",
                    },
                  })}
                  onClick={() => {
                    gaEventTracker(
                      "buildingDemographics",
                      "Demographic Builder"
                    );
                    handleDemographicBuilderClick();
                  }}
                >
                  <Tool size={24} color="white" />
                </ActionIcon>
              </Group>
            </Flex>
          )}
        </Group>

        {showLinks && (
          <>
            <Group
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.625rem",
                flex: "1 0 0",
                [`@media (max-width: ${theme.breakpoints.sm})`]: {
                  display: "none",
                },
              })}
            >
              {teamNameToDisplay()}
            </Group>

            <Flex
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-end",
                flex: "1 0 0",
                [`@media (max-width: ${theme.breakpoints.sm})`]: {
                  gap: "0.625rem",
                },
              })}
            >
              <Group
                sx={(theme) => ({
                  display: "none",
                  [`@media (max-width: ${theme.breakpoints.sm})`]: {
                    display: "flex",
                  },
                })}
              >
                {
                teamNameToDisplay()
                }
              </Group>

              {guid ? null : (
                <Button
                  type="submit"
                  id="save-btn"
                  color="#FF8E16"
                  sx={(theme) => ({
                    backgroundColor: theme.colors.allColors[7],
                    "&:hover": {
                      backgroundColor: "#f76707",
                    },
                    minWidth: "130px",

                    [`@media (max-width: ${theme.breakpoints.sm})`]: {
                      minWidth: "0px",
                    },

                  })}
                  onClick={() => openSaveModal()}
                >
                  Save
                </Button>
              )}
            </Flex>
          </>
        )}
      </Header>
    </>
  );
};

export default HeaderMain;
