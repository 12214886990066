import { stat } from "fs";

// import { getPolygonClosureUid } from ".";
let mapPolygons: any[] = [];

// SA1Poly structure
interface SA1Poly {
  [key: string]: {
    data: {
      [key: string]: { units: string; value: number; state: number; national: number } | { value: number; units: string };
    };
    coords: string[];
    area_type: string;
  };
}

export const loadSA1sOntoMap = (
  sa1Structures: SA1Poly,
  map: any,
  dispatch: any,
  state: any,
  polygonId: string | undefined
) => {
  let count: number = 0;
  let bounds = new google.maps.LatLngBounds();
  let splittedPolygons: any[] = [];
  let id: string = "";
  mapPolygons = [];

  for (let key in sa1Structures) {
    let coords: any[] = [];
    let d = sa1Structures[key];

    if(d.area_type === 'LGA' || d.area_type === 'SAL') {
      delete sa1Structures.key;
      continue;
    }

    if(polygonId !== d.data.parent_id.toString()) {
      continue;
    }

    if (d.coords.length) {
      id = d.coords[0];
      d.coords.forEach((encodedPath: any) => {
        let singlePolygonPath =
          google.maps.geometry.encoding.decodePath(encodedPath);

        coords.push(singlePolygonPath);
        singlePolygonPath.forEach((latLng: any) => {
          bounds?.extend(latLng);
        });
      });
    }


    let newPolygon = new google.maps.Polygon({
      paths: coords,
      strokeColor: "#A9A9A9",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "green",
      fillOpacity: 0.7,
      // title: 'Polygon',
    });
    if (state.splitPolygonVisibility) newPolygon.setMap(map);
    else newPolygon.setMap(null);

    let polygonData: any = {
      sa1_code: key,  
      //id: id,
      // parentId: polygonId,
    };

    // populate polygonData with the data from the new API
    for (let dataKey in d.data) {
      let data = d.data[dataKey];
      polygonData[dataKey] = data;
    }


    // no parent_id in the new API
    // if (d.parent_id === polygonId) count = count + d.population_count;

    let completedPolygon = {
      polygon: newPolygon,
      data: polygonData,
      selected: true,
      actively_unselected: false,
      //intersected_percentage: d.intersected_percentage,
      //parentId: polygonId,
    };
    splittedPolygons.push(completedPolygon);
    mapPolygons.push(completedPolygon);
  }

  // let unsplitPolygonId: number = getPolygonClosureUid(polygon);
  // dispatch({ type: "setMapPolygons", payload: mapPolygons });
  dispatch({
    type: "setTotalPopulation",
    payload: { unsplitPolygonId: polygonId, population_count: count },
  });

  dispatch({
    type: "setMapPolygons",
    payload: {
      unsplitPolygonId: polygonId,
      mapPolygons: splittedPolygons,
    },
  });
};
