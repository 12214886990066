import axios from "axios";
// import { getPolygonClosureUid } from "../map-utils";

export const fetchLetterBoxCount = async (
  encodedPath: string,
  dispatch: any,
  polygonId: string
) => {
  try {
    dispatch({ type: "setSummaryDataLoading", payload: true });

    let data = new FormData();
    data.append("encoded_geom_str", encodedPath);
    data.append("include_confidence", "True");
    data.append("include_paf_estimate", "True");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}`,
      data
    );

    // let polygonId: number = getPolygonClosureUid(polygon);

    dispatch({
      type: "setLetterBoxData",
      payload: {
        polygonId: polygonId,
        residential_count: response.data["Residential count"],
        // response.data["Letterbox count"] -
        // response.data["Business exact count"],
      },
    });
    dispatch({
      type: "setBusinessesCount",
      payload: {
        polygonId: polygonId,
        businesses_count: response.data["Business exact count"],
      },
    });
    dispatch({
      type: "setLetterBoxCountPAF",
      payload: {
        polygonId: polygonId,
        letterbox_count_PAF: response.data["PAF count"],
      },
    });
    dispatch({ type: "setSummaryDataLoadingLetterboxCounts", payload: false });

    return response.data;
  } catch (err) {
    dispatch({ type: "setSummaryDataLoadingLetterboxCounts", payload: false });
    console.log("Something went wrong", err);
  }
};
