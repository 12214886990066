import axios from "axios";

// TODO: Type safe this
export const fetchSa1sReport = async (areaPortions: any[], catalog: any, onePolygon: boolean) => {
  try {
    if (areaPortions.length) {
      let data = new FormData();
      data.append("sa1_portions", JSON.stringify(areaPortions));
      data.append("one_polygon", onePolygon? 'True' : 'False');

      let selectedFiltersToSend = { tables: catalog.tables };
      data.append("selected_filters", JSON.stringify(selectedFiltersToSend));

      const newAPIResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}demographics/summary_data`,
        data
      );

      return {
        legacyAPI: {}, //response.data,
        newAPI: newAPIResponse.data,
      };
    }
  } catch (err) {
    console.log("Something went wrong", err);
  }
};
