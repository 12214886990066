import {
  Box,
  Button,
  createStyles,
  Grid,
  Menu,
  Modal,
  Pagination,
  Space,
  TextInput,
} from "@mantine/core";
import { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "@mantine/core";
import { Copy, Archive, Share, Trash, Search, Dots } from "tabler-icons-react";

import CreateNewMapModal from "./CreateNewMapModal";

import { useAuth } from "../hooks/useAuth";
import { useStore } from "../hooks/useStore";
import { useNavigate } from "react-router-dom";
import ShareMapModal from "./ShareMapModal";
import axios from "axios";
import MyMapListTour from "./tours/MyMapListTour";
import ScopoLoader from "./ScopoLoader";

const useStyles = createStyles((theme) => ({
  upgradeNowBtn: {
    color: theme.colors.allColors[7],
    backgroundColor: theme.colors.allColors[0],
    "&:hover": {
      backgroundColor: theme.colors.allColors[7],
      color: "white",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      minWidth: "208px",
    },
  },
  tableBody: {
    textAlign: "start",
  },
  boundaryMap: {
    display: "flex",
    alignItems: "center",
  },
  iconDiv: {
    marginRight: "17px",
  },
  userCreation: {
    display: "flex",
    alignItems: "center",
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.colors.allColors[2],
  },
  mapDetails: {
    color: theme.colors.allColors[8],
    opacity: ".54",
  },
  author: {
    color: theme.colors.allColors[8],
  },
  createdAt: {
    color: theme.colors.allColors[8],
    opacity: ".54",
  },
  updatedAt: {
    color: theme.colors.allColors[8],
  },
  backgroundImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '85vh',
    width: "100%",
    margin: '10px',
    background: 'url(./images/login-bg.svg)',
    backgroundSize: 'cover',
    [`@media (max-width: 500px)`]: {
      minHeight: '65vh',
    },
  }
}));

const MyMapsList: FC = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [shareMapOpened, setShareMapOpened] = useState(false);
  const [sharedMapId, setSharedMapId] = useState(0);
  const [userMaps, setUserMaps] = useState([]);
  const [usersFilterMaps, setUsersFilterMaps] = useState([]);
  const { user, teamName } = useAuth();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useStore();
  const [activePage, setActivePage] = useState(1);
  const PER_PAGE = 5;

  const navigate = useNavigate();

  useEffect(() => {
    setUserMaps(state.userMaps);
    setUsersFilterMaps(state.userMaps);
  }, [state.userMaps]);

  useEffect(() => {
    const getMapList = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}my_maps/`,
          {
            headers: {
              Authorization: `token ${user.key}`,
            },
          }
        );
        dispatch({ type: "setUserMaps", payload: response.data.user_maps });
      } catch (err) {
        console.log(err);
      }
      finally {
        setLoading(false);
      }
    };
    getMapList();
  }, [dispatch, user.key]);

  function archiveMap(mapId: string) {
    setUserMaps([]);
    let formData = new FormData();
    formData.append("map_id", mapId.toString());
    formData.append("archived_flag ", "True");

    axios.post(
      `${process.env.REACT_APP_BASE_URL}set_map_archive_flag/`,
      formData,
      {
        headers: {
          Authorization: `token ${user.key}`,
        },
      }
    ).then(() => {
      let unarchivedUserMaps: any[] = [];
      state.userMaps.forEach(function (userMap: any) {
        if (userMap.id !== parseInt(mapId)) unarchivedUserMaps.push(userMap);
      });
      dispatch({ type: "setUserMaps", payload: unarchivedUserMaps });
    });
  }

  function deleteMap(mapId: string) {
    const confirmResult = window.confirm(
      "Are you sure you want to delete this map?"
    );
    if (confirmResult === true) {
      setUserMaps([]);
      let formData = new FormData();
      formData.append("map_id", mapId.toString());
      formData.append("deleted_flag ", "True");

      axios.post(
        `${process.env.REACT_APP_BASE_URL}set_map_deleted_flag/`,
        formData,
        {
          headers: {
            Authorization: `token ${user.key}`,
          },
        }
      ).then(() => {
        let unarchivedUserMaps: any[] = [];
        state.userMaps.forEach(function (userMap: any) {
          if (userMap.id !== parseInt(mapId)) unarchivedUserMaps.push(userMap);
        });
        dispatch({ type: "setUserMaps", payload: unarchivedUserMaps });
      });
    }
  }

  const handleChange = (value: string) => {
    if (value.length >= 3) {
      const newUserMaps = userMaps.filter((ele: any) =>
        ele.job_name.toLowerCase().includes(value.toLowerCase())
      );
      setUsersFilterMaps(newUserMaps);
    }
    if (value.length === 0) {
      setUsersFilterMaps(userMaps);
    }
  };

  // table head JSX
  const ths = (
    <tr>
      <th>MAP</th>
      <th>CREATED</th>
      <th>UPDATED</th>
      <th></th>
      <th></th>
    </tr>
  );

  const rows = [...usersFilterMaps]
    .splice((activePage - 1) * PER_PAGE, PER_PAGE)
    .map((map: any) => (
      <tr key={map.id}>
        <td
          onClick={() => {
            dispatch({ type: 'resetSummaryStats' })
            navigate(`/${map.id}`)
          }
          }
          style={{ cursor: "pointer" }}
        >
          <div className={classes.boundaryMap}>
            <div className={classes.iconDiv}> </div>
            <div className="info-map-div">
              <div className={classes.title}>{map.job_name}</div>
              <div>
                <div className={classes.mapDetails}></div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className={classes.userCreation}>
            <div className={classes.textDiv}>
              <span className={classes.author}>{map.created_by}</span>{" "}
              <span className={classes.createdAt}>
                {new Date(map.created).toLocaleDateString()}
              </span>
            </div>
          </div>
        </td>
        <td className={classes.updatedAt}>
          <div className={classes.userCreation}>
            <div className={classes.textDiv}>
              <span className={classes.author}>{map.last_edited_by}</span>{" "}
              <span className={classes.createdAt}>
                {new Date(map.edited).toLocaleDateString()}
              </span>
            </div>
          </div>
        </td>
        <td></td>
        <td>
          <Menu
            transitionProps={{ transition: 'pop', duration: 150 }}
            position="bottom-start" 
            arrowPosition="center"
            styles={{
              itemLabel: { color: "#414042" },
            }}
          >

            <Menu.Target>
              <Box
                style={{ cursor: "pointer", marginRight: "17px", }}
              >
                <Dots
                  size={15}
                  color={'#414042'}
                />
              </Box>
            </Menu.Target>

            <Menu.Dropdown>

              <Menu.Item
                icon={<Copy size={14} />}
                onClick={() => navigate(`/${map.id}/copy`)}
              >
                Duplicate
              </Menu.Item>
              <Menu.Item
                icon={<Archive size={14} />}
                onClick={() => {
                  archiveMap(`${map.id}`);
                }}
              >
                Archive
              </Menu.Item>
              <Menu.Item
                icon={<Trash size={14} />}
                onClick={() => {
                  deleteMap(`${map.id}`);
                }}
              >
                Delete
              </Menu.Item>
              <Menu.Item
                icon={<Share size={14} />}
                onClick={() => {
                  setSharedMapId(parseInt(`${map.id}`));
                  setShareMapOpened(true);
                }}
              >
                Share
              </Menu.Item>
            </Menu.Dropdown>

          </Menu>
        </td>
      </tr>
    ));

  const mapListHeader = (
    <Grid mt="md">
      <Grid.Col
        lg={6}
        sm={4}
        style={{
          textAlign: "start",
          fontSize: "22px",
          fontWeight: "bold",
        }}
      >
        {teamName} - Map List
      </Grid.Col>
      <Grid.Col lg={3} sm={4}>
      </Grid.Col>
      <Grid.Col lg={3} sm={4}>
        <Link to="/">
          <Button className={classes.upgradeNowBtn}>Create New Map</Button>
        </Link>
      </Grid.Col>
    </Grid>
  )

  if (loading) {
    return (
      <>
        {mapListHeader}
        <ScopoLoader rows={4} />
      </>
    );
  }

  return (
    <>
      {mapListHeader}
      <Space h="lg" />
      <Space h="lg" />
      {state.userMaps.length > 0 ? (
        <div>
          <Grid>
            <Grid.Col lg={3}>
              <TextInput
                radius="lg"
                placeholder="Search"
                icon={<Search size={18} strokeWidth={2} color={"black"} />}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Grid.Col>
          </Grid>
          <div className="table-responsive">
            <Table
              className="table-inner"
              verticalSpacing="xl"
              captionSide="bottom"
              mb="xl"
              mt="sm"
              pb="lg"
            >
              <thead>{ths}</thead>
              <tbody className={classes.tableBody}>{rows}</tbody>
            </Table>
          </div>
          {
            (state.userMaps.length > PER_PAGE) &&
            <Pagination
              total={Math.ceil(state.userMaps.length / PER_PAGE)}
              position="center"
              className="pagination"
              value={activePage}
              onChange={setActivePage}
            />
          }
        </div>
      )
        :
        <div className={classes.backgroundImage}>
          <Button
            className="create-ist-map-btn"
            color="#FF8E16"
            size="xl"
            sx={(theme) => ({
              backgroundColor: theme.colors.allColors[7],
              "&:hover": {
                backgroundColor: "#f76707",
              },
              minWidth: "130px",
            })}
            onClick={() => setOpened(true)}
          >
            Create First Map
          </Button>
        </div>
      }
      <MyMapListTour />
      
      <Modal
        centered
        padding={0}
        opened={shareMapOpened}
        onClose={() => setShareMapOpened(false)}
        title="Share this Map"
      >
        <ShareMapModal close={setShareMapOpened} sharedMapId={sharedMapId} />
      </Modal>

      <Modal
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        padding={0}
        withCloseButton={false}
        size="lg"
      >
        <CreateNewMapModal />
      </Modal>
    </>
  );
};

export default MyMapsList;
