import { useAuth } from "../hooks/useAuth";

import Main from "./Main";

const SALMap = () => {
  const { user } = useAuth();

  return <Main isSharedMap={false} isLGAMap={false} isSALMap={true} isSA1Map={false} loggedIn={user} />;
};

export default SALMap;
