import axios from "axios";

async function reactivateOrCancelSubscription(
  cancelAtPeriodEnd: boolean | null,
  key: string
) {
  try {
    let url: string = cancelAtPeriodEnd
      ? `subscriptions/reactivate_subscription`
      : `subscriptions/cancel_subscription`;

    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}${url}/`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `token ${key}`,
      },
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export { reactivateOrCancelSubscription };
