// Import axios and types
import axios, { AxiosResponse } from "axios";
import { FilterTable } from "../types";

interface UserKey {
  userKey: string;
}

interface SaveSettingsData {
  demographicsSettingsJson: string;
  settingsName: string;
  organisationDefault: string;
}

export interface UpdateSettingsData extends SaveSettingsData {
  demographicsSettingsId: string;
}

interface SetMapDemographicsData {
  demographicsSettingsId: string;
  mapId: string;
}

interface DemographicsSettingsId {
  demographicsSettingsId: string;
}

const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

const getTokenHeader = (userKey: string) => ({
  Authorization: `token ${userKey}`,
});

const appendToFormData = (data: Record<string, any>): FormData => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  return formData;
};

export async function saveDemographicsSettings(
  data: SaveSettingsData,
  userKey: string
) {
  const formData = appendToFormData({
    demographics_settings_json: data.demographicsSettingsJson,
    settings_name: data.settingsName,
    organisation_default: data.organisationDefault,
  });

  try {
    const response = await API.post(
      "demographics/save_demographics_settings",
      formData,
      {
        headers: getTokenHeader(userKey),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving demographics settings:", error);
    throw error;
  }
}

export async function updateDemographicsSettings(
  data: UpdateSettingsData,
  userKey: string
) {
  const formData = appendToFormData({
    demographics_settings_json: data.demographicsSettingsJson,
    settings_name: data.settingsName,
    organisation_default: data.organisationDefault,
    demographics_settings_id: data.demographicsSettingsId,
  });

  try {
    const response = await API.post(
      "demographics/save_demographics_settings",
      formData,
      {
        headers: getTokenHeader(userKey),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating demographics settings:", error);
    throw error;
  }
}

export async function deleteDemographicsSettings(
  { demographicsSettingsId }: DemographicsSettingsId,
  userKey: string
) {
  const formData = appendToFormData({
    demographics_settings_id: demographicsSettingsId,
  });

  try {
    const response = await API.post(
      "demographics/delete_demographics_settings",
      formData,
      {
        headers: getTokenHeader(userKey),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting demographics settings:", error);
    throw error;
  }
}

export async function setMapDemographicsSettings(
  data: SetMapDemographicsData,
  userKey: string
) {
  const formData = appendToFormData({
    demographics_settings_id: data.demographicsSettingsId,
    map_id: data.mapId,
  });

  try {
    const response = await API.post(
      "demographics/set_map_demographics_settings",
      formData,
      {
        headers: getTokenHeader(userKey),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error setting map demographics settings:", error);
    throw error;
  }
}

export async function fetchDemographicsSettingById(
  { demographicsSettingsId }: DemographicsSettingsId,
  userKey: string
) {
  try {
    const response = await API.get(
      `demographics/get_demographics_settings/${demographicsSettingsId}`,
      {
        headers: getTokenHeader(userKey),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching demographics setting by ID:", error);
    throw error;
  }
}

// get filter catalogue from server via axios
export const getAvaliableCatalogue = async () => {
  let catalog: FilterTable[] = [];

  await axios
    .get(`${process.env.REACT_APP_BASE_URL}demographics/filters_catalogue`)
    .then((response) => {
      catalog = getAvailableFilters(response);
    })
    .catch((error) => {
      console.error("Error fetching filters from server: ", error);
    });

  return catalog;
};

const getAvailableFilters = (axiosresponse: AxiosResponse<any, any>) => {
  
  const transformed: FilterTable[] = [];

  // Transform the response data into the format we need
  axiosresponse.data.tables.forEach((table: FilterTable) => {
    // populate the transformed object
    transformed.push(table);
  });

  return transformed;
};

// Fetch Demographics Settings List
export async function fetchDemographicsSettingsList(state: any, dispatch: any, { userKey }: UserKey) {
  try {
      // if(state.userDemographicSettingsList.length > 0)
      //   return state.userDemographicSettingsList;
      const response = await API.get('demographics/get_demographics_settings_list', {
          headers: getTokenHeader(userKey),
      });

      dispatch({
        type: "setUserDemographicSettingsList",
        payload: response.data,
      });
    
      return response.data;
  } catch (error) {
      console.error('Error fetching demographics settings list:', error);
      throw error;
  }
}