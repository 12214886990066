import AppShellLayout from "../components/AppShellLayout";
import MyMapsList from "../components/MyMapsList";

const MapList = () => {
  return (
    <AppShellLayout>
      <MyMapsList />
    </AppShellLayout>
  );
};

export default MapList;
