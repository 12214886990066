/* eslint-disable eqeqeq */
import axios from "axios";
import ExportMapComponent from "../components/report-export-components/ExportMapComponents";
import { downloadAsBase64, downloadAsImage } from "../components/report-export-components/ComponentExporter";
import React, { useContext, useState } from 'react';
import { createRoot } from "react-dom/client";
import storeContext from "../store/store";

import {
  Dataset,
  FilterData,
  FilterDataWithResponses,
  FilterType,
  SubCategory,
  Table,
} from "../components/filterbar-utils/filtertypes"

type mapProps = {
  polygonList: any;
  paddingPercent?: Array<number>;
  context?: any;
};

const getMapSA1CSV = (context : {state:any, dispatch:any}) => {
  const { state, dispatch } = context;
  const sa1LevelRawData = getSA1LevelRawData(state);
  const rawData = getRawData(state);
  var dataText = '';
  dataText = 'Summary Data\n';
  dataText += 'Powered by Scopomap\n';
  dataText += 'SA1 Code,SA1 on Scopomap,Population Included,Median Age,Weekly Household Income,Unemployment Percentage,People with University Degree,Indigenous Population,Apartments Percentage,Rental Properties Percentage,Dwellings Owner Outright Percentage,Dwellings Owned with Mortgage Percentage,' + state.summaryData.languages[0].name + ',' + state.summaryData.languages[1].name + ',' + state.summaryData.languages[2].name + ',' + state.summaryData.methodsOfTravel[0].name + ',' + state.summaryData.methodsOfTravel[1].name + ',' + state.summaryData.methodsOfTravel[2].name + ',' + state.summaryData.methodsOfTravel[3].name + ',' + state.summaryData.methodsOfTravel[4].name + '\n';
  sa1LevelRawData.forEach((sa1: any) => {
    dataText += sa1.sa1_code + ',https://app.scopomap.com.au/SA1/' + sa1.sa1_code + ',' + sa1.population_count.toString() + ',' + sa1.medianAge.toString() + ',' + sa1.income.toString() + ',' + sa1.unemployed.toString() + ',' + sa1.uniDegree.toString()
      + ',' + sa1.indigenous.toString() + ',' + sa1.unitPercentage.toString() + ',' + sa1.rentalProperties.toString() + ',' + sa1.ownedOutright.toString() + ',' + sa1.ownedWithMortgage.toString()
      + ',' + (sa1[state.summaryData.languages[0].name] || 0).toString() + ',' + (sa1[state.summaryData.languages[1].name] || 0).toString() + ',' + (sa1[state.summaryData.languages[2].name] || 0).toString()
      + ',' + (sa1[state.summaryData.methodsOfTravel[0].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[1].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[2].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[3].name] || 0).toString() + ',' + (sa1[state.summaryData.methodsOfTravel[4].name] || 0).toString() + '\n';
  });
  return dataText;
}

const getNewAPIMapSummaryCSV = (context : {state:any, dispatch:any}) => {
  const { state, dispatch } = context;
  const rawData = state.summaryDataNewAPI;
  var dataText = 'Summary Data\n';
  dataText += 'Powered by Scopomap\n';
  dataText += 'Category,Area,State Average,National Average\n';
  rawData.tables.forEach((table: any) => {
    dataText += table.category_title + '\n';
    table.sub_categories.forEach((subCategory: any) => {
      subCategory.filters.forEach((filter: any) => {
        const filterData = filter as FilterDataWithResponses;
        console.log(filter.filter_title);
        
        if(filterData.responses === undefined) {
          const simpleFilterData = filter as FilterData;
          dataText += filterData.filter_title + ',' +
            (simpleFilterData.value||0*100) + '%,'+
            (simpleFilterData.state||0*100) + '%,'+
            (simpleFilterData.national||0*100) + '%\n';
        }
        else {
          dataText += filter.filter_title + '\n';
          filterData.responses.sort((a, b) => b.area - a.area);
          for(var i=0; i<filterData.top_answers; i++) {
            dataText += filterData.responses[i].filter_name + ',' +
              (filterData.responses[i].area*100) + '%,'+
              (filterData.responses[i].state*100) + '%,'+
              (filterData.responses[i].national*100) + '%\n';
          }
        }
      });
    });
  });
  return dataText;
}

const getMapSummaryCSV = (context : {state:any, dispatch:any}) => {
  const { state, dispatch } = context;
  const rawData = getRawData(state);
  var dataText = 'Summary Data\n';
  dataText += 'Powered by Scopomap\n';

  // Header
  dataText = 'Summary Data\n';
  dataText += 'Powered by Scopomap\n';
  dataText += 'Item,Area,State Average,National Average,Units\n';

  // At a glance
  dataText += 'Residential dwellings' + ',' + rawData.residential.toString() + '\n';
  dataText += 'Businesses' + ',' + rawData.businesses.toString() + '\n';
  dataText += 'Letterboxes' + ',' + rawData.letterboxes.toString() + '\n';
  dataText += 'Population' + ',' + rawData.population.toString() + '\n';

  let summary_data = state.summaryDataNewAPI;
  summary_data.tables.forEach((table: any) => {
    let filterCount = 0;
    let categoryTitle = table.category_title;
    // dataText += '\n' + categoryTitle + '\n';
    table.sub_categories.forEach((subCategory: any) => {
      let subCategoryTitle = subCategory.sub_category_title;
      // dataText += ',' + subCategoryTitle + '\n';
      subCategory.filters.forEach((filter: any) => {
        filterCount++;
        let filterTitle = filter.filter_title;
        if(filter.responses === undefined)
          dataText += filterTitle + ',' + filter.value.toString() + ',' + filter.state.toString() + ',' + filter.national.toString() + ',' + filter.units + '\n';
        else {
          // dataText += ',,' + filterTitle + '\n'
          let topResponses = filter.responses.slice(0, filter.top_answers);
          topResponses.forEach((topResponse: any) => {
            dataText += filterTitle + ' - ' + topResponse.filter_name + ',' + topResponse.area + ',' + topResponse.state + ',' + topResponse.national + ',' + topResponse.units + '\n';
          });
        }
      });
    });
  });

  return dataText;  
}

const downloadSummary = async (format: string, context : {state:any, dispatch:any} , user: any) => {

  // decompose context
  const { state, dispatch } = context;

  const rawData = getRawData(state);
  const sa1LevelRawData = getSA1LevelRawData(state);

  const properties = {
    polygonList: state.polygons,
    paddingPercent: [25, 25, 25, 25],
    context: { state, dispatch },
  }

  let mapImageString = await downloadMapAsBase64(state, properties);

  var dataText = 'Summary Data\n';

  if (format == 'sa1s') {
    // console.log(JSON.stringify(state.mapPolygons));
    dataText = 'CSV Report\n';
    dataText += 'Powered by Scopomap\n';
    dataText += 'SA1 Code,SA1 on Scopomap,Population Included,';
    let filterNameList : string[] = [];
    let summary_data = state.summaryDataNewAPI;
    summary_data.tables.forEach((table: any) => {
      table.sub_categories.forEach((subCategory: any) => {
        subCategory.filters.forEach((filter: any) => {
          if(filter.responses === undefined) {
            let filterName = filter.filter_name;
            let filterTitle = filter.filter_title;
            filterNameList.push(filterName);
            dataText += filterTitle + ',';
          }
          else {
            let topResponses = filter.responses.slice(0, filter.top_answers);
            topResponses.forEach((topResponse: any) => {
              let filterName = topResponse.filter_name;
              filterNameList.push(filterName);
              dataText += filterName + ',';
            });
          }
        });
      });
    });
    dataText += '\n';
    state.mapPolygons.forEach((sa1: any) => {
      console.log(JSON.stringify(sa1.data));
      dataText += sa1.data.sa1_code + ',https://app.scopomap.com.au/SA1/' + sa1.data.sa1_code + ',' + sa1.data.population_count.toString() + ',';
      filterNameList.forEach((filterName: string) => {
        if(Object.keys(sa1.data).indexOf(filterName) > -1)
          dataText += sa1.data[filterName].value.toString() + ',';
        else
          dataText += '0,';
      });
      dataText += '\n';
    });
    let filename = 'SA1s.csv';
    var blob = new Blob([dataText], { type: "text/csv" });
    downloadDataAsFile(blob, filename);
    return;
  }

  var peopleCSV = '';
  var dwellingsCSV = '';
  var headerCSV = '';


  if (format == 'Word') {
    let formData = new FormData();
    formData.append("dummy", 'Dummy text');
    formData.append("raw_data", JSON.stringify(rawData));
    formData.append("csv_header", headerCSV);
    formData.append("csv_people_data", peopleCSV);
    formData.append("csv_dwellings_data", dwellingsCSV);
    formData.append("map_name", state.jobName);
    formData.append("summary_data", JSON.stringify(state.summaryDataNewAPI));
    if(typeof mapImageString === "string")
      formData.append("map_image_string", mapImageString);
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}demographics/summary_report/`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `token ${user.key}`,
      },
      responseType: 'blob',
    });
    let data = response.data;
    let filename = "summary.docx";
    var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    downloadDataAsFile(blob, filename);
    return;
  }

  if (format == 'CSV') {
    let filename = "summary.csv";
    let dataText = getMapSummaryCSV(context);
    var blob = new Blob([dataText], { type: "text/csv" });
    downloadDataAsFile(blob, filename);
    return;
  }
  
}


function downloadDataAsFile(blob: Blob, filename: string) {
  let navigator = window.navigator as any;
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

function getSA1LevelRawData(state: any) {
  let sa1s: any[] = [];
  state?.mapPolygons?.forEach((p: any) => {
    sa1s.push(p.data);
  });
  return sa1s;
}

function getRawData(state: any) {
  const rawData = {
    residential: state.letterBoxData?.residential_count || 0,
    businesses: state.businessesCount?.businesses_count || 0,
    letterboxes: state.letterBoxCountPAF?.letterbox_count_PAF,
    population: state.summaryDataNewAPI.population || 0,
    area: state.polygonsArea,
    

  }
  return rawData;
}

/**
 * 
 *  downloadMapAsBase64(yourExportMapComponentProps)
  *.then(base64String => {
  *  // Use the base64String for whatever you need here
  *  console.log(base64String);
  *})
  *.catch(error => {
  *  // Handle the error here
  *  console.error(error);
 * });
 * 
 * @param props 
 * @returns 
 */

const downloadMapAsBase64 = async (state: any, props: mapProps): Promise<string | null> => {
  // Create a container for rendering
  const container = document.createElement('div');
  const uniqueId = 'export-map-component-container-export';
  container.id = uniqueId;
  container.style.position = 'absolute';
  container.style.top = '-999px'; // Position offscreen
  container.style.left = '-999px';
  container.style.width = '1000px';
  container.style.height = '100%';
  document.body.appendChild(container);

  // Create a root for the container
  const root = createRoot(container);

  return new Promise<string | null>((resolve, reject) => {
    // Decompose props
    const { polygonList, paddingPercent, context } = props;

    // re-compose props with callback function
    const newProps = {
      polygonList,
      paddingPercent,
      context,
      callback: async (width: number, height: number) => {
        try {
          console.log("width: " + width + " height: " + height);
          // resize container/root to state.heatMapExportSize.height and state.heatMapExportSize.width
          document.getElementById(uniqueId)?.setAttribute('style', `width: ${width}px; height: ${height}px;`);

          // Convert the map to base64
          const base64String = await downloadAsBase64("exportmapComponent");
          resolve(base64String);
          // downloadAsImage("exportmapComponent", 'image'); // comment out in production
        } catch (error) {
          reject(error);
        } finally {
          // Cleanup after the operation is complete
          root.unmount();
          document.body.removeChild(container);
        }
      }
    };

    // Render the ExportMapComponent offscreen
    root.render(<ExportMapComponent {...newProps} />);
  });
};

export { downloadSummary, downloadMapAsBase64, getMapSA1CSV, getMapSummaryCSV, getNewAPIMapSummaryCSV };