// import { getPolygonClosureUid } from ".";

let markers: any[] = [];
async function addTextInsideShape(
  polygon: any,
  center: any,
  map: any,
  label: number
) {
  if (markers.length > 0) {
    markers.forEach((marker: any) => {
      if (marker.id === polygon.id) {
        marker.setMap(null);
      }
      return null;
    });
  }

  let marker: any = new google.maps.Marker({
    position: center,
    map: map,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 0,
    },
    label: {
      text: label.toString() || "0",
      className: "marker-label",
      fontSize: "15px",
      color: "green",
      fontWeight: "bold",
    },
  });

  // let polygonId: number = getPolygonClosureUid(polygon);
  let polygonId: number = polygon.id;

  // let polygonPath: any = polygon.getPath();
  if (polygonId) {
    marker.id = polygonId;
  }

  markers.push(marker);
  polygon.bindTo("center", marker, "position");
  //   marker.setLabel(label.toString() || "0");
}

function removeTextInsideShape(polygon: any) {
  if (markers.length > 0) {
    markers.forEach((marker: any) => {
      if (marker.id === polygon.id) {
        marker.setMap(null);
      }
      return null;
    });
  }
}

export { addTextInsideShape, removeTextInsideShape };
