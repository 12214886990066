function downloadKML(mapPolygons: any[]) {
  var data = `<?xml version="1.0" encoding="UTF-8"?>
      <kml xmlns="http://www.opengis.net/kml/2.2">
        <Placemark>
          <name>Map XML</name>`;
  mapPolygons.forEach((p) => {
    data += `
          <Polygon>
            <extrude>1</extrude>
            <altitudeMode>relativeToGround</altitudeMode>
            <outerBoundaryIs>
              <LinearRing>
                <coordinates>`;
    var polygon = p;
    var path = polygon.getPath();
    path.forEach((point: any) => {
      data +=
        `
                  ` +
        point.lng().toString() +
        "," +
        point.lat().toString() +
        ",100";
    });
    var point = path.getAt(0);

    data += `
                </coordinates>
              </LinearRing>
            </outerBoundaryIs>
          </Polygon>`;
  });
  data += `
        </Placemark>
      </kml>
          `;
  let filename = "map.kml";
  var blob = new Blob([data], { type: "text/csv" });
  let navigator = window.navigator as any;
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

export { downloadKML };