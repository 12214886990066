import {
  Breadcrumbs,
  createStyles,
  Grid,
  LoadingOverlay,
  Space,
  Table,
  Text,
  TextInput,
  Menu,
} from "@mantine/core";
import axios from "axios";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppShellLayout from "../components/AppShellLayout";
import { useAuth } from "../hooks/useAuth";
import { fetchUserSubscriptionDetails } from "../map-utils/fetchUserSubscriptionDetails";
import { SubscriptionDetail } from "../types";
import { reactivateOrCancelSubscription } from "../map-utils/reactivateOrCancelSubscription";
import { showNotification } from "@mantine/notifications";
import { Check, X, Settings as Settings2 } from "tabler-icons-react";
import EditProfileInfo from "../components/EditProfileInfo";

const useStyles = createStyles((theme) => ({
  button: {
    backgroundColor: "#FD7E14",
    minWidth: "160px",
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      width: "100%",
    },
  },
  tableHead: {
    backgroundColor: '#F4F7FC',
  },
  tableRow: {
    border: '2px solid #ccc',
    '& > *': {
      fontSize: '15px !important',
      padding: '10px 0',
      color: '#000 !important',
    }
  },
  settingDropDown: {
    position: "absolute",
    top: '90px',
    right: '180px',
  },
  settingIcon: {
    maxWidth: '530px',
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'space-between',
  },
  settingIconDetails: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  settingItems: {
    fontSize: '16px',
    fontWeight: 'normal',
    padding: '12px 12px',
    '&:hover': {
      background: '#F4F7FC'
    }
  },
  iconSetting: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  detailsTitle: {
    fontSize: '24px'
  }
}));

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];


const SubscriptionsReport = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [adminViewing, setAdminViewing] = useState<boolean>(false);
  const [adminViewingAnother, setAdminViewingAnother] = useState<boolean>(false);
  const { classes } = useStyles();

  const navigate = useNavigate();
  let startPeriod = new Date()
  const endPeriod = new Date();
  const dayOfMonth = startPeriod.getDate();
  startPeriod.setDate(dayOfMonth - 30);
  const [startDate, setStartDate] = useState<Value>(startPeriod);
  const [endDate, setEndDate] = useState<Value>(endPeriod);
  const [lastMonthCounter, setLastMonthCounter] = useState<number>(0);
  const [thisFYCounter, setThisFYCounter] = useState<number>(0);
  const [lastFYCounter, setLastFYCounter] = useState<number>(0);
  const [tableRows, setTableRows] = useState<JSX.Element[]>([]);

  useEffect(() => {
    let formData = new FormData();
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}subscriptions/subscription_report/`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `token ${user.key}`,
      },
    }).then((response) => {
      let dataRows: any[] = response.data;
      const rows = dataRows.map((element) => (
        <tr key={element.org_id}>
          <td>{element.organisation_name}</td>
          <td>{element.org_id}</td>
          <td>{element.current_period_end}</td>
          <td>{element.stripe_customer_id}</td>
          <td>{element.subscription_id}</td>
          <td>{element.stripe_subscription_id}</td>
          <td>{element.card_expiry}</td>
        </tr>
      ));
      setTableRows(rows);
    }, (error) => {
      console.log(error);
    });
  }, []);

  return (
    <AppShellLayout>
      <LoadingOverlay
        visible={loading}
        loaderProps={{ size: "xl", color: "orange" }}
        overlayOpacity={0.7}
      />

      <h1>Subscriptions Report</h1>
      <Table>
        <thead>
          <tr>
            <th>Organisation Name</th>
            <th>Organisation ID</th>
            <th>Expiry</th>
            <th>Stripe Customer</th>
            <th>Subscription ID</th>
            <th>Stripe Subscription</th>
            <th>Card(s) Expiry</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </Table>


    </AppShellLayout>
  );
};

export default SubscriptionsReport;


