import { MantineProvider, ButtonStylesParams } from '@mantine/core';
import { rem, em } from '@mantine/core';
import { Routes, Route, useParams } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
// import Main from './pages/Main';
import MapList from "./pages/MapList";
// import MapTest from "./pages/MapTest";
import Signup from "./pages/Signup";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./pages/404";
import Pricing from "./pages/Pricing";
import Team from "./pages/Team";
import MapCopy from "./pages/MapCopy";
import Layout from "./components/Layout";
import SharedMaps from "./pages/SharedMaps";
import ArchivedMaps from "./pages/ArchivedMaps";
import SALMapSelection from "./pages/SALMapSelection"
import LGAMapSelection from "./pages/LGAMapSelection"
import SharableMaps from "./pages/SharableMaps";
import ViewMap from "./pages/ViewMap";
import LGAMap from "./pages/LGAMap";
import SALMap from "./pages/SALMap";
import SA1Map from "./pages/SA1Map";
import SubscriptionSuccess from "./pages/SubscriptionSuccess";
import ChangePassword from "./pages/ChangePassword";
import { Notifications } from "@mantine/notifications";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import ContactUs from "./pages/ContactUs";
import RequestDemoOrQuote from "./pages/RequestDemoOrQuote";
import AcceptTeamInvitation from "./pages/AcceptTeamInvitation";
import Settings from "./pages/Settings";
import AddressDownloadsReport from "./pages/AddressDownloadsReport";
import SubscriptionsReport from "./pages/SubscriptionsReport";
import GlobalStyles from "./components/GlobalStyles";
import { StrictMode, useEffect } from "react";
import ReactGA from "react-ga4";
import NotificationsLayout from "./components/NotificationsLayout";

// const TRACKING_ID = "UA-253558680-1"; // OUR_TRACKING_ID
const TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID;
ReactGA.initialize(`${TRACKING_ID}`);


function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "" });
  }, []);

  return (
    <MantineProvider
      theme={{
        fontFamily: "DIN 2014",
        headings: {
          fontFamily: "DIN 2014",
        },

        primaryColor: 'orange',

        // Classnames added here
        components: {
          Pagination: {
            styles: () => ({
              // Define styles for the pagination item
              control: {
                gap: 0,
                color: "#414042",
                borderRadius: 0,

                // Style for the active item
                '&[data-active]': {
                  backgroundColor: "#FF8E16",
                },
              },
              // Define styles for the pagination dots
              dots: {
                border: "1px solid #dee2e6",
              },
            }),
          },

          Button: {
            styles: (theme, params) => ({
              root: {
                // Apply styles based on the button variant
                ...(params.variant === 'filled' ? {
                  backgroundColor: '#FF8E16', // Default state
                  borderColor: '#FF8E16',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#FD7E14',
                  },
                } : params.variant === 'outline' ? {
                  borderColor: '#FF8E16',
                  color: '#FF8E16',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: '#FF8E16',
                    color: '#fff',
                  },
                } : {}),
              },
            }),
          },
        },

        colors: {
          allColors: [
            "#FFFFFF",
            "#BCBEC0",
            "#414042",
            "#FFBF00",
            "#FF6C1C",
            "#FF3905",
            "#000000",
            "#FF8E16",
            "#292A2C",
            "#222b45",
          ],

        },

        fontSizes: {
          xs: '0.75rem',
          sm: '0.875rem',
          md: '1rem',
          lg: '1.125rem',
          xl: '1.25rem',
        },

        spacing: {
          xs: '0.625rem',
          sm: '0.75rem',
          md: '1rem',
          lg: '1.25rem',
          xl: '2rem',
        },

        radius: {
          xs: '0.125rem',
          sm: '0.25rem',
          md: '0.5rem',
          lg: '1rem',
          xl: '2rem',
        },

      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <Notifications />
      <GlobalStyles />

      <div className="App">
        <Routes>
          <Route element={<NotificationsLayout />}>
            <Route path="/signup/:guid" element={<Signup />} />
            <Route path="/signup" element={<RequestDemoOrQuote />} />
            {/* <Route path="/signup" element={<Signup />} /> */}
            <Route path="/login" element={<Login />} />
            <Route
              path="/reset-password/:uid/:token"
              element={<ResetPassword />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/book-demo-request-quote" element={<RequestDemoOrQuote />} />
            <Route path="/sharable-maps/:guid" element={<ViewMap />} />
            <Route
              path="/maplist"
              element={
                <ProtectedRoute>
                  <MapList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/shared-maps"
              element={
                <ProtectedRoute>
                  <SharedMaps />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sharable-maps"
              element={
                <ProtectedRoute>
                  <SharableMaps />
                </ProtectedRoute>
              }
            />
            <Route
              path="/archived-maps"
              element={
                <ProtectedRoute>
                  <ArchivedMaps />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SALs"
              element={
                <ProtectedRoute>
                  <SALMapSelection />
                </ProtectedRoute>
              }
            />
            <Route
              path="/LGAs"
              element={
                <ProtectedRoute>
                  <LGAMapSelection />
                </ProtectedRoute>
              }
            />
            <Route
              path="/LGA/:lgaCode16"
              element={
                <ProtectedRoute>
                  <LGAMap />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SAL/:salCode21"
              element={
                <ProtectedRoute>
                  <SALMap />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SA1/:sa1Code7Digit"
              element={
                <ProtectedRoute>
                  <SA1Map />
                </ProtectedRoute>
              }
            />
            <Route
              path="/team"
              element={
                <ProtectedRoute>
                  <Team />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/adderss-downloads-report"
              element={
                <ProtectedRoute>
                  <AddressDownloadsReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscriptions-report"
              element={
                <ProtectedRoute>
                  <SubscriptionsReport />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/:id"
                element={
                  <ProtectedRoute>
                    <MapOr404 />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/:id/copy"
                element={
                  <ProtectedRoute>
                    <MapCopy />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/change-password"
                element={
                  <ProtectedRoute>
                    <ChangePassword />
                  </ProtectedRoute>
                }
              />

              <Route path="/pricing" element={<Pricing />} />
              <Route
                path="/subscription-success/:checkoutSessionId"
                element={<SubscriptionSuccess />}
              />
              <Route path="/subscription-cancel/:checkoutSessionId" element={<Pricing />} />
              <Route
                path="/join-team-invite/:guid"
                element={<AcceptTeamInvitation />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </MantineProvider>
  );
}

function MapOr404() {
  let params = useParams();
  let mapId = params.id?.match(/\d+/);
  if (!mapId) {
    return <NotFound />;
  }
  return <Home />;
}

export default App;
