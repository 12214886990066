import {
 Button,
 TextInput,
 createStyles,
 Text,
} from "@mantine/core";
import React, { useState, FC } from 'react'
import { Edit } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";

const useStyles = createStyles((theme) => ({

    form: {
        display: "flex",
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'flex-start',
        width:"100%",
        maxWidth: "1160px",
        gap: '20px',
        // [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        //     flexDirection:'row',
        // },
    },

    editModal: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

  editModalInputWrapper: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '5px',
    flexWrap: 'wrap',
  },

  editModalContainer: {
    '& > *':{        
        borderBottom: '1px solid rgb(200, 200, 200)',
        padding: '10px 0px',
        maxWidth: '530px',
    },
  },

  inputSectionWrapper: {
    margin: '5px 0px',
    width: '100%',
    maxWidth: '100%',
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
        maxWidth: '49%',
      },
  },

  detailsTitle: {
    fontSize: '26px'
  }
}));

type EditProfileProps = {
  username: string;
  orgname: string;
  adminViewingAnother: boolean
};

const EditProfileInfo: FC<EditProfileProps> = ({ username, orgname, adminViewingAnother=false }) => {

  const { classes } = useStyles();
  const [usernameEditClicked, setUsernameEditClicked] = useState<boolean>(false);
  const [orgNameEditClicked, setOrgNameEditClicked] = useState<boolean>(false);
  const [currentUsername, setCurrentUsername] = useState<string>(username);
  const [currentOrgName, setCurrentOrgName] = useState<string>(orgname);
  const [newUsername, setNewUsername] = useState<string>('');
  const [newOrgName, setNewOrgName] = useState<string>('');
  const [orgNameError, setOrgNameError] = useState<string>('');
  const [usernameError, setUsernameError] = useState<string>('');
  const { user, changeLocalUsername, setTeamName } = useAuth();


  const navigate = useNavigate();

  const handleNewOrgName = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!(newOrgName.length >= 3)) {
        setOrgNameError("Organisation name must be at least 3 characters long");
        return;
    };
    if (newOrgName.trim() !== '') {
        setOrgNameEditClicked(false)
        let formdata = new FormData();
        formdata.append("new_organisation_name", newOrgName);
        try {
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}change_organisation_name/`,
                formdata,
                {
                    headers: {
                        Authorization: `token ${user.key}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setCurrentOrgName(newOrgName);
            setTeamName(newOrgName);
        } catch(err) {
            console.error(err);
        } finally {
            setNewOrgName('');
            setOrgNameError('');
        }
    }
  }

  const handleNewUsername = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!(newUsername.length >= 6)) {
        setUsernameError("Username must be at least 6 characters long");
        return;
    };
    if (newUsername.trim() !== '') {
        setUsernameEditClicked(false);
        let formdata = new FormData();
        formdata.append("new_username", newUsername);
        try{
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}change_username/`,
                formdata,
                {
                    headers: {
                        Authorization: `token ${user.key}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setCurrentUsername(newUsername);
            changeLocalUsername(newUsername);
        } catch(err){
            console.error(err);
        } finally {
            setUsernameError('');
            setNewUsername('');
        }
    }
  }

  const renderOrgNameEditing = () => {
    return
    {orgNameEditClicked && !adminViewingAnother && (
        <div className={classes.editModalInputWrapper}>
        <TextInput
            value={newOrgName}
            onChange={(e) => setNewOrgName(e.target.value)}
            sx={{ width: "80%" }}
            placeholder="Enter New Organisation Name"
            required
            error={orgNameError}
        />
        <Button
            disabled={newOrgName.trim() === ''}
            type="submit"
            color="#FF8E16"
            sx={(theme) => ({
            backgroundColor: theme.colors.allColors[7],
            "&:hover": {
                backgroundColor: "#f76707",
            },
            })}
            onClick={handleNewOrgName}
        >
            Save
        </Button>
        <Button
            color="#FF8E16"
            sx={(theme) => ({
            backgroundColor: theme.colors.allColors[7],
            "&:hover": {
                backgroundColor: "#f76707",
            }
            })}
            onClick={()=> setOrgNameEditClicked(false)}
        >
            cancel
        </Button>
        </div>
        )}
        {!orgNameEditClicked && !adminViewingAnother && <div className={classes.editModal}>
        <div>
            <Text style={{fontWeight: 100, fontSize: '13px', color:'#6C6C6C'}}>
                organisation name
            </Text>
            <Text style={{fontWeight: 600, fontSize: '18px'}}>
                {currentOrgName}
            </Text>
        </div>
        <Edit
            size={24}
            strokeWidth={2}
            cursor={'pointer'}
            color={'#FF8E16'}
            onClick={() => setOrgNameEditClicked(true)}
            />
        </div>};
  }

  return (
    <>
        <form className={classes.form}>
            <div className={classes.inputSectionWrapper}>
                {/* Username */}
                <div className={`${classes.editModalContainer}`}>
                    {usernameEditClicked && !adminViewingAnother && (
                    <div className={classes.editModalInputWrapper}>
                    <TextInput
                        value={newUsername}
                        onChange={(e) => setNewUsername(e.target.value)}
                        sx={{ width: "80%" }}
                        placeholder="New username"
                        required
                        error={usernameError}
                    />
                    <Button
                        disabled={newUsername.trim() === ''}
                        type="submit"
                        color="#FF8E16"
                        sx={(theme) => ({
                        backgroundColor: theme.colors.allColors[7],
                        "&:hover": {
                            backgroundColor: "#f76707",
                        }
                        })}
                        onClick={ handleNewUsername }
                    >
                        Save
                    </Button>
                    <Button
                        color="#FF8E16"
                        sx={(theme) => ({
                        backgroundColor: theme.colors.allColors[7],
                        "&:hover": {
                            backgroundColor: "#f76707",
                        }
                        })}
                        onClick={()=> setUsernameEditClicked(false)}
                    >
                        cancel
                    </Button>
                    </div>
                    )}
                    {!usernameEditClicked && <div className={classes.editModal}>
                    <div>
                        <Text style={{fontWeight: 100, fontSize: '13px', color:'#6C6C6C'}}>
                            Username
                        </Text>
                        <Text style={{fontWeight: 600, fontSize: '18px'}}>
                            {currentUsername}
                        </Text>
                    </div>
                    {!adminViewingAnother &&
                    <Edit
                        size={24}
                        strokeWidth={2}
                        color={'#FF8E16'}
                        cursor={'pointer'}
                        onClick={()=> setUsernameEditClicked(true)}
                    />
                    }
                    </div>}
                    {/* Password */}
                    {!usernameEditClicked && <div className={classes.editModal}>
                    <div>
                        <Text style={{fontWeight: 100, fontSize: '13px', color:'#6C6C6C'}}>
                        Password
                        </Text>
                        <Text style={{fontWeight: 600, fontSize: '18px'}}>
                            ********
                        </Text>
                    </div>
                    {!adminViewingAnother &&
                    <Edit
                        size={24}
                        strokeWidth={2}
                        color={'#FF8E16'}
                        cursor={'pointer'}
                        onClick={()=> navigate("/change-password")}
                    />
                    }
                    </div>}
                </div>
            </div>
            {/* Organisation Name */}
            <div className={classes.inputSectionWrapper}>
                <div className={`${classes.editModalContainer}`}>
                    {orgNameEditClicked && (
                    <div className={classes.editModalInputWrapper}>
                    <TextInput
                        value={newOrgName}
                        onChange={(e) => setNewOrgName(e.target.value)}
                        sx={{ width: "80%" }}
                        placeholder="New team name"
                        required
                        error={orgNameError}
                    />
                    <Button
                        disabled={newOrgName.trim() === ''}
                        type="submit"
                        color="#FF8E16"
                        sx={(theme) => ({
                        backgroundColor: theme.colors.allColors[7],
                        "&:hover": {
                            backgroundColor: "#f76707",
                        },
                        })}
                        onClick={handleNewOrgName}
                    >
                        Save
                    </Button>
                    <Button
                        color="#FF8E16"
                        sx={(theme) => ({
                        backgroundColor: theme.colors.allColors[7],
                        "&:hover": {
                            backgroundColor: "#f76707",
                        }
                        })}
                        onClick={()=> setOrgNameEditClicked(false)}
                    >
                        cancel
                    </Button>
                    </div>
                    )}
                    {!orgNameEditClicked && <div className={classes.editModal}>
                    <div>
                        <Text style={{fontWeight: 100, fontSize: '13px', color:'#6C6C6C'}}>
                            Team Name
                        </Text>
                        <Text style={{fontWeight: 600, fontSize: '18px'}}>
                            {currentOrgName}
                        </Text>
                    </div>
                    {!adminViewingAnother &&
                    <Edit
                        size={24}
                        strokeWidth={2}
                        cursor={'pointer'}
                        color={'#FF8E16'}
                        onClick={() => setOrgNameEditClicked(true)}
                        />
                    }
                    </div>}
                </div>
            </div>
        </form>
    </>)
}

export default EditProfileInfo