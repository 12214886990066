import React, { FC, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useElemetHeight from "../hooks/useElementHeight";
import TopNotification from "./TopNotification";


const Layout: FC = () => {
  let { user } = useAuth();
  let elementRef = useRef<any>(null);
  const elementHeight = useElemetHeight();

  return (
  <div className="main-wrapper">
    <div className="notficationWrapper" ref={elementRef}>
    {
      user?.subscriptionDetails?.subscription_name === 'Trial' ?
      (<TopNotification  subscriptionDetails ={user.subscriptionDetails} />) : null
    }
    </div>
    <Outlet />
  </div>
  )
};

export default Layout;
