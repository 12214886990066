import {
  Button,
  createStyles,
  Divider,
  Group,
  Text,
  Textarea,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { FC, ReactEventHandler, useState } from "react";
import { Check } from "tabler-icons-react";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import { url } from "inspector";
import { json } from "stream/consumers";
import { relative } from "path";
import { event } from "react-ga";
import EmailAddressInput from "./EmailAddressInput";
import VerifiedAddress from "./VerifiedAddress";




const useStyles = createStyles((theme) => ({
  createMapWrapper: {
    overflow: "hidden",
  },
  // createMapWrapperInner:{
  //   maxHeight:20,
  // },
  bodyText: {
    lineHeight: "26.8px",
    marginBottom: 25,
  },
  addOther: {},
  modalWrapper: {
    zIndex: 10, // Adjust this value as needed
  },
  notificationWrapper: {
    zIndex: 20, // Higher z-index to ensure it appears on top of the modal
    position: "fixed",
    top: 20,
    right: 20,
  },
  
}));

const ShareMapModal = (props: any) => {
  const { classes } = useStyles();
  const [count, setCount] = useState(0);
  const [inputMail, setInputMail] = useState("");
  const [emailAddress, setEmailAddress] = useState<string[]>([]);
  const [message, setMessage] = useState<any>("");
  const { user } = useAuth();

  

  const handleRemoveEmail = (indexToRemove: number) => {
    const updatedEmails = [...emailAddress]; 
    updatedEmails.splice(indexToRemove, 1); 
    setEmailAddress(updatedEmails); 
    setCount(count-1);
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) =>
  {
    if(e.key === 'Enter'){
      handleAddAddress();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  const handleSetEmail = (value: string) => {
    setInputMail(value);

    //console.log("" + index + value + " has an error: " + emails[index].error);
  };

  const handleSetMessage = (value: String) => {
    setMessage(value);
  };

  const isValidAddress = () => {
    let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if(filter.test(inputMail))
      {
        return true;
      }
    return false;
  };

  const isAlreadyInRecepients = () => {
    for (let i = 0; i< count; i++)
      {
        if (inputMail == emailAddress[i])
        {
          return true;
        }
      }
      return false;
  };

  const isArrayEmpty = () => {
    return (emailAddress.length==0);
  }

  const handleAddAddress = () =>
  {
    if ((isValidAddress())&&(!isAlreadyInRecepients())){
      setEmailAddress([...emailAddress, inputMail]);
      setCount(count+1);
      setInputMail("");
    };
  }


  const handleSendInvitation = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    // console.log(props.sharedMapId.toString());
    // return;
    let emailList = [];
    let foundError = false;
    

    for (let i = 0; i < count; i++) {
      //console.log(isValidAddress(emails[i].value));
      emailList.push(emailAddress[i]);
    }
    if (!isAlreadyInRecepients()&&isValidAddress())
    {
      console.log(inputMail + " is supposed to be sent a mail too")
      emailList.push(inputMail);
    }
    else if (!(inputMail===''))
    {
      foundError = true;
    }
    if (!foundError) {
      try {
        const formData = new FormData();
        console.log(emailList.join(","));
        formData.append("to_email", emailList.join(","));
        formData.append("job_id", props.sharedMapId.toString());
        formData.append("share_message", message);

        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}share_map/`,
          data: formData,
          headers: {
            Authorization: `token ${user.key}`,
          },
        });
        console.log(response);
        props.close(false);

        showNotification({
          id: "map-share-notification",
          title: "Map sharing Email Sent",
            message: '',
          autoClose: 3000,
          color: "green",
          icon: <Check />,
          styles: (theme) => ({
            root: {
              backgroundColor: theme.white,
              borderColor: "green",
              "&::before": { backgroundColor: theme.white },
            },
            title: { color: "black" },
            description: { color: "black" },
            closeButton: {
              color: theme.black,
            },
          }),
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
    <div className="modal-wrapper">
      <Text size="sm" color="#777777" className={classes.bodyText}>
        Share this map with people outside your Scopomap team. Enter their email
        addresses below.
      </Text>
      {/* <Group className={classes.createMapWrapper}>
         <Group className={classes.createMapWrapperInner}> */}
      <form
      onSubmit={handleSendInvitation}>
        {[...Array(count)].map((_, ind) => {
          return (
            <div style={{
              position: "relative",
              display: "flex",
              width: 400,
              minHeight: 30,
              margin: 1,

              }}>
              <VerifiedAddress
              address = {emailAddress[ind]}
              index = {ind}
              remove={handleRemoveEmail}
              />

              
  {/*            
              <Group position="left" key={ind}>
                  <Tooltip label = {emailAddress[ind]}>
                    <div style={{
                      position:"relative",
                      width: 200,
                      marginLeft:4,
                      marginRight: 0,
                      fontSize: 14,
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      borderRadius: '15px',
                      border: '1px solid gray',
                      paddingLeft: '4px', // Adjust the padding as needed
                      paddingRight: '20px',
                      textAlign: 'left', // Align the text to the left
                    }}

                    >
                      
                      {emailAddress[ind].slice(0, 24) }
                    

                    </div>
                  </Tooltip>
                  <CloseButton size = 'xs'
                  onClick={()=>{
                    handleRemoveEmail(ind)
                    setCount(count-1)
                  }}
                  style={{
                  position: "absolute",
                  right: 204,
                  }}aria-label="Close modal" />
              </Group>

                */}
            </div> 
          );
        })}
        <Group
          position="center"
          className={classes.addOther}
          align="center"
          pt={6}
          pb={13}
        >
            <div style = {{
            position: 'relative',
            width: "100%",
            verticalAlign: "top"
            }}>
          <EmailAddressInput
          value={inputMail}
          onChange={handleSetEmail}
          checkAddAddress={handleAddAddress}
          isValidAddress={isValidAddress}
          isAlreadyInRecepients={isAlreadyInRecepients}
          onPressEnter={handleEnterKey}
          isArrayEmpty={isArrayEmpty}
        />

            </div>
 {/*           <Space h="xs" />            
            <Plus style={{ marginRight: "4px" }} size={14} />{" "}          
            <ActionIcon
            styles={{
              root: {
                width: "auto",
                minWidth: "auto",
                borderBottom: "1px solid",
                borderRadius: "0",
                lineHeight: "6px",
                height: "auto",
                minHeight: "auto",
              },
            }}
            >


            <Text
              color="#777777"
              size="sm"
              align="center"
              //onClick={() => setCount(count + 1)}
              onClick={() => {
                handleAddAddress()
              }}
            >
              {" "}
              Add recipient
            </Text>
            </ActionIcon>
*/}
            </Group>
            <Textarea
              onChange={(e) => handleSetMessage(e.target.value)}
              rows={5}
              style={{ width: "100%" }}
              placeholder="Enter message to recipients..."
            />
            <Divider my="sm" />

            <Group position="right" pt={10}>
              <Button
                style={{ minWidth: "130px" /* Outlined button**/}}
                variant="outline"
                onClick={() => props.close(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{ minWidth: "130px" }}
                variant="filled"
                onClick={handleSendInvitation}
              >
                Share
          </Button>
        </Group>
      </form>
      </div>

    </>
    
  );
};

export default ShareMapModal;
