import React, { FC, useEffect, useState } from "react";
import {
  AppShell,
  createStyles,
  Drawer,
  Grid,
  Group,
  Title,
  useMantineTheme,
  Text,
  Card,
  Button,
} from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import HeaderMain from "../components/HeaderMain";
import FiltersBar from "../components/FiltersBar";
import Navbar from "../components/Navbar";
import HeatMap from "../components/HeatMap";
import { useAuth } from "../hooks/useAuth";
import useElemetHeight from "../hooks/useElementHeight";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { Star } from "tabler-icons-react";
import AppShellLayout from "../components/AppShellLayout";

const useStyles = createStyles((theme) => ({
  pricingCardWrapper: {
    boxShadow: "0px 2px 10px rgb(0 0 0 / 8%)",
    color: "#292A2C",
    border: "1px solid transparent",
    transition: ".3s ease-in-out",
    borderRadius: "3px",
    padding: "30px 10px",
    "&:hover": {
      border: "1px solid #FF8E16",
    },
  },
  cardTitle: {
    fontSize: "32px",
    width: "100%",
    position: "relative",
    lineHeight: "42px",
    paddingBottom: "0",
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      fontSize: "20px",
      lineHeight: "27px",
    },
  },
  cardDesc: {
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
  },
}));

const dummyLinks = [
  { link: "/about", label: "Features" },
  { link: "/pricing", label: "Pricing" },
  { link: "/learn", label: "Learn" },
  { link: "/community", label: "Community" },
];

const SubscriptionSuccess: FC = () => {
  const theme = useMantineTheme();
  const [openFiltersBar, setOpenFiltersBar] = useState(false);
  const [openNavbar, setOpenNavbar] = useState(false);
  const [openHeatMap, setOpenHeatMap] = useState(false);
  const elementHeight = useElemetHeight();
  let { checkoutSessionId } = useParams();
  const { user } = useAuth();
  const { classes } = useStyles();
  const [, setUserInfo] = useLocalStorage("user", user);

  useEffect(() => {
    const handleCheckout = async () => {
      let data = new FormData();
      data.append("checkout_session_id", checkoutSessionId || "");
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}subscriptions/checkout_success/`,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `token ${user.key}`,
        },
      });
      user.subscriptionDetails = response.data;
      setUserInfo(user);
    };
    handleCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleNavbar = () => {
    setOpenNavbar(() => !openNavbar);
  };

  const toggleFiltersBar = () => {
    if (openHeatMap) {
      setOpenHeatMap(() => false);
    }
    setOpenFiltersBar(() => !openFiltersBar);
  };

  const toggleHeatMap = () => {
    if (openFiltersBar) {
      setOpenFiltersBar(() => false);
    }
    setOpenHeatMap(() => !openHeatMap);
  };

  return (
    <AppShellLayout
    // padding={0}
    // styles={{
    //   main: {
    //     background: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
    //     paddingLeft: openNavbar ? 0 : "0",
    //     position: "relative",
    //     height: `calc(100vh - var(--mantine-header-height, 0px) - ${elementHeight}px)`,
    //   },
    // }}
    // navbarOffsetBreakpoint="sm"
    // asideOffsetBreakpoint="sm"
    // fixed={false}
    // navbar={
    //   <>
    //     {" "}
    //     <Drawer
    //       size={300}
    //       opened={openNavbar}
    //       onClose={() => setOpenNavbar(false)}
    //       overlayOpacity={0.25}
    //       overlayColor={theme.colorScheme === "dark" ? theme.colors.dark[9] : "#292A2C"}
    //       classNames={{
    //         root: "drawerRootWrapper",
    //         overlay: "drawerOverlay",
    //         drawer: "customDrawer",
    //         closeButton: "drawerCloseButton",
    //       }}
    //     >
    //       <Navbar opened={false} />
    //     </Drawer>
    //     {openFiltersBar ? <FiltersBar /> : undefined}
    //     {openHeatMap ? <HeatMap /> : undefined}
    //   </>
    // }
    // header={
    //   <HeaderMain
    //     links={dummyLinks}
    //     toggleNavbar={toggleNavbar}
    //     toggleFiltersBar={toggleFiltersBar}
    //     toggleHeatMap={toggleHeatMap}
    //     showLinks={false}
    //   />
    // }
    >
      <Grid justify={"center"} p="xl" align={"center"}>
        <Grid.Col sm={8} lg={6}>
          <Card shadow="lg" p="xl" className={classes.pricingCardWrapper}>
            <Group position="center" spacing={3}>
              <Star fill="#ff8e16" stroke="#ff8e16" size={80} />
              <Title className={classes.cardTitle} order={3} align="center">
                Thank you for subscribing!
              </Title>
              <Text size="sm" className={classes.cardDesc}>
                You have successfully subscribed to scopomap.
              </Text>
            </Group>
            <Group position="center" pt={30}>
              <Link to="/">
                <Button
                  style={{ minWidth: "230px" }}
                  variant="filled"
                >
                  Go Home!
                </Button>
              </Link>
            </Group>
          </Card>
        </Grid.Col>
      </Grid>
    </AppShellLayout>
  );
};

export default SubscriptionSuccess;
