declare global {
  interface Window {
    onChangeHandler: () => void;
    sendComment: (index: number) => void;
    prevInfowindow: any;
    polygons: any[];
    handleDelete: () => void;
    handleEdit: () => void;
    polygonsInfoWindows: any;
    toggleAreaSelection: (e: any) => any;
    generateAddressList: (e: any) => any;
    sendAnnotation: (e: any) => void;
    businessLocations: any[];
  }
}
window.polygons = [];
window.businessLocations = [];
window.polygonsInfoWindows = null;
const styles = [
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
];

const mapSetup = (
  mapRef: any,
  zoomLevel: number,
  center: { lat: number; lng: number }
) => {
  const map = new google.maps.Map(mapRef.current, {
    center: new google.maps.LatLng(center.lat, center.lng),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      position: google.maps.ControlPosition.TOP_RIGHT,
      // position: google.maps.ControlPosition.BOTTOM_LEFT,
    },
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
      // position: google.maps.ControlPosition.BOTTOM_LEFT,
    },
    zoom: zoomLevel,
    clickableIcons: false,
    styles: styles,
    fullscreenControl: false,
  });

  const drawingMngr = new google.maps.drawing.DrawingManager({
    drawingMode: null,
    drawingControl: false,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [
        google.maps.drawing.OverlayType.POLYGON,
        google.maps.drawing.OverlayType.CIRCLE,
        google.maps.drawing.OverlayType.MARKER,
      ],
    },
    markerOptions: {
      icon: "images/marker-icon.svg",
    },
  });
  drawingMngr.setMap(map);
  return { map, drawingMngr };
};

export default mapSetup;
