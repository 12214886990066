import { useState, useEffect } from "react";

type SizeType = {
  width: number | undefined;
  height: number | undefined;
};
// Hook
export default function useElementSize(elementId: string) {
  const [elementSize, setElementSize] = useState<SizeType>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    let element: HTMLElement | null = document.getElementById(elementId);
    function handleResize() {
      setElementSize({
        width: element?.offsetWidth,
        height: element?.offsetHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [elementId]);
  return elementSize;
}
