import { FC, useState } from "react";
import { useForm } from "@mantine/form";
// import { Link } from "react-router-dom";
import {
  Grid,
  TextInput,
  Text,
  Group,
  Button,
  Avatar,
  Stack,
  //   Divider,
  //   Anchor,
  //   Alert,
} from "@mantine/core";
import "../css/login.css";
// import { gapi } from "gapi-script";
// import axios from "axios";
import BrandSection from "../components/BrandSection";
import { Check, KeyOff } from "tabler-icons-react";
import axios from "axios";
import { showNotification } from "@mantine/notifications";

const ForgotPassword: FC = () => {
  const [, setShowAlert] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) =>
        value === ""
          ? "Email must not be empty."
          : /^\S+@\S+$/.test(value)
          ? null
          : "Invalid email",
    },
  });
  const handleSubmit = async (values: any) => {
    setShowAlert(false);
    let formdata = new FormData();
    try {
      formdata.append("email", values.email);
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}dj-rest-auth/password/reset/`,
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        showNotification({
          id: "password-reset-emial",
          title: "Password Reset Email!",
          message: `${response.data.detail}!`,
          autoClose: 2000,
          color: "green",
          icon: <Check />,
          styles: (theme) => ({
            root: {
              backgroundColor: theme.white,
              borderColor: "green",

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: "black" },
            description: { color: "black" },
            closeButton: {
              color: theme.black,
            },
          }),
        });
        form.setValues({
          email: "",
        });
      }
      console.log("response", response);
    } catch {
      setShowAlert(true);
    }
  };
  return (
    <Grid p={0} m={0}>
      <Grid.Col xs={12} sm={6} lg={7} p={0} className="left-container">
        <div className="log-left">
          <div className="main-box">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 15,
              }}
            >
              <Avatar
                className="avatar"
                styles={{ placeholder: { backgroundColor: "#fd7e14" } }}
              >
                <KeyOff strokeWidth={2} color={"white"} className="icon-main" />
              </Avatar>
            </div>
            <Text className="sub-title-text">Forgot Your Password</Text>
            <Text size="md" weight={300} className="sub-text">
              Enter the email address associated with your account and we'll
              send you a link to reset your account.
            </Text>
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Stack>
                <TextInput
                  type="text"
                  placeholder="Email"
                  value={form.values.email}
                  mt={20}
                  onChange={(event) =>
                    form.setFieldValue("email", event.currentTarget.value)
                  }
                  error={form.errors.email}
                />
                <Button type="submit" color={"orange"} mt={10}>
                  Send Reset Link
                </Button>
              </Stack>
            </form>
          </div>
        </div>
      </Grid.Col>
      <BrandSection />
    </Grid>
  );
};

export default ForgotPassword;
