import axios from "axios";
import dayjs from "dayjs";
import { attachEventCommentInfowindow } from "./infoWindow";

export const saveMapAnnotation = async function (
  formData: any,
  tokenKey: String
) {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}save_annotation_comment/`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `token ${tokenKey}`,
    },
  });
  return response;
}

export const displayListOfAnnotations = function (
  user: any,
  input: any,
  annotations: any[],
  index: number,
  commentsHtml: any
) {
  annotations[index].comments.push({
    owner: user.username,
    comment: input.value,
    created: dayjs(),
  });
  input.value = "";
  commentsHtml = "";
  let commentsDiv: any = document.getElementById("comments");
  annotations[index].comments.forEach(
    (comment: any) => {
      let time = dayjs().to(comment.created);
      commentsHtml += `
            <div class="ma-user comment-info">
              <div class="ma-user-info">
                <h6 class="username">${comment.owner || "owner name"
        }</h6>
                <span class="annotation-time">${time || "3 Hours ago"
        }</span>
                <p class="comment-text">${comment?.comment
        }</p>
              </div>
            </div>`;
    }
  );
  if (commentsDiv) commentsDiv.innerHTML = commentsHtml;
}

export const getMapAnnotations = async function (
  map: any,
  user: any,
  id: string,
  markers: any[],
  dispatch: any
) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}get_map_annotations/${id}/`,
      {
        headers: user && {
          Authorization: `token ${user.key}`,
        },
      }
    );
    let { annotations } = response.data;
    if (annotations.length) {
      annotations.forEach((annotation: any, index: number) => {
        let { comments } = annotation;

        let annotationTime = dayjs().to(annotation.created);
        const latLang = new google.maps.LatLng(
          annotation.lat,
          annotation.lng
        );
        const marker = new google.maps.Marker({
          position: latLang,
          map,
          icon: "images/marker-icon.svg",
        });

        marker.setPosition(latLang);
        let newElement: HTMLInputElement | null = document.getElementById(
          "annotation_input"
        ) as HTMLInputElement;
        window.sendComment = async function sendComment(idx: number) {
          let input = document.querySelector(
            "#annotation_input"
          ) as HTMLInputElement;
          if (input?.value.length === 0) return undefined;
          let annotationId = annotations[idx].id || 0;
          try {
            let formData = new FormData();
            formData.append("annotation_id", JSON.stringify(annotationId));
            formData.append("comment", input.value);
            formData.append("owner", user.user_id);

            const response = await saveMapAnnotation(formData, user.key);
            if (response.status === 200) {
              displayListOfAnnotations(user, input, annotations, idx, commentsHtml);
            }
          } catch (e) {
            console.log("error", e);
          }
          markers[idx].close();
        };
        let commentsHtml = "";
        comments.forEach((comment: any) => {
          let commentTime = dayjs().to(comment.created);
          commentsHtml += `
        <div class="ma-user comment-info">
          <div class="ma-user-info">
            <h6 class="username">${comment.owner}</h6>
            <span class="annotation-time">${commentTime
            }</span>
            <p class="comment-text">${comment.comment}</p>
          </div>
        </div>`;
        });

        const htmlMsg = `
        <div class="modal-ano">
          <div class="ma-body">
            <div class="ma-user">
              <div class="ma-user-info">
                <h6 class="username">${user?.username}</h6>
                <span class="annotation-time">${annotationTime
          }</span>
                <p class="comment-text">${annotation.annotation ||
          annotation.main_comment
          }</p>
              </div>
            </div>
            <hr class="annotation-hr"/>
            <div class="comments-box" id="comments">
            ${commentsHtml}
            </div>
          </div>
          <div class="ma-form">
            <input id="annotation_input" class="comment-input" type="text" placeholder="Enter comment..."/>
            <button onclick="sendComment(${index})" class="comment-btn">send</button>
          </div>
        </div>
        `;

        let infoWindow = new google.maps.InfoWindow({
          content: htmlMsg,
        });
        markers.push(infoWindow);
        google.maps.event.addListener(map, "click", () =>
          infoWindow.close()
        );

        attachEventCommentInfowindow(marker, infoWindow, map, newElement, index);

        dispatch({
          type: "addMarkers",
          payload: {
            marker: marker,
          },
        });

        dispatch({ type: "toggleMarkers", payload: true });
      });
    }
  } catch (e) {
    console.log("error", e);
  }
}