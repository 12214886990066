import {
  Avatar,
  Button,
  createStyles,
  Grid,
  Menu,
  Modal,
  Pagination,
  Space,
  TextInput,
} from "@mantine/core";
import { FC, useState, useEffect } from "react";
import { Table } from "@mantine/core";
import { Copy, Archive, Share, Trash, Search } from "tabler-icons-react";

import CreateNewMapModal from "./CreateNewMapModal";

import { useAuth } from "../hooks/useAuth";
import { useStore } from "../hooks/useStore";
import { useNavigate } from "react-router-dom";
import ShareMapModal from "./ShareMapModal";
import axios from "axios";
import ScopoLoader from "./ScopoLoader";

const useStyles = createStyles((theme) => ({
  upgradeNowBtn: {
    color: theme.colors.allColors[7],
    backgroundColor: theme.colors.allColors[0],

    "&:hover": {
      backgroundColor: theme.colors.allColors[7],
      color: "white",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      minWidth: "208px",
    },
  },
  createNewBtn: {
    color: theme.colors.allColors[0],
    backgroundColor: theme.colors.allColors[7],
    "&:hover": {
      backgroundColor: theme.colors.allColors[7],
      color: "white",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      minWidth: "208px",
    },
  },
  tableBody: {
    textAlign: "start",
  },
  boundaryMap: {
    display: "flex",
    alignItems: "center",
  },
  iconDiv: {
    marginRight: "17px",
  },
  mapDetailsCell: {
    display: "flex",
    alignItems: "center",
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.colors.allColors[2],
  },
  mapDetails: {
    color: theme.colors.allColors[8],
    opacity: ".54",
  },
  mapCount: {
    color: theme.colors.allColors[8],
  },
  mapDetailsRow: {
    color: theme.colors.allColors[8],
  },
  imgDiv: {
    marginRight: "10px",
  },
  letterAvatar: {
    backgroundColor: theme.colors.allColors[3],
    color: "black",
  },
  menuItem: {
    color: "blue !important",
  },
  menu: {
    color: "blue !important",
  },
  loginTextSection: {
    width: "100%",
    height: "30%",
  },

  loginButtonSection: {
    width: "100%",
    height: "30%",
    justifyContent: "center",
    alignItems: "center",
  },

  inputText: {
    marginLeft: "20%",
    width: "60%",
  },

  loginButton: {
    backgroundColor: "blue",
    color: "white",
  },
}));

const SALMapsSelectionTable: FC = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userFilterListOfSALs, setUserFilterListOfSALs] = useState([]);
  const [listOfSALs, setListOfSALs] = useState([]);

  const { state, dispatch } = useStore();
  const [activePage, setActivePage] = useState(1);
  const PER_PAGE = 20;

  const navigate = useNavigate();

  useEffect(() => {
    setUserFilterListOfSALs(state.listOfSALs);
    setListOfSALs(state.listOfSALs);
  }, [state.listOfSALs]);

  useEffect(() => {
    const getSALList = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}demographics/sal_list`
        );

        dispatch({ type: "setSALList", payload: response.data });
        dispatch({ type: "emptyPolygons", payload: [] });
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    getSALList();
  }, [dispatch]);

  function loadSALMap(salCode21: string) {
    setListOfSALs([]);
    let formData = new FormData();
    formData.append("sal_code21", salCode21.toString());
    const response = axios.get(
      `${process.env.REACT_APP_BASE_URL}demographics/load_sal/`,
    );
  }

  const handleChange = (value: string) => {
    console.log(value);
    if (value.length >= 3) {
      const newListOfSALs = listOfSALs.filter((ele: any) =>
        ele.sal_name21.toLowerCase().includes(value.toLowerCase())
      );
      setUserFilterListOfSALs(newListOfSALs);
    }

    if (value.length === 0) {
      setUserFilterListOfSALs(listOfSALs);
    }
  };

  const ths = (
    <tr>
      <th>SAL Name</th>
      <th>State</th>
      <th>Area (km<sup>2</sup>)</th>
    </tr>
  );

  const rows = [...userFilterListOfSALs]
    .splice((activePage - 1) * PER_PAGE, PER_PAGE)
    .map((sal: any) => (
      <tr key={sal.sal_code21}>
        <td
          onClick={() => {
            dispatch({ type: 'resetSummaryStats' })
            navigate(`/SAL/${sal.sal_code21}`)
          }}
          style={{ cursor: "pointer" }}
        >
          <div className={classes.boundaryMap}>
            <div className={classes.iconDiv}> </div>
            <div className="info-map-div">
              <div className={classes.title}>{sal.sal_name21}</div>
              <div>
                <div className={classes.mapDetails}></div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div className={classes.mapDetailsCell}>
            <div className={classes.textDiv}>
              <span className={classes.mapCount}>{sal.ste_name21}</span>{" "}
            </div>
          </div>
        </td>
        <td className={classes.mapDetailsRow}>
          <div className={classes.mapDetailsCell}>
            <div className={classes.textDiv}>
              <span className={classes.mapCount}>{sal.area_sqkm}</span>{" "}
            </div>
          </div>
        </td>
        <td></td>
        <td>
          <Button
            className={classes.upgradeNowBtn}
            onClick={() => {
              dispatch({ type: 'resetSummaryStats' })
              navigate(`/SAL/${sal.sal_code21}`)
            }}
          >
            Load Suburb
          </Button>
        </td>
      </tr>
    ));

  const archivedMapsHeader = (
    <Grid mt="md">
      <Grid.Col
        lg={6}
        sm={4}
        style={{
          textAlign: "start",
          fontSize: "22px",
          fontWeight: "bold",
        }}
      >
        Suburbs
      </Grid.Col>
    </Grid>
  );

  if (loading) {
    return (
      <>
        {archivedMapsHeader}
        <ScopoLoader rows={4} />
      </>
    );
  }
  return (
    <>
      {archivedMapsHeader}
      <Space h="lg" />
      <Space h="lg" />
      {state.listOfSALs.length > 0 && (
        <div>
          <Grid>
            <Grid.Col lg={3}>
              <TextInput
                radius="lg"
                placeholder="Search"
                icon={<Search size={18} strokeWidth={2} color={"black"} />}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Grid.Col>
          </Grid>
          <div className="table-responsive">
            <Table
              className="table-inner"
              verticalSpacing="xl"
              captionSide="bottom"
              mb="xl"
              mt="sm"
              pb="lg"
            >
              <thead>{ths}</thead>
              {listOfSALs.length && (
                <tbody className={classes.tableBody}>{rows}</tbody>
              )}
            </Table>
          </div>
          <Pagination
            total={Math.ceil(state.listOfSALs.length / PER_PAGE)}
            position="center"
            className="pagination"
            value={activePage}
            onChange={setActivePage}
          />
        </div>
      )}

    </>
  );
};

export default SALMapsSelectionTable;
