import { Text, Button, Flex, Tooltip, Title } from "@mantine/core";
import { FilterTable } from "../../../types";
import { HeartPlus } from "tabler-icons-react";

interface FiltersPanelProps {
  filters: FilterTable[];
  isEdited: boolean;
  handleSaveClick: () => void;
}

const FiltersPanel: React.FC<FiltersPanelProps> = ({
  filters,
  isEdited,
  handleSaveClick,
}) => {
  return (
    <Flex
      direction="row"
      gap="md"
      style={{ width: "100%", alignItems: "center" }}
    >
      <Flex
        direction="row"
        gap="md"
        justify="space-between"
        style={{ alignSelf: "stretch", width: "100%", alignItems: "center" }}
      >
        <Title order={3}>Demographics Builder</Title>
        <Flex direction="row" gap="md" style={{ alignItems: "center" }}>
          <Tooltip
            label={"Create new filter"}
            withArrow
            transitionProps={{ transition: "pop", duration: 300 }}
            openDelay={500}
          >
            <Button
              color="orange"
              variant={"filled"}
              onClick={handleSaveClick}
              disabled={!filters.length}
            >
              <HeartPlus />
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FiltersPanel;
