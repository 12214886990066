import {
  Flex,
  Text,
  UnstyledButton,
  ActionIcon,
  Menu,
  ScrollArea,
  Input,
} from "@mantine/core";
import { TrashX, DotsVertical, Star, Edit, X } from "tabler-icons-react";
import {
  deleteDemographicsSettings,
  updateDemographicsSettings,
} from "../../../map-utils/filterbarAPI";
import { SavedFilter, ItemProps, FilterTable } from "../../../types";
import { useAuth } from "../../../hooks/useAuth";
import { useContext, useEffect, useState, SetStateAction } from "react";
import storeContext from "../../../store/store";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";
import ScrollingText from "../../ScrollingText/ScrollingText";

interface SidebarProps {
  listOfUserSavedFilters: SavedFilter[];
  selectedFilterName: string | null;
  setSelectedFilterName: (name: string) => void;
  filters: FilterTable[];
  setFilters: (filters: SetStateAction<FilterTable[]>) => void;
  setIsEditing: (value: SetStateAction<boolean>) => void;
  setDemographicsSettingsId: (id: string | null) => void; // Add this prop
  selectedFilterID: string | null;
}

const Sidebar: React.FC<SidebarProps> = ({
  listOfUserSavedFilters,
  selectedFilterName,
  setSelectedFilterName,
  filters,
  setFilters,
  setIsEditing,
  setDemographicsSettingsId,
  selectedFilterID,
}) => {
  const [selectedSetting, setSelectedSetting] = useState<string | null>(null);
  const [selectFilterData, setSelectFilterData] = useState<ItemProps[]>([]);
  const { state, dispatch } = useContext<any>(storeContext);

  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [editingSettingId, setEditingSettingId] = useState<number | null>(null);
  const [editingSettingName, setEditingSettingName] = useState("");
  const [userHasInteracted, setUserHasInteracted] = useState(false); // Track user interactions
  const { user } = useAuth();

  useEffect(() => {
    if (listOfUserSavedFilters) {
      const data = listOfUserSavedFilters.map((setting) => ({
        label: setting.settings_name,
        value: JSON.stringify(setting),
        demographics_settings_id: setting.demographics_settings_id,
      }));
      setSelectFilterData(data);
    }
  }, [listOfUserSavedFilters]);

  useEffect(() => {
    if (state.mapAssociatedDemographicSettings !== "") {
      // Here, state.mapAssociatedDemographicSettings is demographics_settings_json
      setSelectedSetting(state.mapAssociatedDemographicSettings);
      setDemographicsSettingsId(null);
      resetFilters();
      setSelectedFilterName("custom");
      updateFiltersFromSetting(state.mapAssociatedDemographicSettings);
    } else if (!userHasInteracted) {
      const defaultFilter = listOfUserSavedFilters.find(
        (filter) => filter.organisation_default
      );
      if (defaultFilter) {
        const defaultFilterString = JSON.stringify(defaultFilter);
        setSelectedSetting(defaultFilterString);
        setSelectedFilterName(defaultFilter.settings_name);
        setDemographicsSettingsId(
          defaultFilter.demographics_settings_id.toString()
        );
        updateFiltersFromSetting(defaultFilter.demographics_settings_json);
      }
    }
  }, [
    state.mapAssociatedDemographicSettings,
    listOfUserSavedFilters,
    setSelectedFilterName,
    setDemographicsSettingsId,
    userHasInteracted,
  ]);

  const handleSelectChange = (value: string | null) => {
    setUserHasInteracted(true); // Mark as user-interacted
    setSelectedSetting(value);
    if (value === selectedSetting) {
      setSelectedSetting(null);
      setDemographicsSettingsId(null);
      resetFilters();
      setSelectedFilterName("custom");
      return;
    }

    const selectedFilter: SavedFilter = JSON.parse(value!);
    updateFiltersFromSetting(selectedFilter.demographics_settings_json);
    setSelectedFilterName(selectedFilter.settings_name);
    setDemographicsSettingsId(
      selectedFilter.demographics_settings_id.toString()
    );
    setIsEdited(
      areFiltersDifferent(
        filters,
        JSON.parse(selectedFilter.demographics_settings_json)
      )
    );
  };

  const resetFilters = () => {
    setFilters((currentFilters) =>
      currentFilters.map((table) => ({
        ...table,
        sub_categories: table.sub_categories.map((subCategory) => ({
          ...subCategory,
          filters: subCategory.filters.map((filter) => ({
            ...filter,
            checked: false,
          })),
        })),
      }))
    );
    setIsEdited(false);
  };

  // respond to changes in the selectedFilterID and make sure the selected filter is selectedSetting
  useEffect(() => {
    if (selectedFilterID) {
      const foundSetting = listOfUserSavedFilters.find(
        (setting) => setting.demographics_settings_id === +selectedFilterID
      );

      if (foundSetting) {
        setSelectedSetting(JSON.stringify(foundSetting));
        setSelectedFilterName(foundSetting.settings_name);
        setDemographicsSettingsId(selectedFilterID);
        updateFiltersFromSetting(foundSetting.demographics_settings_json);
      }
    }
  }, [selectedFilterID]);

  const updateFiltersFromSetting = (demographicsSettingsJson: any) => {
    if (!demographicsSettingsJson) return;

    const settingFilters: FilterTable[] = JSON.parse(demographicsSettingsJson);

    setFilters((currentFilters) =>
      currentFilters.map((table) => ({
        ...table,
        sub_categories: table.sub_categories.map((subCategory) => ({
          ...subCategory,
          filters: subCategory.filters.map((filter) => ({
            ...filter,
            checked: settingFilters.some(
              (f) =>
                f.table_name === table.table_name &&
                f.category_title === table.category_title &&
                f.sub_categories.some(
                  (sub) =>
                    sub.sub_category_title === subCategory.sub_category_title &&
                    sub.filters.some(
                      (f) => f.filter_name === filter.filter_name
                    )
                )
            ),
          })),
        })),
      }))
    );
    setIsEdited(areFiltersDifferent(filters, settingFilters));
  };

  const areFiltersDifferent = (
    currentFilters: FilterTable[],
    settingFilters: FilterTable[]
  ) => {
    const serializeFilters = (filters: any[]) =>
      JSON.stringify(
        filters.sort((a, b) => (a.table_name > b.table_name ? 1 : -1))
      );

    return (
      serializeFilters(currentFilters) !== serializeFilters(settingFilters)
    );
  };

  const handleDemographicDelete = async (selectedSetting: string | null) => {
    if (!selectedSetting) {
      showNotification({
        title: "Error",
        message: "No setting selected",
        color: "red",
      });
      return;
    }

    const setting: SavedFilter = JSON.parse(selectedSetting);
    const settingId = setting.demographics_settings_id;

    // Check if the setting is the default one
    if (setting.organisation_default) {
      showNotification({
        title: "Cannot Delete Default Filter",
        message:
          "The default filter cannot be deleted. To change the default, set another filter as default first.",
        color: "yellow",
      });
      return;
    }

    try {
      setLoading(true);
      await deleteDemographicsSettings(
        {
          demographicsSettingsId: `${settingId}`,
        },
        user.key
      );

      const updatedList = listOfUserSavedFilters.filter(
        (setting) => setting.demographics_settings_id !== settingId
      );

      // Update both `listOfUserSavedFilters` in context and `selectFilterData` in component state
      setSelectFilterData(
        updatedList.map((setting) => ({
          label: setting.settings_name,
          value: JSON.stringify(setting),
          demographics_settings_id: setting.demographics_settings_id,
        }))
      );

      // Dispatch updated list to context
      dispatch({
        type: "setUserDemographicSettingsList",
        payload: updatedList,
      });

      setSelectedSetting(null);
      setSelectedFilterName("custom");
      setDemographicsSettingsId(null);

      showNotification({
        title: "Success",
        message: "Setting deleted successfully",
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Error",
        message:
          error instanceof AxiosError
            ? error.response?.data
            : "Failed to delete setting. Please try again.",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDemographicEdit = async (selectedSetting: string | null) => {
    if (!selectedSetting) {
      showNotification({
        title: "Error",
        message: "No setting selected",
        color: "red",
      });
      return;
    }

    const setting: SavedFilter = JSON.parse(selectedSetting);
    const settingId = setting.demographics_settings_id;

    const foundSetting = listOfUserSavedFilters.find(
      (setting) => setting.demographics_settings_id === settingId
    );

    if (foundSetting) {
      if (foundSetting.organisation_default) {
        showNotification({
          title: "Cannot Edit Default Filter",
          message:
            "To update the default filter, please create a new filter and set it as default.",
          color: "yellow",
        });
        return;
      }

      setEditingSettingId(settingId);
      setEditingSettingName(foundSetting.settings_name);
    }
  };

  const handleSaveSettingName = async () => {
    if (!editingSettingId) return;

    const foundSetting = listOfUserSavedFilters.find(
      (setting) => setting.demographics_settings_id === editingSettingId
    );

    if (foundSetting) {
      if (foundSetting.organisation_default) {
        showNotification({
          title: "Cannot Update Default Filter",
          message:
            "To update the default filter, please create a new filter and set it as default.",
          color: "yellow",
        });
        setEditingSettingId(null);
        setEditingSettingName("");
        return;
      }

      const data = {
        demographicsSettingsJson: foundSetting.demographics_settings_json,
        settingsName: editingSettingName,
        organisationDefault: foundSetting.organisation_default
          ? "True"
          : "False",
        demographicsSettingsId: `${foundSetting.demographics_settings_id}`,
      };

      try {
        await updateDemographicsSettings(data, user.key);

        const updatedList = listOfUserSavedFilters.map((setting) =>
          setting.demographics_settings_id === editingSettingId
            ? { ...setting, settings_name: editingSettingName }
            : setting
        );

        setSelectFilterData(
          updatedList.map((setting) => ({
            label: setting.settings_name,
            value: JSON.stringify(setting),
            demographics_settings_id: setting.demographics_settings_id,
          }))
        );

        dispatch({
          type: "setUserDemographicSettingsList",
          payload: updatedList,
        });

        setEditingSettingId(null);
        setEditingSettingName("");

        showNotification({
          title: "Success",
          message: "Setting name updated successfully",
          color: "green",
        });
      } catch (error) {
        showNotification({
          title: "Error",
          message: "Failed to update setting name. Please try again.",
          color: "red",
        });
      }
    }
  };

  const handleDemographicSetAsDefault = async (
    selectedSetting: string | null
  ) => {
    if (!selectedSetting) {
      showNotification({
        title: "Error",
        message: "No setting selected",
        color: "red",
      });
      return;
    }

    const setting: SavedFilter = JSON.parse(selectedSetting);

    if (setting) {
      const settingId = setting.demographics_settings_id;

      const foundSetting = listOfUserSavedFilters.find(
        (setting) => setting.demographics_settings_id === settingId
      );

      updateDemographicsSettings(
        {
          demographicsSettingsJson: setting.demographics_settings_json,
          settingsName: setting.settings_name,
          organisationDefault: "True",
          demographicsSettingsId: `${settingId}`,
        },
        user.key
      );

      if (foundSetting) {
        const updatedList = listOfUserSavedFilters.map((setting) => {
          if (setting.demographics_settings_id === settingId) {
            return {
              ...setting,
              organisation_default: true,
            };
          } else {
            return {
              ...setting,
              organisation_default: false,
            };
          }
        });

        setSelectFilterData(
          updatedList.map((setting) => ({
            label: setting.settings_name,
            value: JSON.stringify(setting),
            demographics_settings_id: setting.demographics_settings_id,
          }))
        );

        dispatch({
          type: "setListOfUserSavedFilters",
          payload: updatedList,
        });

        showNotification({
          title: "Success",
          message: "Setting set as default",
          color: "green",
        });
      }
    }
  };

  return (
    <Flex
      style={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        borderLeft: "1px solid #D9D9D9",
        minWidth: "250px",
        maxWidth: "300px",
        height: "460px",
      }}
    >
      {selectFilterData.map((setting, idx) => (
        <Flex
          key={setting.demographics_settings_id}
          direction="row"
          gap="md"
          style={{
            padding: "10px 10px",
            width: "100%",
            gap: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #D9D9D9",
            borderLeft:
              selectedSetting === setting.value
                ? "3px solid #FD7E14"
                : "3px solid #fff",
          }}
        >
          <UnstyledButton
            onClick={() => handleSelectChange(setting.value)}
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: "180px",
            }}
            disabled={editingSettingId === setting.demographics_settings_id}
          >
            {editingSettingId === setting.demographics_settings_id ? (
              <Input
                value={editingSettingName}
                onChange={(e) => setEditingSettingName(e.target.value)}
                onBlur={handleSaveSettingName}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSaveSettingName();
                  }
                }}
              />
            ) : (
              <>
                {selectedSetting === setting.value && (
                  <Flex
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <ActionIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedSetting(null);
                        setDemographicsSettingsId(null);
                        resetFilters();
                        setSelectedFilterName("custom");
                      }}
                    >
                      <X size={16} />
                    </ActionIcon>
                  </Flex>
                )}
                <ScrollingText duration={7} direction="left" textSpacing="1em">
                  <Text>{setting.label}</Text>
                </ScrollingText>
              </>
            )}
          </UnstyledButton>

          <Menu shadow="md">
            <Menu.Target>
              <ActionIcon
                color="gray"
                size="sm"
                style={{ cursor: "pointer" }}
                onClick={(e) => e.preventDefault()}
              >
                <DotsVertical />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                icon={<Star style={{ color: "#FFD700" }} />}
                onClick={() => handleDemographicSetAsDefault(setting.value)}
              >
                Set as default
              </Menu.Item>
              <Menu.Item
                icon={<Edit style={{ color: "#4169E1" }} />}
                onClick={() => handleDemographicEdit(setting.value)}
              >
                Rename
              </Menu.Item>
              <Menu.Item
                icon={
                  <TrashX
                    style={{
                      color: JSON.parse(setting.value).organisation_default
                        ? "#D9D9D9"
                        : "#FF0000",
                    }}
                  />
                }
                onClick={() => handleDemographicDelete(setting.value)}
                disabled={JSON.parse(setting.value).organisation_default}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      ))}
    </Flex>
  );
};

export default Sidebar;
