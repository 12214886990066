import AppShellLayout from "../components/AppShellLayout";
import LGAMapsSelectionTable from "../components/LGAMapsSelectionTable";

const LGAMapSelection = () => {
  return (
    <AppShellLayout>
      <LGAMapsSelectionTable />
    </AppShellLayout>
  );
};

export default LGAMapSelection;
