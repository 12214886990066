import {
  Divider,
  Group,
  Navbar,
  Radio,
  Text,
  Title,
  Button,
  Col,
  Grid,
  Tooltip,
  Stack,
  Collapse,
  Flex,
} from "@mantine/core";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import storeContext from "../store/store";
import { setHeatMap } from "../map-utils/setHeatMap";
import useElementSize from "../hooks/useElementSize";
import { ChevronDown, ChevronRight } from "tabler-icons-react";

import {
  Dataset,
  FilterData,
  FilterDataWithResponses,
  FilterType,
  SubCategory,
  Table,
} from "./filterbar-utils/filtertypes";
import { Filter, FilterTable } from "../types";

const HeatMapCategory = ({ title, children }: HeatMapCategoryProps) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Stack>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        variant="white"
        sx={(theme) => ({
          color: theme.colors.allColors[7],
          "&:hover": {
            color: "#f76707",
          },
        })}
      >
        {isOpen ? <ChevronDown /> : <ChevronRight />}
        {title}
      </Button>

      <Collapse in={isOpen}>
        <Stack
          spacing="md"
          sx={{
            paddingLeft: "20px",
            paddingTop: "10px",
            paddingBottom: "20px",
            paddingRight: "20px",
          }}
        >
          {children}
        </Stack>
      </Collapse>
    </Stack>
  );
};

type HeatMapCategoryProps = {
  title: string;
  children?: React.ReactNode;
};

type Props = {
  openHeatMap?: boolean;
  openFiltersBar?: boolean;
};

const HeatMap: FC<Props> = ({ openHeatMap }: Props) => {
  const { state, dispatch } = useContext<any>(storeContext);
  const { height } = useElementSize("header-main");
  const [valueSelected, setValueSelected] = useState(false);
  // set the subcategories
  const [subCategoriesWithCategory, setSubCategoriesWithCategory] = useState<
    { categoryTitle: string; subCategory: SubCategory }[]
  >([]);

  useEffect(() => {
    if (openHeatMap && state.mapPolygons.length > 0) {
      setHeatMap(state.mapPolygons, state.heatmapValue);
    }
  }, [
    state.heatmapValue,
    openHeatMap,
    state.mapPolygons,
    state.filterSelection,
  ]);

  useEffect(() => {
    // build subcategories here
    // Assuming state.filterSelection is an array of selected filters
    const data: Dataset = JSON.parse(JSON.stringify(state.summaryDataNewAPI));

    // if data is {} or undefined, return
    // TODO: Find an alternative way to know when to display the data to avoid iterating over an empty state variable
    if (!data.tables) return;


    // check if the filter selection is empty
    if (Object.keys(state.filterSelection).length == 0) return;

    const selected: FilterTable[] = JSON.parse(
      JSON.stringify(state.filterSelection)
    );

    console.log("filter selection", state.filterSelection);

    const subCategoriesWithCategory: {
      categoryTitle: string;
      subCategory: SubCategory;
    }[] = [];

    selected?.forEach((table) => {
      table.sub_categories.forEach((subCategory) => {
        // find subcategory in dataset
        let findSubcat = data.tables
          .find((t) => t.table_name === table.table_name)
          ?.sub_categories.find(
            (s) => s.sub_category_title === subCategory.sub_category_title
          ) ?? {
          sub_category_title: subCategory.sub_category_title,
          filters: [],
        };

        // remove filters that are not selected
        findSubcat.filters = findSubcat.filters.filter((filter) => {
          return subCategory.filters.find(
            (selectedFilter) =>
              selectedFilter.filter_name === filter.filter_name
          );
        });

        // find the table's category title
        let categoryTitle =
          data.tables.find((t) => t.table_name === table.table_name)
            ?.category_title ?? "";

        // add subcategory with its table category to the list
        subCategoriesWithCategory.push({
          categoryTitle,
          subCategory: findSubcat,
        });
      });
    });

    console.log("subCategories", subCategoriesWithCategory);
    setSubCategoriesWithCategory(subCategoriesWithCategory);
  }, [state.filterSelection, openHeatMap]);

  const handleExport = () => {
    dispatch({
      type: "setViewExportMapModal",
      payload: { visible: true, type: "heat" },
    });
  };

  const handleRadioChange = (e: any) => {
    // find the filter in the subCategories accounting for the top answers
    const filter = subCategoriesWithCategory
      .map((item) =>
        item.subCategory.filters.find((f) =>
          "responses" in f
            ? f.responses.find((r) => r.filter_name === e)
            : f.filter_name === e
        )
      )
      .filter((f) => f)[0];

    // If the filter has responses and the response with the name exists, return it
    if (filter && "responses" in filter) {
      const response = filter.responses.find((r) => r.filter_name === e);
      if (response) {
        dispatch({
          type: "setHeatmapValue",
          payload: response,
        });
      }
    }
    // If the filter does not have responses, return the filter name
    else if (filter) {
      dispatch({
        type: "setHeatmapValue",
        payload: filter,
      });
    }
    setValueSelected(true);
  };

  const renderHeatMapCategories = () => {
    // Group subcategories by their category title
    const groupedSubCategories = subCategoriesWithCategory.reduce(
      (groups, item) => {
        if (!groups[item.categoryTitle]) {
          groups[item.categoryTitle] = [];
        }
        groups[item.categoryTitle].push(item.subCategory);
        return groups;
      },
      {} as { [key: string]: SubCategory[] }
    );

    // Create HeatMapCategories with category titles
    return Object.keys(groupedSubCategories).map((categoryTitle) => (
      <div key={categoryTitle}>
        <Flex pl="10px" pt="4px" pb="4px">
          <Text ta="left" fw={700} fz="md" tt="capitalize">
            {categoryTitle}
          </Text>
        </Flex>
        {groupedSubCategories[categoryTitle].map((subCategory) => (
          <Fragment key={subCategory.sub_category_title}>
            <Divider />
            <HeatMapCategory title={subCategory.sub_category_title}>
              <Group>{buildRadioGroup(subCategory.filters)}</Group>
            </HeatMapCategory>
          </Fragment>
        ))}
      </div>
    ));
  };

  const buildRadioGroup = (
    filters: (FilterData | FilterDataWithResponses)[]
  ) => {
    return (
      <Stack sx={(theme) => ({ gap: "md" })}>
        {filters.map((filter) => {
          // add check of if undefined
          if (filter) {
            if ("responses" in filter) {
              // console.log("heatmap", filter); // Debugging
              return filter.responses
                .sort((a, b) => b.area - a.area)
                .slice(0, filter.top_answers)
                .map((response) => (
                  <Fragment key={response.filter_name}>
                    <Radio
                      value={response.filter_name}
                      label={response.filter_name}
                    />
                  </Fragment>
                ));
            } else {
              return (
                <Fragment key={filter.filter_name}>
                  <Radio
                    value={filter.filter_name}
                    label={filter.filter_title}
                  />
                </Fragment>
              );
            }
          }
        })}
      </Stack>
    );
  };

  return (
    <div style={openHeatMap ? {} : { display: "none" }}>
      <Navbar
        hiddenBreakpoint="sm"
        width={{ sm: 200, md: 270, lg: 300 }}
        fixed={false}
        sx={(theme) => ({
          height: `calc(100vh  - ${height}px)`,
          top: `${height}px`,
          overflowY: "auto",
          overflowX: "hidden",
          [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            position: "fixed",
            top: `${height}px !important`,
            maxWidth: "300px",
          },
          [`@media (max-width: 425px)`]: {
            maxWidth: "250px",
          },
        })}
      >
        {state.filterData.setCheck ? (
          <>
            <Grid grow>
              <Col span={6}>
                <Title
                  order={6}
                  p="lg"
                  align="left"
                  style={{ textTransform: "uppercase" }}
                >
                  Heatmap
                </Title>
              </Col>

              <Col
                span={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip label="Currently in Beta" position="right" withArrow>
                  <Button
                    variant="outline"
                    color="orange"
                    compact
                    type="submit"
                    disabled={!valueSelected}
                    onClick={handleExport}
                  >
                    Export
                  </Button>
                </Tooltip>
              </Col>
            </Grid>

            <Divider />

            <Radio.Group
              onChange={(e) => {
                handleRadioChange(e);
              }}
              p="0"
              pt={0}
              size="md"
              required
            >
              <Stack sx={(theme) => ({ gap: 0 })}>
                {
                  //if there are subcategories, render them
                  // else render the default message
                  subCategoriesWithCategory.length > 0 ? (
                    renderHeatMapCategories()
                  ) : (
                    <Stack p="lg">
                      <Text size="md">
                        Select your demographics to view a list of heatmaps to
                        choose from
                      </Text>
                    </Stack>
                  )
                }
                <Divider />
              </Stack>
            </Radio.Group>
          </>
        ) : (
          <>
            <Title
              order={6}
              p="lg"
              align="left"
              style={{ textTransform: "uppercase" }}
            >
              Heatmap
            </Title>
            <Title
              order={5}
              p="lg"
              align="left"
              sx={(theme) => ({ color: theme.colors.allColors[7] })}
              style={{ textTransform: "uppercase" }}
            >
              Please Draw Shape to see heatmap
            </Title>
          </>
        )}
      </Navbar>
    </div>
  );
};

export default HeatMap;
