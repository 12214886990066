import React, { useState, useContext } from "react";
import { Button, Collapse, Divider } from "@mantine/core";
import { ChevronDown, ChevronRight } from "tabler-icons-react";
import SliderGroup from "./SliderGroup";
import { FilterData, FilterDataWithResponses } from "./filtertypes";
import storeContext from "../../store/store";

// Props type, adjusted to directly use an array of Filters or FiltersWithResponses
interface FilterOptionsProps {
  groupName: string;
  category: string;
  filters: (FilterData | FilterDataWithResponses)[];
}

// Updated Component to use the correct props
const FilterGroup: React.FC<FilterOptionsProps> = ({ groupName, filters, category }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const { state, dispatch } = useContext<any>(storeContext);

  filters.forEach((filter: any) => {
    if(filter.top_answers !== undefined) {
      Object.keys(state.appliedFilters).forEach((appliedFilterName: any) => {
        if(state.appliedFilters[appliedFilterName].groupName === groupName) {
          filter.responses.forEach((response: any) => {
            if(response.filter_name === appliedFilterName)
              response.minimumSelected = state.appliedFilters[appliedFilterName].minValue;
              response.maximumSelected = state.appliedFilters[appliedFilterName].maxValue;
          });
        }
      });
    }
    Object.keys(state.appliedFilters).forEach((appliedFilterName: any) => {
      if(appliedFilterName === filter.filter_name) {
        filter.minimumSelected = state.appliedFilters[appliedFilterName].minValue;
        filter.maximumSelected = state.appliedFilters[appliedFilterName].maxValue;
      }
    });
  });

  return (
    <React.Fragment>
      <Button
        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
        variant="white"
        sx={(theme) => ({
          color: theme.colors.allColors[7],
          "&:hover": {
            color: "#f76707",
          },
        })}
      >
        {isDropDownOpen ? <ChevronDown /> : <ChevronRight />}
        {groupName}
      </Button>
      <Collapse in={isDropDownOpen}>
        {/* Iterate over filters array */}
        {filters.map((filter) => {

          if (filter) {
            if ("responses" in filter) {
              // Handle filters with responses

              return filter.responses
                .sort((a, b) => b.area - a.area)
                .slice(0, filter.top_answers)
                .map((response) => (
                  <React.Fragment key={response.filter_name}>
                    <SliderGroup
                      title={response.filter_name}
                      propertyName={response.filter_name}
                      category={category}
                      groupName={groupName}
                      sliderLabel={(value: number) => `${value}%`}
                      units="%"
                      defaultChecked={true}
                      value={[response.min * 100, response.max * 100]}
                      defaultValue={[response.minimumSelected || response.min * 100, response.maximumSelected || response.max * 100]}
                      changeValue={[response.min * 100, response.max * 100]}
                      // onChangeValue={(e: [number, number]) =>
                      //   console.log(`${response.filter_name} changed to`, e)
                      // }
                    />
                    <Divider />
                  </React.Fragment>
                ));
            } else if(filter.units == '%') {
              // Handle regular filters
              return (
                <React.Fragment key={filter.filter_name}>
                  <SliderGroup
                    title={filter.filter_title}
                    propertyName={filter.filter_name}
                    groupName={groupName}
                    category={category}
                    units={filter.units}
                    sliderLabel={(value: number) => `${value}${filter.units}`}
                    defaultChecked={true}
                    value={[filter.min, filter.max]}
                    defaultValue={[filter.minimumSelected || filter.min, filter.maximumSelected || filter.max]}
                    changeValue={[filter.min, filter.max]}
                    // onChangeValue={(e: [number, number]) =>
                    //   console.log(`${filter.filter_name} changed to`, e)
                    // }
                  />
                  <Divider />
                </React.Fragment>
              );
            }
            else {
              // Handle regular filters
              return (
                <React.Fragment key={filter.filter_name}>
                  <SliderGroup
                    title={filter.filter_title}
                    propertyName={filter.filter_name}
                    groupName={groupName}
                    category={category}
                    units={filter.units}
                    sliderLabel={(value: number) => `${filter.units}${value}`}
                    defaultChecked={true}
                    value={[filter.min, filter.max]}
                    defaultValue={[filter.minimumSelected || filter.min, filter.maximumSelected || filter.max]}
                    changeValue={[filter.min, filter.max]}
                    // onChangeValue={(e: [number, number]) =>
                    //   console.log(`${filter.filter_name} changed to`, e)
                    // }
                  />
                  <Divider />
                </React.Fragment>
              );
            }
          }
        })}
      </Collapse>
      <Divider />
    </React.Fragment>
  );
};

export default FilterGroup;
