import React, { useState, useRef, useEffect, Children, ReactNode } from "react"; 
import "./styles.css";

// Define the styles for the marquee container
const marqueeContainerStyles: React.CSSProperties = {
  position: "relative",
  width: "100%",
  overflow: "hidden",
};

// Define the interface for the component's props
export interface MarqueeTextProps {
  children?: ReactNode; // Children to be displayed in the marquee
  className?: string; // Optional class name for styling
  duration?: number; // Duration for one cycle of animation
  direction?: "left" | "right"; // Direction of the marquee
  textSpacing?: string; // Spacing between the text items
  pauseOnHover?: boolean; // Should the marquee pause on hover
  playOnlyInView?: boolean; // Should the marquee play only when in view
  threshold?: number; // Threshold value for the playOnView behavior
}

// Generate the styles for the marquee items (the scrolling element)
const marqueeItemsStyles = (
  startPosition: number,
  time: number,
  direction?: string,
  willChange?: boolean
): React.CSSProperties => ({
  display: "inline-block",
  whiteSpace: "nowrap",
  transform: `translate3d(-${startPosition}px, 0, 0)`, // Initial position
  animationName: "marqueeScroll",
  animationDuration: `${time}s`,
  animationTimingFunction: "linear",
  animationIterationCount: "infinite",
  animationPlayState: "var(--marquee-play)", // Control play state via CSS variable
  animationDirection: direction === "right" ? "reverse" : undefined,
  ...(willChange && { willChange: "transform" }), // Optional optimization
});

// Generate the styles for each individual item in the marquee
const marqueeItemStyles = (marginRight: string): React.CSSProperties => ({
  position: "relative",
  display: "inline-block",
  marginRight: marginRight,
});

// Function to create cloned items to ensure seamless scrolling
const getClonedItems = (
  items: (string | number | React.ReactNode)[],
  copyTimes = 1
): (string | number)[] => {
  return Array(copyTimes).fill(items).flat();
};

// The main scrolling text component
const ScrollingText: React.FC<MarqueeTextProps> = ({
  className = "marquee",
  duration = 50,
  direction = "left",
  textSpacing = "0.15em",
  threshold = 0.1,
  children,
}: MarqueeTextProps) => {
  const marqueeContainer = useRef<HTMLDivElement>(null); // Ref for the marquee container
  const marqueeItems = useRef<HTMLDivElement>(null); // Ref for the marquee items
  const [translateFrom, setTranslateFrom] = useState(0); // State for starting position
  const [showItems, setShowItems] = useState(Children.toArray(children)); // State for items to show
  const [initialDuration, setInitialDuration] = useState(duration); // State for initial duration
  const [isPlaying, setIsPlaying] = useState(false); // State for play/pause status
  const [key, setKey] = useState(0); // State for resetting animation

  // Effect to handle the calculation of cloned items and initial settings
  useEffect(() => {
    const containerWidth = Math.floor(marqueeContainer.current!.offsetWidth);
    const itemsWidth = Math.floor(marqueeItems.current!.scrollWidth);
    
    if (itemsWidth <= containerWidth) {
      setIsPlaying(false);
      return;
    }

    const cloneTimes = Math.max(
      2,
      Math.ceil((containerWidth * 2) / itemsWidth)
    );
    const translateFromVal = itemsWidth * Math.floor(cloneTimes / 2);
    const durationVal =
      duration * parseFloat((translateFromVal / containerWidth).toFixed(2));

    setShowItems(getClonedItems(Children.toArray(children), cloneTimes));
    setTranslateFrom(translateFromVal);
    setInitialDuration(durationVal);

  }, [children, duration]);

  // Handle mouse enter event to start the animation
  const handleMouseEnter = () => {
    if (marqueeItems.current!.scrollWidth > marqueeContainer.current!.offsetWidth) {
      setIsPlaying(true);
    }
  };

  // Handle mouse leave event to pause the animation and reset it
  const handleMouseLeave = () => {
    setIsPlaying(false);
    setKey((prevKey) => prevKey + 1); // Update key to reset animation
  };

  return (
    <div
      ref={marqueeContainer}
      className={`${className}`}
      style={{
        ...marqueeContainerStyles,
        ["--marquee-play" as string]: isPlaying ? "running" : "paused",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`${className}__items`}
        style={marqueeItemsStyles(translateFrom, initialDuration, direction)}
        ref={marqueeItems}
        key={key} // Use key to reset animation
      >
        {showItems.map((item, index) => (
          <div
            className={`${className}__item`}
            style={marqueeItemStyles(textSpacing)}
            key={index}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollingText;