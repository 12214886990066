import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "./store/store";
import { AuthProvider } from "./hooks/useAuth";
import { BrowserRouter } from "react-router-dom";
// import axios from "axios";
// import { showNotification } from "@mantine/notifications";
// import { X } from "tabler-icons-react";


// axios.interceptors.response.use(
//   (res) => {
//     // Add configurations here
//     if (res.status === 200) {
//       // console.log("Posted Successfully");
//     }
//     return res;
//   },
//   (err) => {
//     let message = `${
//       (err.response.data?.non_field_errors &&
//         err.response.data?.non_field_errors[0]) ||
//       err.response.data["email"] ||
//       err.response.data["username"] ||
//       err.response.data ||
//       "Please try again"
//     }!`;
//     if (err.response.status === 400) console.log("error", err);
//     if (err.response.status === 402) {
//       message += " You'll be redirected to the settings page, where you or your team's admin can rectify the issue.";
//       setTimeout(() => { window.location.href = '/settings' }, 5000);
//     }
//     if(err.response.data === "Subscription not valid") alert("subscription error")
//     showNotification({
//       id: "err-notification",
//       title: "Request Failed!",
//       message: message,
//       autoClose: 5000,
//       color: "red",
//       icon: <X />,
//       styles: (theme) => ({
//         root: {
//           backgroundColor: theme.white,
//           borderColor: "red",

//           "&::before": { backgroundColor: theme.white },
//         },

//         title: { color: "black" },
//         description: { color: "black" },
//         closeButton: {
//           color: theme.black,
//         },
//       }),
//     });
    
//     return Promise.reject(err);
//   }
// );
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <StoreProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </StoreProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
