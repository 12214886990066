import { Badge, Grid, HoverCard, Text, Tooltip } from "@mantine/core";
import React, { useContext } from "react";
import { ArrowNarrowDown, ArrowNarrowUp } from "tabler-icons-react";

import storeContext from "../store/store";

function ValueAndComparisonToAverage(props: any) {
  const { state } = useContext<any>(storeContext);
  const {
    title,
    value,
    stateAverageDiff,
    nationalAverageDiff,
    prefixChar,
    suffixChar,
  } = props;

  function roundToTwo(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  function roundToOne(num: number) {
    return Math.round((num + Number.EPSILON) * 10) / 10;
  }

  function roundToInt(num: number) {
    return Math.round(num);
  }

  // Determine the rounded value based on the unit
  let displayValue;
  if (prefixChar === "$") {
    displayValue = roundToInt(value);
  } else if (prefixChar === "") {
    displayValue = roundToOne(value);
  } else {
    displayValue = roundToTwo(value);
  }
  // Calculate and truncate the benchmark differences, using ratios instead of deltas
  let stateDiffRatio = (value / stateAverageDiff - 1) * 100;
  let nationalDiffRatio = (value / nationalAverageDiff - 1) * 100;

  // truncate the decimal places to 2
  stateDiffRatio = roundToTwo(stateDiffRatio);
  nationalDiffRatio = roundToTwo(nationalDiffRatio);

  // Benchmark direction and color logic
  const getBenchmarkColor = (diffRatio: number) =>
    diffRatio >= 0 ? "#D3F9D8" : "#FF653257";
  const getBenchmarkTextColor = (diffRatio: number) =>
    diffRatio >= 0 ? "#2B8A3E" : "#FF6532";
  const getBenchmarkIcon = (diffRatio: number) =>
    diffRatio >= 0 ? (
      <ArrowNarrowUp
        size={11}
        style={{ display: "flex" }}
        strokeWidth={2}
        color="#2B8A3E"
      />
    ) : (
      <ArrowNarrowDown
        size={11}
        style={{ display: "flex" }}
        strokeWidth={2}
        color="#FF6532"
      />
    );

  return (
    <Grid.Col span={12}>
      <Grid>
        <Grid.Col span={6}>
          <Tooltip
            label={title}
            position="left"
            withArrow
            offset={10}
            transitionProps={{ transition: "slide-right", duration: 300 }}
            openDelay={300}
          >
            <Text size="sm" truncate>
              {title}
            </Text>
          </Tooltip>
        </Grid.Col>
        <Grid.Col span={6}>
          <Grid justify="flex-end" m={0}>
            <Text weight={700}>
              {prefixChar}
              {displayValue}
              {suffixChar}
            </Text>
            <span>
              {state.benchmark === "state" && (
                <HoverCard>
                  <HoverCard.Target>
                    <Badge
                      variant="filled"
                      sx={{
                        paddingLeft: 4,
                        marginLeft: 14,
                        lineHeight: "inherit",
                        backgroundColor: getBenchmarkColor(stateDiffRatio),
                        color: getBenchmarkTextColor(stateDiffRatio),
                      }}
                      leftSection={getBenchmarkIcon(stateDiffRatio)}
                    >
                      {stateDiffRatio === Infinity ? 0 : stateDiffRatio || 0}%
                    </Badge>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Text size="sm">
                      State Value Benchmark: {prefixChar}{prefixChar === '$' ? roundToInt(stateAverageDiff) : roundToOne(stateAverageDiff)}{suffixChar}
                    </Text>
                  </HoverCard.Dropdown>
                </HoverCard>
              )}
              {state.benchmark === "national" && (
                <HoverCard>
                  <HoverCard.Target>
                    <Badge
                      variant="filled"
                      sx={{
                        paddingLeft: 4,
                        marginLeft: 14,
                        lineHeight: "inherit",
                        backgroundColor: getBenchmarkColor(nationalDiffRatio),
                        color: getBenchmarkTextColor(nationalDiffRatio),
                      }}
                      leftSection={getBenchmarkIcon(nationalDiffRatio)}
                    >
                      {nationalDiffRatio === Infinity ? 0 : nationalDiffRatio || 0}%
                    </Badge>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Text size="sm">
                      National Value Benchmark: {prefixChar}{prefixChar === '$' ? roundToInt(nationalAverageDiff) : roundToOne(nationalAverageDiff)}{suffixChar}
                    </Text>
                  </HoverCard.Dropdown>
                </HoverCard>
              )}
            </span>
          </Grid>
        </Grid.Col>
      </Grid>
    </Grid.Col>
  );
}

export default ValueAndComparisonToAverage;
