import { Flex, Checkbox, Text } from "@mantine/core";
import { FilterTable, SubCategory } from "../../../types";
import { SetStateAction } from "react";

interface OptionsListProps {
  table: FilterTable;
  filters: FilterTable[];
  setFilters: React.Dispatch<SetStateAction<FilterTable[]>>;
  setIsEdited: React.Dispatch<SetStateAction<boolean>>;
}

const OptionsList: React.FC<OptionsListProps> = ({
  table,
  filters,
  setFilters,
  setIsEdited,
}) => {
  const handleFilterChange = (
    tableIndex: number,
    subCategoryIndex: number,
    filterIndex: number,
    checked: boolean
  ) => {
    setFilters((currentFilters) => {
      const newFilters = [...currentFilters];
      newFilters[tableIndex].sub_categories[subCategoryIndex].filters[
        filterIndex
      ].checked = checked;
      return newFilters;
    });
    setIsEdited(true);
  };

  const filterOptionsBuilder = (
    filter: SubCategory,
    filtertable: FilterTable,
    index: number
  ) => {
    const numberOfColumns = filter.filters.length > 8 ? 2 : 1;

    const tableIndex = filters.findIndex(
      (table) => filtertable.category_title === table.category_title
    );
    const subCategoryIndex = filters[tableIndex].sub_categories.findIndex(
      (subCategory) =>
        subCategory.sub_category_title === filter.sub_category_title
    );

    return (
      <Flex
        direction="column"
        gap="md"
        style={{
          alignSelf: "stretch",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "10px 10px",
          gap: "20px",
          border: "1px solid #D9D9D9",
          borderRadius: "5px",
        }}
        key={index}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Text
            style={{
              fontSize: "16px",
              fontWeight: 700,
              color: "black",
              fontFamily: "'DIN 2014'",
              lineHeight: 1.5,
            }}
          >
            {filter.sub_category_title}
          </Text>
          <Checkbox
            checked={filter.filters.some((f) => f.checked)}
            onChange={(event) => {
              setFilters((currentFilters) => {
                const newFilters = [...currentFilters];
                newFilters[tableIndex].sub_categories[
                  subCategoryIndex
                ].filters.forEach((f) => {
                  f.checked = event.currentTarget.checked;
                });
                return newFilters;
              });
              setIsEdited(true);
            }}
          />
        </Flex>

        <div
          style={{
            alignSelf: "stretch",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {filter.filters.map((f, idx) => (
            <div
              style={{
                width: `${60 / numberOfColumns}%`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              key={f.filter_title}
            >
              <Checkbox
                value={f.filter_name}
                label={f.filter_title}
                checked={f.checked}
                onChange={(event) =>
                  handleFilterChange(
                    tableIndex,
                    subCategoryIndex,
                    idx,
                    event.currentTarget.checked
                  )
                }
              >
                {f.filter_title}
              </Checkbox>
            </div>
          ))}
        </div>
      </Flex>
    );
  };

  return (
    <div
      style={{
        flex: "1",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "0px 25px 0px 40px",
        boxSizing: "border-box",
        gap: "10px 0px",
        minWidth: "377px",
        maxWidth: "100%",
        fontSize: "20px",
        color: "#000",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 0px",
          gap: "20px",
        }}
      >
        <Text
          style={{
            margin: "0",
            position: "relative",
            fontSize: "inherit",
            lineHeight: "155%",
            fontWeight: "700",
            fontFamily: "inherit",
          }}
          size="xl"
          weight={700}
        >
          {table.category_title}
        </Text>
      </div>
      {table.sub_categories.map((sub_category, idx) =>
        filterOptionsBuilder(sub_category, table, idx)
      )}
    </div>
  );
};

export default OptionsList;
