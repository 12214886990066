import { useState } from 'react';
import { Modal, TextInput, Checkbox, Button, Group, Stack } from '@mantine/core';

function BufferOptionsModal({ opened, setOpened, onConfirm }: { opened: boolean, setOpened: (value: boolean) => void, onConfirm: (size: number, smoothing: boolean) => void }) {
  const [size, setSize] = useState('50');
  const [smoothing, setSmoothing] = useState(false);

  const handleSubmit = () => {
    // Perform validation or any other logic before submitting
    onConfirm(parseFloat(size), smoothing);
    // Close the modal after confirmation
    setOpened(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Set buffer options"
      styles={{
        body: { paddingBottom: 20 }, // 20px space at the bottom of modal body
        title: { marginBottom: 20 }, // Space below the title
        close: { marginBottom: 20 }, // Space below the close button
      }}
    >
      <Stack spacing={20}>
        <TextInput
          label="Buffer size (in meters)"
          placeholder="Enter buffer size"
          value={size}
          onChange={(event) => setSize(event.currentTarget.value)}
          required
        />

        <Checkbox
          color="orange"
          label="Apply smoothing"
          checked={smoothing}
          onChange={(event) => setSmoothing(event.currentTarget.checked)}
        />
      </Stack>

      <Button color="orange" onClick={handleSubmit} style={{ marginTop: 20 }}>Confirm</Button>
    </Modal>
  );
}

export default BufferOptionsModal;
