import { Dispatch, Map, PolygonCustomType, State } from "../types";
import { getBusinessLocations } from "./getBusinessLocations";
import { getSummaryData } from "./getSummaryData";
import { removeTextInsideShape } from "./handleTextInsideShape";

export const handlePolygonDelete = (
  polygon: PolygonCustomType,
  dispatch: Dispatch,
  state: State,
  userkey: string,
  map?: Map,
) => {
  window.polygons = window?.polygons?.filter((p: any) => {
    let encodedPath = google.maps.geometry.encoding.encodePath(p.getPath());
    if (p.IdBeforeEdit) {
      return encodedPath === p.IdBeforeEdit;
    } else {
      return polygon.getPath() !== p.getPath();
    }
  });

  let polygonId: string = polygon.id;
  let allPolygons: string = "";
  window.polygons.forEach((polygon: any) => {
    let polygonEncodedPath = google.maps.geometry.encoding.encodePath(
      polygon.getPath()
    );
    if (allPolygons.length > 0) allPolygons += ",";
    allPolygons += polygonEncodedPath;
  });
  if (window?.polygons.length) {
    getSummaryData(polygon, allPolygons, map, dispatch, state, userkey, state.mapAssociatedDemographicSettings);
  }
  getBusinessLocations(allPolygons, map, dispatch, state);
  dispatch({ type: "removePolygon", payload: polygon });
  // dispatch({
  //   type: "removeMapPolygons",
  //   payload: { unsplitPolygonId: polygonId, mapPolygons: [] },
  // });
  dispatch({
    type: "setPolygonsArea",
    payload: {
      polygonId: polygonId,
      area: 0,
    },
  });
  dispatch({
    type: "setLetterBoxData",
    payload: {
      polygonId: polygonId,
      residential_count: 0,
    },
  });
  dispatch({
    type: "setBusinessesCount",
    payload: {
      polygonId: polygonId,
      businesses_count: 0,
    },
  });
  dispatch({
    type: "setLetterBoxCountPAF",
    payload: {
      polygonId: polygonId,
      letterbox_count_PAF: 0,
    },
  });

  // dispatch({
  //   type: "setTotalSummaryData",
  //   payload: { polygonId: polygonId, summaryBarData: {} },
  // });
  
  if (state.polygons.length > 0) {
    dispatch({
      type: "setFilterData",
      payload:
        state.totalFiltersRecords[state.totalFiltersRecords.length - 1].filters,
    });
  }
  dispatch({
    type: "setTotalFiltersRecords",
    payload: { polygonId: polygonId, filters: null },
  });
  dispatch({
    type: "setTotalPopulation",
    payload: { unsplitPolygonId: polygonId, population_count: 0 },
  });
  removeTextInsideShape(polygon);
  polygon.setMap(null);
  if (window.polygons.length === 0) {
    if (window.location.pathname === "/") {
      dispatch({ type: "resetStates", payload: null });
      state?.map?.setZoom(12);
    } else {
      map?.setZoom(12);
    }
  }
};
