// old code
// function downloadAddressListCSV() {
//   var addressListContent: any = document.getElementById("address-list-content");
//   let data = addressListContent.innerHTML
//     .replace(/<div (class="[^"]*")>/g, "\n")
//     .replace(/<div>/g, "\n")
//     .replace(/<\/div>/g, "\n")
//     .replace(/<br>/g, "\n")
//     .replace("<b>", "")
//     .replace("</b>", "");
//   let filename = "addressList.csv";

//   var blob = new Blob([data], { type: "text/csv" });
//   let navigator = window.navigator as any;
//   if (navigator.msSaveOrOpenBlob) {
//     navigator.msSaveBlob(blob, filename);
//   } else {
//     var elem = window.document.createElement("a");
//     elem.href = window.URL.createObjectURL(blob);
//     elem.download = filename;
//     document.body.appendChild(elem);
//     elem.click();
//     document.body.removeChild(elem);
//   }
// }

// New code
function downloadAddressListCSV(tableId: any, separator = ",") {
  // Select rows from tableId
  var rows = document.querySelectorAll("table#" + tableId + " tr");
  // Construct csv
  var csv = [];
  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols: any = rows[i].querySelectorAll("td, th");
    for (var j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      var data = cols[j].innerText
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ");
      // Escape double-quote with double-double-quote
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push('"' + data + '"');
    }
    csv.push(row.join(separator));
  }
  var csv_string = csv.join("\n");
  // Download it
  var filename = "addresses-list-" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export { downloadAddressListCSV };
