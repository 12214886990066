import { Button, Group, Text, createStyles, Title } from '@mantine/core';
import React, { FC } from 'react'
import {BrowserRouter as Router, Link} from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    createMapWrapper:{
        maxWidth:'420px',
        margin:'0 auto',
        textAlign:'center'
    },
    titleText:{
        fontSize:'28px',
        marginTop:'52px',
        marginBottom:'37px',
        color:"#777777"

    },
    bodyText:{
        marginBottom:'37px'
    }
  }));

const CreateNewMapModa:FC = () => {
    const { classes } = useStyles();
  return (

    <div className={classes.createMapWrapper}>
       <Title order={1}  align='center' className={classes.titleText}>Looks like this is your first ScopoMap.</Title>
       <Text size="lg" color="#777777" className={classes.bodyText}>Great to have you here, let’s take a few moments for us to show you around.</Text>
        
        <Group  position='center'  pt={5}>
            <Link to="/">
                <Button style={{ minWidth: '230px',marginBottom:'30px' }} variant="filled">Lets's go!</Button>
            </Link>
        </Group>
    </div>
  )
}

export default CreateNewMapModa   