import axios from "axios";
import { fetchLetterBoxCount } from "./fetchLetterBoxCounts";
import { getSummaryData } from "./getSummaryData";
import { getBusinessLocations } from "./getBusinessLocations";
import dayjs from "dayjs";
import { addTextInsideShape } from "./handleTextInsideShape";
import { handlePolygonEdit } from "./handlePolygonEdit";
import { Language, PolygonMouseEvent } from "../types";
import { showPolygonActionsModal } from "./showPolygonActionsModal";
import { polygonValidOrRemove } from "./polygonValidator";
import { getMapAnnotations } from "./mapAnnotation";
import { loadKMLFile } from "./loadKMLFile";

var relativeTime: any = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

type FiltersItemType = {
  name: string;
  title: string;
};
const markers: any = [];
const filtersDataTitles: FiltersItemType[] = [
  { name: "income", title: "Weekly Income" },
  { name: "unit", title: "Units" },
  { name: "median", title: "Median Age" },
  { name: "unemployed", title: "Unemployed" },
  { name: "degree", title: "Uni Degree" },
  { name: "properties", title: "Rental Properties" },
  { name: "internet", title: "Internet at home" },
];

export const openSavedMap = async (
  id: any,
  user: any,
  map: any,
  dispatch: any,
  state: any,
  userkey: string,
  guid?: string,
  lgaCode16?: string,
  salCode21?: string,
  sa1Code7Digit?: string
) => {
  try {
    let url: string = id
      ? `saved_map_data/${id}`
      : guid?.toString()
      ? `shared_map_data/${guid}`
      : lgaCode16?.toString()
      ? `lga_map/${lgaCode16}`
      : salCode21?.toString()
      ? `sal_map/${salCode21}`
      : `statistical_area_1_map/${sa1Code7Digit}`;
    console.log(url);
    if (state.trialExpired || state.subscriptionExpired) return;
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}demographics/${url}/`,
      {
        headers: user && {
          Authorization: `token ${user.key}`,
        },
      }
    );

    if (response.data.read_only === "false" && response.data.job_id != 0) {
      window.location.replace(`/${response.data.job_id}`);
    }
    dispatch({ type: "addJobId", payload: id });
    const { filter_data: appliedFilters } = response.data;
    let filters = appliedFilters ? JSON.parse(appliedFilters) : {};
    if(filters.constructor.name == "Array")
      filters = {};

    dispatch({
      type: "setMapAssociatedDemographicSettings",
      payload: response.data.demographics_settings,
    });

    dispatch({
      type: "setAppliedFilters",
      payload: filters,
    });

    dispatch({
      type: "setFilterDataEntire",
      payload: filters,
    });

    Object.keys(filters).find((fItem: any) => {
      if (fItem === "languages") {
        filters[fItem].forEach((lang: Language) => {
          dispatch({
            type: "setFiltersDataToSave",
            payload: lang.name + " Speakers",
          });
        });
      }
      filtersDataTitles.forEach((item: FiltersItemType) => {
        if (fItem.toLowerCase().includes(item.name) && fItem.includes("max")) {
          dispatch({
            type: "setFiltersDataToSave",
            payload: item.title,
          });
        }
      });
      return null;
    });
    if (
      response.data.encoded_polygons.length &&
      response.data.encoded_polygons[0] !== ""
    ) {
      let bounds = new google.maps.LatLngBounds();
      let allPolygons = "";
      let polygonsDone = 0;
      response.data.encoded_polygons.forEach((poly: any) => {
        let coords: any[] = [];
        let singlePolygonPath = google.maps.geometry.encoding.decodePath(poly);
        coords.push(singlePolygonPath);
        singlePolygonPath.forEach((latLng: any) => {
          bounds?.extend(latLng);
        });
        let polygon: any = new google.maps.Polygon({
          paths: coords,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
        if (polygonValidOrRemove(polygon)) {
          polygon.id = poly;
          window.polygons.push(polygon);
          // code for adding letterbox count inside polygon.
          var polygonBounds = new google.maps.LatLngBounds();
          for (let i = 0; i < polygon.getPath().getLength(); i++) {
            polygonBounds.extend(polygon.getPath().getAt(i));
          }
          fetchLetterBoxCount(poly, dispatch, polygon.id)
            .then((response: any) => {
              addTextInsideShape(
                polygon,
                polygonBounds.getCenter(),
                map,
                response["Residential count"] + response["Business exact count"]
              );
            })
            .catch((err) => console.log("error in fetching summary data", err));

          if (!guid) {
            polygon.addListener("click", () => {
              if (polygon.editable) {
                handlePolygonEdit(polygon, map, dispatch, userkey, state);
              }
              return;
            });
            polygon.addListener("contextmenu", (e: PolygonMouseEvent) =>
              showPolygonActionsModal(polygon, dispatch, state, e.latLng!, userkey, map)
            );
          }
          polygon.setMap(map);
          map.fitBounds(bounds);
          map.setCenter(bounds.getCenter());
          dispatch({ type: "setPolygons", payload: polygon });
          dispatch({ type: "setCheck", payload: true });
          if (allPolygons.length > 0) allPolygons += ",";
          allPolygons += poly;
        }
        if (++polygonsDone === response.data.encoded_polygons.length) {
          getSummaryData(polygon, allPolygons, map, dispatch, state, userkey, response.data.demographics_settings);
          getBusinessLocations(allPolygons, map, dispatch, state);
          dispatch({ type: "showSummaryBar", payload: true });
        }
      });
    }

    if (response.data.kml_string) {
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(response.data.kml_string, "text/xml");
      loadKMLFile(xmlDoc, state, dispatch, userkey);
    }

    if (id) {
      try {
        await getMapAnnotations(map, user, id, markers, dispatch);
      } catch (e) {
        console.log("error", e);
      }
    }

    return response.data.job_name;
  } catch (e) {
    console.log(e);
  }
};
