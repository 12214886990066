import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { Outlet } from 'react-router-dom'
import { X } from 'tabler-icons-react';
import { Button, Group, Modal, Text } from '@mantine/core';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';


const NotificationsLayout = () => {
    const navigate = useNavigate();
    const [opened, setOpened] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>('')

    axios.interceptors.response.use(
    (res) => {
        // Add configurations here
        if (res.status === 200) {
        // console.log("Posted Successfully");
        }
        return res;
    },
    (err) => {
        let message = `${
            (err.response.data?.non_field_errors &&
                err.response.data?.non_field_errors[0]) ||
            err.response.data["email"] ||
            err.response.data["username"] ||
            err.response.data ||
            "Please try again"
        }`;

        
        if (err.response.status === 400) console.log("error", err);
        if (err.response.status === 402) {
        setModalTitle(err.response.statusText || "Payment Required")
        setOpened(true)
        // message += " You'll be redirected to the settings page, where you or your team's admin can rectify the issue.";
        // setTimeout(() => { window.location.href = '/settings' }, 5000);
        } else {
            showNotification({
                id: "err-notification",
                title: "Request Failed!",
                message: message,
                autoClose: 5000,
                color: "red",
                icon: <X />,
                styles: (theme) => ({
                    root: {
                    backgroundColor: theme.white,
                    borderColor: "red",
                    "&::before": { backgroundColor: theme.white },
                    },
                    title: { color: "black" },
                    description: { color: "black" },
                    closeButton: {
                    color: theme.black,
                    },
                }),
            });
        }
        return Promise.reject(err);
    }
    );
  return (  
  <>
     <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title= {modalTitle}
        color= "orange"
        trapFocus={false}
        closeOnClickOutside={false}
      >
        <Text size="sm">
            You have reached the limit of current plan. You need to upgrade your plan to proceed.
        </Text>
        <Group position="right" mt={15}>
            <Button color={"orange"} onClick={() => {
                setOpened(false)
                navigate(`/settings`);
                }}>Upgrade Plan</Button>
        </Group>
      </Modal> 
   <Outlet />
   </>
  )

}

export default NotificationsLayout