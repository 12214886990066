import { Navigate } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

const ProtectedRoute = (props: any) => {
  const { user } = useAuth();
  // const user = true;
  if (!user) {
    return <Navigate to="/login" />;
  }
  return props.children;
};
export default ProtectedRoute;
