import { forwardRef } from 'react';
import { ChevronRight, ChevronUp } from 'tabler-icons-react';
import { Group, Avatar, Text, Menu, UnstyledButton } from '@mantine/core';

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  image: string;
  name: string;
  email: string;
  icon?: React.ReactNode;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, email, icon, ...others }: UserButtonProps, ref) => (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.md,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          borderRadius: theme.radius.sm,
          backgroundColor: '#3d3c3e',
        },
      })}
      {...others}
    >
      <Group>
        <div style={{ flex: 1 }}>
          <Text size="sm" weight={500} style={{
            color: 'white',
          }}>
            {name}
          </Text>
        </div>

        {icon || <ChevronUp size="1rem"  color='white'/>}
      </Group>
    </UnstyledButton>
  )
);

export default UserButton;
