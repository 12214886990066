import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Group, Text, Button, Textarea, Loader } from "@mantine/core"; // added Loader import
import $ from "jquery";
import storeContext from "../store/store";
import { getNewAPIMapSummaryCSV } from "../map-utils/downloadSummary";

const AIChatWindow = (props: any) => {
  const context = useContext<any>(storeContext);
  const [interogationInput, setInterogationInput] = useState("");
  const [interogationSession, setInterogationSession] = useState([
    {
      role: "system",
      content: "You are an Australia urban planning consultant.",
    },
  ]);
  const [interogationResponse, setInterogationResponse] = useState("");
  const [loading, setLoading] = useState(false); // state to manage loader

  const handleSendButtonClick = async () => {
    setLoading(true); // Show loader
    let sessionSoFar: { role: string; content: string }[] = interogationSession;
    if (sessionSoFar.length == 1) {
      let summaryCSV = getNewAPIMapSummaryCSV(context);
      sessionSoFar.push({
        role: "user",
        content:
          "In respect to the following CSV, describing demographics in an area of Australia, please answer in at least 50 characters, preferably 200-300 characters, and don't mention the data being in a CSV in your answer.\n" +
          summaryCSV,
      });
    }
    sessionSoFar.push({ role: "user", content: interogationInput });
    let data = new FormData();
    data.append("session_so_far", JSON.stringify(sessionSoFar));
    setInterogationSession(sessionSoFar);
    const chatResponse = await axios.post(
      `${process.env.REACT_APP_BASE_URL}demographics/inetractive_ai_chat`,
      data
    );

    sessionSoFar.push(chatResponse.data);
    console.log(chatResponse.data);
    console.log(chatResponse.data.content);
    setInterogationResponse(chatResponse.data.content);

    let newElement = document.getElementById("ai-chat-content");
    if (newElement) {
      let currentInnerHTML = newElement.innerHTML;
      let newInnerHTML =
        currentInnerHTML +
        "<p>" +
        interogationInput +
        "</p>" +
        '<p style="color: grey;"><i>' +
        chatResponse.data.content +
        "</i></p>";
      newElement.innerHTML = newInnerHTML;
    }
    setInterogationInput("");
    $("#ai-chat-content").scrollTop(10000);
    setLoading(false); // Hide loader
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        handleSendButtonClick();
      }
    };

    const inputElement = document.getElementById("ai-chat-input");
    inputElement?.addEventListener("keydown", handleKeyDown);

    return () => {
      inputElement?.removeEventListener("keydown", handleKeyDown);
    };
  }, [interogationInput]);

  return (
    <div>
      <Text size="xl" mb="xs">
        AI Interaction
      </Text>
      <Group align="flex-end">
        <div id="ai-chat-content" className="comments-box ai-chat-box"></div>
        <Textarea
          id="ai-chat-input"
          className="ai-chat-input"
          placeholder="Your question..."
          style={{ flex: 1 }}
          value={interogationInput}
          onChange={(event) => setInterogationInput(event.target.value)}
          disabled={loading} // disable input when loading
        />
        <Button
          id="ai-chat-send"
          onClick={handleSendButtonClick}
          disabled={loading} // disable button when loading
        >
          {loading ? <Loader size="xs" color="white" /> : "Send"}
        </Button>
      </Group>
    </div>
  );
};

export default AIChatWindow;
