import {
  Breadcrumbs,
  createStyles,
  Grid,
  LoadingOverlay,
  Space,
  Table,
  Text,
  TextInput,
  Menu,
} from "@mantine/core";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppShellLayout from "../components/AppShellLayout";
import { useAuth } from "../hooks/useAuth";
import { fetchUserSubscriptionDetails } from "../map-utils/fetchUserSubscriptionDetails";
import { SubscriptionDetail } from "../types";
import { reactivateOrCancelSubscription } from "../map-utils/reactivateOrCancelSubscription";
import { showNotification } from "@mantine/notifications";
import { Check, X, Settings as Settings2 } from "tabler-icons-react";
import EditProfileInfo from "../components/EditProfileInfo";

const useStyles = createStyles((theme) => ({
  button: {
    backgroundColor: "#FD7E14",
    minWidth: "160px",
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      width: "100%",
    },
  },
  tableHead: {
    backgroundColor: '#F4F7FC',
  },
  tableRow: {
    border: '2px solid #ccc',
    '& > *': {
      fontSize: '15px !important',
      padding: '10px 0',
      color: '#000 !important',
    }
  },
  settingDropDown: {
    position: "absolute",
    top: '90px',
    right: '180px',
  },
  settingIcon: {
    maxWidth: '530px',
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'space-between',
  },
  settingIconDetails: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  settingItems: {
    fontSize: '16px',
    fontWeight: 'normal',
    padding: '12px 12px',
    '&:hover': {
      background: '#F4F7FC'
    }
  },
  iconSetting: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  detailsTitle: {
    fontSize: '24px'
  }
}));

const Settings = () => {
  const { user } = useAuth();
  const [, setUserInfo] = useLocalStorage("user", user);
  const [subDetail, setSubDetail] = useState<SubscriptionDetail>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [adminViewing, setAdminViewing] = useState<boolean>(false);
  const [adminViewingUsername, setAdminViewingUsername] = useState<string>('');
  const [adminViewingOrgName, setAdminViewingOrgName] = useState<string>('');
  const [adminViewingStripeCustomerId, setAdminViewingStripeCustomerId] = useState<string>('');
  const [adminViewingAnother, setAdminViewingAnother] = useState<boolean>(false);
  const { classes } = useStyles();

  const navigate = useNavigate();

  const handleFetchUserSubscriptionDetails = () => {
    fetchUserSubscriptionDetails(user?.key, adminViewingUsername, adminViewingOrgName, adminViewingStripeCustomerId)
      .then((response) => {
        user.subscriptionDetails = response;
        setUserInfo(user);
        setSubDetail(response);
        setAdminViewing(response.is_admin);
        setAdminViewingAnother(response.admin_viewing_another);
      })
      .catch((err) =>
        console.log("error in fetching subscription details", err)
      );
  };

  useEffect(() => {
    handleFetchUserSubscriptionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.key]);

  const handleReactivateOrCancelSubscription = () => {
    setLoading(true);
    reactivateOrCancelSubscription(
      subDetail && subDetail["cancel_at_period_end"],
      user.key
    )
      .then((res) => {
        let failed: boolean = res === "Failure" ? true : false;
        showNotification({
          id: "subs-notification",
          title: "Updating Subscription!",
          message: res,
          autoClose: 2000,
          color: failed ? "red" : "green",
          icon: failed ? <X /> : <Check />,
          styles: (theme) => ({
            root: {
              backgroundColor: theme.white,
              borderColor: failed ? "red" : "green",
              "&::before": { backgroundColor: theme.white },
            },
            title: { color: "black" },
            description: { color: "black" },
            closeButton: {
              color: theme.black,
            },
          }),
        });
        handleFetchUserSubscriptionDetails();
        setLoading(false);
      })
      .catch((err) => {
        console.log("error in updating subscription", err);
        setLoading(false);
      });
  };

  return (
    <AppShellLayout>
      <LoadingOverlay
        visible={loading}
        loaderProps={{ size: "xl", color: "orange" }}
        overlayOpacity={0.7}
      />
      <Grid mt={38} className="title-row">
        <Grid.Col sm={6} className="title-col">
          <Breadcrumbs
            styles={{
              breadcrumb: { fontSize: "45px", fontWeight: "bold" },
            }}
            separator=""
          >
            <Text size="lg">
              Settings
            </Text>
          </Breadcrumbs>
        </Grid.Col>
      </Grid>

      {!adminViewingAnother && (
        <div>
          <Space h="xl" />
          <EditProfileInfo username={user.username} orgname={user?.subscriptionDetails?.org_name} adminViewingAnother={false} />
          <Space h="xl" />
          <Space h="xl" />

          <div className={classes.settingIcon}>
            <Text size="xl" className={classes.detailsTitle}>
              Subscription Details
            </Text>
            <Menu
              position="bottom"
              transitionProps={{
                transition: "slide-up",
                duration: 300,
              }}

              trigger="click"
            >
              <Menu.Target>
                <div className={classes.iconSetting}>
                  <Settings2
                    size={28}
                    strokeWidth={2.3}
                    color={'#999'}
                  />
                </div>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item className={classes.settingItems}>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://billing.stripe.com/p/login/dR6g0N42z124ddCeUU"
                    style={{ textDecoration: 'none', width: '100%', color: 'black' }}
                  >
                    Payment Method
                  </a>
                </Menu.Item>

                <Menu.Item
                  className={classes.settingItems}
                  onClick={() => navigate("/pricing")}
                >
                  Change Plan
                </Menu.Item>

                <Menu.Item
                  className={classes.settingItems}
                  onClick={() => handleReactivateOrCancelSubscription()}
                >
                  {subDetail && subDetail["cancel_at_period_end"]
                    ? "Reactivate Subscription"
                    : "Cancel Subscription"}
                </Menu.Item>
              </Menu.Dropdown>

            </Menu>
          </div>
          <Space h="xl" />
          <div className="table-responsive">
            <Table verticalSpacing="md" className="table-inner">
              <thead className={classes.tableHead}>
                <tr className={classes.tableRow}>
                  <th>Type/Name</th>
                  <th>No. Of Users</th>
                  <th>No. Of Available Users</th>
                  <th>Expires In (Days)</th>
                  <th>Address Downloads</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.tableRow}>
                  <td>{subDetail?.subscription_name}</td>
                  <td>{subDetail?.number_of_users}</td>
                  <td>{subDetail?.users_available_in_subscription}</td>
                  <td>
                    {subDetail
                      ? Math.max(
                        Math.ceil(
                          (subDetail.subscription_expires - Date.now() / 1000) /
                          86400
                        ),
                        0
                      )
                      : 0}
                  </td>
                  <td>
                    <a href="/adderss-downloads-report">{subDetail?.addresses_downloaded}</a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      )}
      {adminViewing && (
        <div>
          <br />
          <h2>Find a Client</h2>
          <TextInput
            required
            placeholder="Username"
            value={adminViewingUsername}
            onChange={(event) =>
              setAdminViewingUsername(event.currentTarget.value)
            }
          />
          <TextInput
            required
            placeholder="Org Name"
            value={adminViewingOrgName}
            onChange={(event) =>
              setAdminViewingOrgName(event.currentTarget.value)
            }
          />
          <TextInput
            required
            placeholder="Stripe Customer ID"
            value={adminViewingStripeCustomerId}
            onChange={(event) =>
              setAdminViewingStripeCustomerId(event.currentTarget.value)
            }
          />
          <button onClick={handleFetchUserSubscriptionDetails}>
            Fetch Subscription
          </button>
        </div>
      )}
      {adminViewingAnother && (
        <div>
          <br />
          <Text size="xl" className={classes.detailsTitle}>
            Requested Account - Subscription Details
          </Text>

          <Space h="xl" />
          <EditProfileInfo username={adminViewingUsername} orgname={subDetail?.org_name ? subDetail?.org_name : ''} adminViewingAnother={adminViewingAnother} />
          <Space h="xl" />
          <Space h="xl" />

          <div className="table-responsive">
            <Text size="lg" className={classes.detailsTitle}>
              Admin email - {subDetail?.org_email}
            </Text>
            <Text size="lg" className={classes.detailsTitle}>
              Org ID - {subDetail?.org_id}
            </Text>
            <Text size="lg" className={classes.detailsTitle}>
              Subscription ID - {subDetail?.subscription_id}
            </Text>
            <Text size="lg" className={classes.detailsTitle}>
              Stripe Customer - {subDetail?.stripe_customer_id}
            </Text>
            <Text size="lg" className={classes.detailsTitle}>
              Stripe Subscription - {subDetail?.stripe_subscription_id}
            </Text>
            <Table verticalSpacing="md" className="table-inner">
              <thead className={classes.tableHead}>
                <tr className={classes.tableRow}>
                  <th>Type/Name</th>
                  <th>No. Of Users</th>
                  <th>No. Of Available Users</th>
                  <th>Expires In (Days)</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.tableRow}>
                  <td>{subDetail?.subscription_name}</td>
                  <td>{subDetail?.number_of_users}</td>
                  <td>{subDetail?.users_available_in_subscription}</td>
                  <td>
                    {subDetail
                      ? Math.max(
                        Math.ceil(
                          (subDetail.subscription_expires - Date.now() / 1000) /
                          86400
                        ),
                        0
                      )
                      : 0}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </AppShellLayout>
  );
};

export default Settings;
