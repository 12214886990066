import { useAuth } from "../hooks/useAuth";

import Main from "./Main";

const ViewMap = () => {
  const { user } = useAuth();

  return <Main isSharedMap={true} isLGAMap={false} isSALMap={false} isSA1Map={false} loggedIn={user} />;
};

export default ViewMap;
