import { Global } from "@mantine/core";
import { FC } from "react";

const GlobalStyles: FC = () => {
  return (
    <Global
      styles={(theme) => ({
        ".left-container": {
          [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
            padding: "0 30px",
          },
        },
        ".title-text": {
          fontSize: "40px",
          fontWeight: "bold",
          textAlign: "center",
          [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
            fontSize: "36px",
          },
        },
        ".hr-divider": {
          margin: "20px 0",
          fontSize: 16,
          [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
            margin: "16px 0",
          },
        },
        ".sub-title-text": {
          color: theme.colors.allColors[9],
          //   fontFamily: "DIN 2014",
          fontSize: "36px",
          fontWeight: 500,
          letterSpacing: -1,
          lineHeight: "39px",
          marginBottom: "32px",
          textAlign: "center",
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            fontSize: "28px",
            marginBottom: "18px",
          },
        },
        ".avatar": {
          width: "84px",
          minWidth: "84px",
          height: "84px",
          borderRadius: "8px",
          marginTop: 15,
          [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
            width: "64px",
            minWidth: "64px",
            height: "64px",
            borderRadius: "5px",
          },
        },
        ".icon-main": {
          width: 58,
          height: 58,
          [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
            width: 48,
            height: 48,
          },
        },
        ".sub-text": {
          margin: "0",
          maxWidth: "320px",
          width: "100%",
          fontSize: "16px",
          lineHeight: "22px",
          color: theme.colors.allColors[9],
          textAlign: "center",
        },
        ".logo-wrapper": {
          [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
            maxWidth: "150px",
          },
        },
        ".customDrawer": {
          [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
            maxWidth: "232px",
          },
        },
        ".nav-main ": {
          display: "block",
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            top: "0px !important",
            border: "0px",
          },
          [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
            width: "100%",
          },
        },
        ".nav-secondary ": {
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            width: "200px",
          },
        },
        ".pagination": {
          gap: 0,
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            marginTop: "15px",
          },
        },
        ".table-responsive": {
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            overflowX: "scroll",
          },
        },
        ".table-inner": {
          minWidth: "500px",
        },
        ".change-pass-shell-body": {
          height: `calc(100vh - 89px)`,
          overflow: "hidden",
          [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            height: `calc(100vh - 79px)`,
            overflow: "hidden",
          },
        },
        ".change-pass-shell-main": {
          height: `calc(100vh - 79px)`,
          overflow: "hidden",
        },
      })}
    />
  );
};

export default GlobalStyles;
