import { FC, useState } from "react";
import {
  Grid,
  Group,
  Text,
  Button,
  TextInput,
  MediaQuery,
  Stack,
} from "@mantine/core";
import axios from "axios";

import { useAuth } from "../hooks/useAuth";
import { useForm } from "@mantine/form";
import AppHeader from "../components/AppHeader";
import { Check } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";

const ChangePassword: FC = () => {
  const [, setShowAlert] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },

    validate: {
      oldPassword: (val: any) =>
        val.length < 6
          ? "Old Password must be at least 6 characters long"
          : null,
      newPassword: (val: any) =>
        val.length < 6
          ? "New Password must be at least 8 characters long"
          : null,
      confirmPassword: (val: any) =>
        val.length < 6
          ? "Confirm Password must be at least 8 characters long"
          : null,
    },
  });

  const handleSubmit = async (values: any) => {
    setShowAlert(false);
    if (values.newPassword !== values.confirmPassword) {
      form.setErrors({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "Passwords don't match",
      });
      setShowAlert(true);
      return;
    }

    let formdata = new FormData();
    try {
      formdata.append("old_password", values.oldPassword);
      formdata.append("new_password1", values.newPassword);
      formdata.append("new_password2", values.confirmPassword);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}dj-rest-auth/password/change/`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `token ${user.key}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            showNotification({
              id: "password-nofif",
              title: "Password Updated Successfully!",
              message: `${response.data.detail}! 🤥`,
              autoClose: 1000,
              color: "green",
              icon: <Check />,
              styles: (theme) => ({
                root: {
                  backgroundColor: theme.white,
                  borderColor: "green",

                  "&::before": { backgroundColor: theme.white },
                },

                title: { color: "black" },
                description: { color: "black" },
                closeButton: {
                  color: theme.black,
                },
              }),
            });
            setTimeout(() => {
              logout();
              navigate("/login");
            }, 1000);
          }
        })
        .catch((error) => {
          form.setErrors({
            oldPassword: error.response.data.old_password
              ? error.response.data.old_password[0]
              : "",
            newPassword: error.response.data.new_password1
              ? error.response.data.new_password1[0]
              : "",
            confirmPassword: error.response.data.new_password2
              ? error.response.data.new_password2[0]
              : "",
          });
          setShowAlert(true);
        });
    } catch (error) {
      setShowAlert(true);
    }
  };

  return (
    <>
      <AppHeader
        extraClassNames={{
          body: "change-pass-shell-body",
          main: "change-pass-shell-main",
        }}
      >
        <Grid p={0} m={0}>
          <Grid.Col xs={12} sm={6} lg={7} p={0} className="left-container">
            <div className="log-left change-pas">
              <div className="main-box">
                <Text
                  weight={500}
                  // style={{
                  //   fontSize: 26,
                  //   marginBottom: 10,
                  //   textAlign: "center",
                  // }}
                  sx={(theme) => ({
                    fontSize: 30,
                    marginBottom: 10,
                    textAlign: "center",
                    "@media (max-width: 768px)": {
                      fontSize: 26,
                    },
                  })}
                >
                  Change Your Password
                </Text>
                <form
                  onSubmit={form.onSubmit((values) => handleSubmit(values))}
                >
                  <Stack>
                    <TextInput
                      type="password"
                      required
                      placeholder="Old Password"
                      value={form.values.oldPassword}
                      onChange={(event) =>
                        form.setFieldValue(
                          "oldPassword",
                          event.currentTarget.value
                        )
                      }
                      error={form.errors.oldPassword}
                    />
                    <TextInput
                      type="password"
                      required
                      placeholder="New Password"
                      value={form.values.newPassword}
                      onChange={(event) =>
                        form.setFieldValue(
                          "newPassword",
                          event.currentTarget.value
                        )
                      }
                      error={form.errors.newPassword}
                    />
                    <TextInput
                      type="password"
                      required
                      placeholder="Confirm New Password"
                      value={form.values.confirmPassword}
                      onChange={(event) =>
                        form.setFieldValue(
                          "confirmPassword",
                          event.currentTarget.value
                        )
                      }
                      error={form.errors.confirmPassword}
                    />
                    <Button type="submit" color={"orange"}>
                      Submit
                    </Button>
                  </Stack>
                </form>
              </div>
            </div>
          </Grid.Col>
          <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <Grid.Col xs={12} sm={6} lg={5} p={0}>
              <div className="signup-right">
                <div className="signup-img">
                  <div className="loc-img loc-1">
                    <img src="/images/location-icon.svg" alt="location icon" />
                  </div>
                  <div className="loc-img loc-2">
                    <img src="/images/location-icon.svg" alt="location icon" />
                  </div>
                  <div className="loc-img loc-3">
                    <img src="/images/location-icon.svg" alt="location icon" />
                  </div>
                  <div className="logo">
                    <img src="/images/logo.png" alt="logo" />
                  </div>
                  <div className="text-box">
                    <Text className="text" color={"#fff"}>
                      Powerful.
                      <br /> Insights.
                      <br /> Easy.
                      <br />
                    </Text>
                  </div>
                  <img src="/images/login-bg.svg" alt="" />
                </div>
              </div>
            </Grid.Col>
          </MediaQuery>
        </Grid>
      </AppHeader>
    </>
  );
};

export default ChangePassword;
