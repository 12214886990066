import {
  createStyles,
  Breadcrumbs,
  Text,
  TextInput,
  Button,
  Grid,
  Space,
  Modal,
} from "@mantine/core";
import { useState, useEffect, useCallback } from "react";
import TeamList from "../components/TeamList";
import { Search, Plus } from "tabler-icons-react";
import InviteMemberModal from "../components/InviteMemberModal";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import TopNotification from "../components/TopNotification";
import { TeamMemberType } from "../types";
import AppShellLayout from "../components/AppShellLayout";

const useStyles = createStyles((theme) => ({
  breadCrumb: {
    fontSize: 22,
  },
  breadCrumbSeperator: {
    fontSize: "30px",
    color: "red",
  },
  mapUserWrapper: {
    padding: "0px 0px",
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      padding: "0px 30px",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      padding: `0px 83px ${" "}!important`,
    },
    mainMapShell: {
      backgroundColor: "red",
    },
  },
}));

const Team = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState<boolean>(false);
  const [members, setMembers] = useState<TeamMemberType[]>([]);
  const [orgAdmin, setOrgAdmin] = useState<boolean>(false);
  const [filterMembers, setFilterMembers] = useState<TeamMemberType[]>([]);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const { user, teamName } = useAuth();

  const getMembers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}subscriptions/user_list/`,
        {
          headers: {
            Authorization: `token ${user.key}`,
          },
        }
      );
      setMembers(response.data.users);
      setOrgAdmin(response.data.org_admin)
      setFilterMembers(response.data.users);
      setIsLoad(true);
    } catch (e) {
      console.log(e);
    }
  }, [user.key]);

  const handleChange = (value: string) => {
    if (value.length >= 3) {
      const newUsers = members.filter((ele: any) =>
        ele.username.toLowerCase().includes(value.toLowerCase())
      );
      setFilterMembers(newUsers);
    }

    if (value.length === 0) {
      setFilterMembers(members);
    }
  };

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  return (
    <AppShellLayout>
      <div className={classes.mapUserWrapper}>
        <Grid mt={38} align="center">
          <Grid.Col span={12} sm={8}>
            <Breadcrumbs
              styles={{
                breadcrumb: { fontSize: "22px", fontWeight: "bold" },
              }}
              separator=""
            >
              <Text size="lg">{teamName} - Users</Text>
            </Breadcrumbs>
          </Grid.Col>
          <Grid.Col span={12} sm={4}>
            <div style={{ textAlign: "right" }}>
              <Button
                color={"orange"}
                onClick={() => setOpened(true)}
                leftIcon={<Plus size={14} strokeWidth={3} color={"#fff"} />}
              >
                Invite Team Members
              </Button>
            </div>
          </Grid.Col>
        </Grid>

        <Space h="lg" />
        <Grid>
          <Grid.Col lg={8}>
            <TextInput
              radius="lg"
              placeholder="Search"
              icon={<Search size={18} strokeWidth={2} color={"black"} />}
              onChange={(e) => handleChange(e.target.value)}
            />
          </Grid.Col>
        </Grid>
        <TeamList
          members={filterMembers}
          orgAdmin={orgAdmin}
          isLoad={isLoad}
          setMembers={setFilterMembers}
        />
      </div>
      <Modal
        centered
        padding={0}
        opened={opened}
        onClose={() => setOpened(false)}
        title="Invite to Team"
      >
        <InviteMemberModal close={setOpened} />
      </Modal>
    </AppShellLayout>
  );
};

export default Team;
