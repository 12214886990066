import { FC, useState } from "react";
import { useForm } from "@mantine/form";
// import { Link } from "react-router-dom";
import { Grid, TextInput, Text, Group, Button, Avatar, Stack } from "@mantine/core";
import "../css/login.css";
import { Check, Key, X } from "tabler-icons-react";

import BrandSection from "../components/BrandSection";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";

const ResetPassword: FC = () => {
  const [, setShowAlert] = useState(false);
  const params: any = useParams();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validate: {
      password: (val: any) =>
        val.length < 8 ? "Password must be at least 8 characters long" : null,
      confirmPassword: (val: any, values) =>
        val.length < 8
          ? "Confirm Password must be at least 8 characters long"
          : val !== values.password
          ? "Passwords did not match"
          : null,
    },
  });
  const handleSubmit = async (values: any) => {
    setShowAlert(false);
    let formdata = new FormData();
    try {
      formdata.append("uid", params.uid);
      formdata.append("token", params.token);
      formdata.append("new_password1", values.password);
      formdata.append("new_password2", values.confirmPassword);
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}dj-rest-auth/password/reset/confirm/ `,
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        showNotification({
          id: "reset-password",
          title: "Password Saved Successfully!",
          message: `${response.data.detail}!`,
          autoClose: 2000,
          color: "green",
          icon: <Check />,
          styles: (theme) => ({
            root: {
              backgroundColor: theme.white,
              borderColor: "green",

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: "black" },
            description: { color: "black" },
            closeButton: {
              color: theme.black,
            },
          }),
        });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    } catch {
      setShowAlert(true);
      showNotification({
        id: "reset-password",
        title: "Password reset failed!",
        message: `Please try again!`,
        autoClose: 3000,
        color: "red",
        icon: <X />,
        styles: (theme) => ({
          root: {
            backgroundColor: theme.white,
            borderColor: "red",

            "&::before": { backgroundColor: theme.white },
          },

          title: { color: "black" },
          description: { color: "black" },
          closeButton: {
            color: theme.black,
          },
        }),
      });
    }
  };
  return (
    <Grid p={0} m={0}>
      <Grid.Col xs={12} sm={6} lg={7} p={0} className="left-container">
        <div className="log-left">
          <div className="main-box">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 15,
              }}
            >
              <Avatar
                className="avatar"
                styles={{ placeholder: { backgroundColor: "#fd7e14" } }}
              >
                <Key strokeWidth={2} color={"white"} className="icon-main" />
              </Avatar>
            </div>
            <Text className="sub-title-text">Reset Your Password</Text>
            {/* <Text size="md" weight={300} className={classes.subText}>
              Enter the email address associated with your account and we'll
              send you a link to reset your account.
            </Text> */}
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Stack>
                <TextInput
                  type="password"
                  placeholder="Password"
                  value={form.values.password}
                  onChange={(event) =>
                    form.setFieldValue("password", event.currentTarget.value)
                  }
                  error={form.errors.password}
                />
                <TextInput
                  type="password"
                  placeholder="Confirm Password"
                  value={form.values.confirmPassword}
                  onChange={(event) =>
                    form.setFieldValue(
                      "confirmPassword",
                      event.currentTarget.value
                    )
                  }
                  error={form.errors.confirmPassword}
                />
                <Button type="submit" color={"orange"} mt={10}>
                  Submit
                </Button>
              </Stack>
            </form>
          </div>
        </div>
      </Grid.Col>
      <BrandSection />
    </Grid>
  );
};

export default ResetPassword;
