import { useAuth } from "../hooks/useAuth";

import Main from "./Main";

const LGAMap = () => {
  const { user } = useAuth();

  return <Main isSharedMap={false} isLGAMap={true} isSALMap={false} isSA1Map={false} loggedIn={user} />;
};

export default LGAMap;
