import { UnstyledButton, Group, Avatar, Text } from "@mantine/core";
import { FC } from "react";

type Props = {
  title: string;
  onClick: () => void;
};
const GoogleBtn: FC<Props> = ({ title, onClick }: Props) => {
  return (
    <Group
      grow
      mb="md"
      mt="md"
      onClick={onClick}
      sx={(theme) => ({
        [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
          marginBottom: 10,
          marginTop: 10,
        },
      })}
    >
      <UnstyledButton
        style={{
          border: "1px solid #4285f4",
          borderRadius: "5px",
          height: "34px",
          backgroundColor: "#4285f4",
        }}
      >
        <Group style={{ gap: 0, flexWrap: "nowrap" }}>
          <Avatar
            src={process.env.REACT_APP_PUBLIC_FOLDER + "google-logo.png"}
            size={26}
            color="blue"
            style={{
              padding: "3px 5px",
              background: "#fff",
              boxSizing: "revert",
              borderRadius: 0,
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
          />
          <div style={{ width: "100%" }}>
            <Text
              style={{
                color: "#ffffff",
                backgroundColor: " #4285F4",
                maxWidth: "272px",
                width: "100%",
                height: "32px",
                padding: "4px 0px",
                textAlign: "center",
              }}
            >
              {title} with Google
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    </Group>
  );
};

export default GoogleBtn;
