import { FC, useState } from "react";
import { useForm } from "@mantine/form";
import { Link } from "react-router-dom";
// import { ChevronDown, Container, Forms } from "tabler-icons-react";
import {
  Grid,
  TextInput,
  Text,
  Group,
  Button,
  Divider,
  Anchor,
  Checkbox,
  MediaQuery,
  Stack,
} from "@mantine/core";
import "../css/signup.css";
import { GLogin } from "../components/GoogleAuth";

import axios from "axios";
// import AxiosError from "axios";
import { useAuth } from "../hooks/useAuth";
import BrandSection from "../components/BrandSection";
//import LinkedInTag from 'react-linkedin-insight';

const Signup: FC = () => {
  const [, setShowAlert] = useState(false);
  const { login } = useAuth();
  const TRACKING_ID = process.env.REACT_APP_LINKEDIN_TRACKING;

  //LinkedInTag.init(TRACKING_ID || "", 'dc');

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      organisationName: "",
      mailingListOptIn: false,
    },

    validate: {
      firstName: (val) =>
        val.length < 2 ? "First name must be at least 2 characters long" : null,
      lastName: (val) =>
        val.length < 2 ? "Last name must be at least 2 characters long" : null,
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      username: (val) =>
        val.length < 6 ? "Username must be at least 6 characters long" : null,
      password: (val) =>
        val.length < 6 ? "Password must be at least 6 characters long" : null,
      organisationName: (val) =>
        val.length < 3
          ? "Organisation name must be at least 3 characters long"
          : null,
    },
  });

  const handleSubmit = async (values: any) => {
    setShowAlert(false);
    if (values.password !== values.confirmPassword) {
      form.setErrors({
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "Passwords don't match",
        organisationName: "",
        mailingListOptIn: "",
      });
      setShowAlert(true);
      return;
    }

    let formdata = new FormData();
    try {
      formdata.append("username", values.username);
      formdata.append("password1", values.password);
      formdata.append("password2", values.confirmPassword);
      formdata.append("email", values.email);
      formdata.append("organisation_creator_first_name", values.firstName);
      formdata.append("organisation_creator_last_name", values.lastName);
      formdata.append("organisation_name", values.organisationName);
      formdata.append("mailing_list_opt_in", values.mailingListOptIn);

      let registerFormData = {
        username: values.username,
        password1: values.password,
        password2: values.confirmPassword,
        email: values.email,
      };
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}dj-rest-auth/registration/`,
          registerFormData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then(async (response) => {
          const user_data = await axios({
            method: "get",
            url: `${process.env.REACT_APP_BASE_URL}dj-rest-auth/user/`,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Token " + response.data.key,
            },
          });

          await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}subscriptions/create_organisation/`,
            data: formdata,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Token " + response.data.key,
            },
          });

          login({
            key: response.data.key,
            username: user_data.data.username,
            user_id: user_data.data.pk,
            redirect: "/",
          });

          //LinkedInTag.track('13094732');

        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data);
          form.setErrors({
            firstName: "",
            lastName: "",
            email: error.response.data.email
              ? error.response.data.email[0]
              : "",
            username: error.response.data.username
              ? error.response.data.username[0]
              : "",
            password: error.response.data.password1
              ? error.response.data.password1[0]
              : "",
            confirmPassword: error.response.data.password2
              ? error.response.data.password2[0]
              : "",
            organisationName: "",
            mailingListOptIn: "",
          });
          setShowAlert(true);
        });
    } catch (error) {
      // console.log("error start");
      // console.log("name: " + (error as Error).name);
      // console.log("message: " + (error as Error).message);
      // console.log("cause: " + (error as Error).cause);
      // let a = error as Error;
      // let b = AxiosError;
      // console.log(error);
      // console.log("error finish");
      setShowAlert(true);
    }
  };

  return (
    // <section className="signup-wrap">
    //   <Container my="md" mt={0} mb={0} className="main-container">
    <Grid p={0} m={0}>
      <Grid.Col xs={12} sm={6} lg={7} p={0} className="left-container">
        <div className="log-left">
          <div className="main-box">
            <Text weight={500} className="title-text">
              Join Scopomap
            </Text>
            <GLogin title="Sign Up" />
            <Divider
              label="Or"
              labelPosition="center"
              styles={{
                label: { fontSize: 16, color: "black !important" },
              }}
              className="hr-divider"
            />
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Group grow my={20}>
                <TextInput
                  required
                  placeholder="First Name"
                  value={form.values.firstName}
                  onChange={(event) =>
                    form.setFieldValue("firstName", event.currentTarget.value)
                  }
                  error={form.errors.firstName}
                />
                <TextInput
                  required
                  placeholder="Last Name"
                  value={form.values.lastName}
                  onChange={(event) =>
                    form.setFieldValue("lastName", event.currentTarget.value)
                  }
                  error={form.errors.lastName}
                />
              </Group>
              <Stack>
                <TextInput
                  required
                  placeholder="Email"
                  value={form.values.email}
                  onChange={(event) =>
                    form.setFieldValue("email", event.currentTarget.value)
                  }
                  error={form.errors.email}
                />

                <TextInput
                  required
                  placeholder="Username"
                  value={form.values.username}
                  onChange={(event) =>
                    form.setFieldValue("username", event.currentTarget.value)
                  }
                  error={form.errors.username}
                />

                <TextInput
                  type="password"
                  required
                  placeholder="Password"
                  value={form.values.password}
                  onChange={(event) =>
                    form.setFieldValue("password", event.currentTarget.value)
                  }
                  error={form.errors.password}
                />
                <TextInput
                  type="password"
                  required
                  placeholder="Confirm Password"
                  value={form.values.confirmPassword}
                  onChange={(event) =>
                    form.setFieldValue(
                      "confirmPassword",
                      event.currentTarget.value
                    )
                  }
                  error={form.errors.confirmPassword}
                />
                <TextInput
                  required
                  placeholder="Organisation Name"
                  value={form.values.organisationName}
                  onChange={(event) =>
                    form.setFieldValue(
                      "organisationName",
                      event.currentTarget.value
                    )
                  }
                  error={form.errors.organisationName}
                />
                <Checkbox
                  checked={form.values.mailingListOptIn}
                  label="Sign up to our mailing list"
                  onChange={(event) =>
                    form.setFieldValue(
                      "mailingListOptIn",
                      event.currentTarget.checked
                    )
                  }
                  size="sm"
                />
                <Button type="submit" color={"orange"}>
                  Register
                </Button>
              </Stack>

              <Group position="apart" mt="xl">
                <Anchor
                  component={Link}
                  to="/login"
                  type="button"
                  size="xs"
                  className="anchor-text"
                  underline={true}
                >
                  Already have an account?
                </Anchor>
              </Group>
              <Divider
                labelPosition="center"
                styles={{
                  label: { fontSize: 16, color: "black !important" },
                }}
                className="hr-divider"
              />
              <Text size="md" weight={300} className="welcome-text">
              By signing up you agree to the{" "}
              <Anchor underline={true} color="orange">
                terms of service
              </Anchor>{" "}
              and{" "}
              <Anchor underline={true} color="orange">
                privacy policy
              </Anchor>
            </Text>
            </form>
          </div>
        </div>
      </Grid.Col>
      <BrandSection />
    </Grid>
    //   </Container>
    // </section>
  );
};

export default Signup;
