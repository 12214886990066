import React, { FC } from "react";
import { TextInput, Group, Button, CloseButton, Tooltip } from "@mantine/core";



type VerifiedAddress = {
    address: string;
    index: number;
    remove: (index:number) => void;
    
};

const VerifiedAddress: FC<VerifiedAddress> = ({
    address,
    index, 
    remove,

}) => {
    return (
    <Group position="left">
        <Tooltip label = {address}>
          <div style={{
            position:"relative",
            width: 200,
            marginLeft:4,
            marginRight: 0,
            fontSize: 14,
            fontWeight: 'bold',
            fontStyle: 'italic',
            borderRadius: '15px',
            border: '1px solid gray',
            paddingLeft: '4px', // Adjust the padding as needed
            paddingRight: '20px',
            textAlign: 'left', // Align the text to the left
          }}
          >
            {address.slice(0, 23) }
          </div>
        </Tooltip>
        <CloseButton size = 'xs'
        onClick={()=>{
          remove(index)
        }}
        style={{
        position: "absolute",
        right: 204,
        }}aria-label="Close modal" />
    </Group>




    )
    };
export default VerifiedAddress;