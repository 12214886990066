/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useContext, useEffect } from "react";
import {
  createStyles,
  getStylesRef,
  Navbar as MUINavbar,
  Group,
  Image,
  Title,
  Switch,
  Menu,
  Modal,
  Button,
  Collapse,
  Grid,
  Radio,
  useMantineTheme,
  SimpleGrid,
  Anchor,
  Text,
  Flex,
  Box,
} from "@mantine/core";
import {
  Settings,
  HeartHandshake,
  Map,
  Archive,
  ArrowForwardUp,
  CloudUpload,
  FileDownload,
  BuildingStore,
  MessageDots,
  User,
  Logout,
  AddressBook,
  Users,
  Copy,
  Polygon,
  Lifebuoy,
  ArrowsUpDown,
  Report,
  ReportMoney,
} from "tabler-icons-react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import "../css/navbar.css";
import UserButton from "./UserButton";
import { useAuth } from "../hooks/useAuth";
import storeContext from "../store/store";
import { downloadKML } from "../map-utils/downloadKML";
import DropZoneModal from "../components/DropZoneModal";
import AddressesModal from "./AddressesModal";
import ShareMapModal from "./ShareMapModal";
import axios from "axios";

const useStyles = createStyles((theme, _params) => {
  const icon: any = getStylesRef("icon");
  return {
    header: {
      paddingBottom: theme.spacing.md, // Ensure these values are in rem in your theme configuration
      marginBottom: theme.spacing.md,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
    },
    switchWrapper: {
      padding: "0.625rem 0.75rem", // Converted 10px 12px to rem
      width: "100%",
    },
    switchWrapperInner: {
      borderBottom: "1px solid #68656B",
      borderTop: "1px solid #68656B",
      padding: "1.5rem 0 0.4375rem 0", // 24px 0 7px to rem
      width: "100%",
    },

    switchRoot: {
      lineHeight: "1.55rem",
      flexDirection: "row-reverse",
      alignItems: "top",
      alignContent: "top",
      width: "80%",
      justifyContent: "space-between",
    },

    switchTrack: {
      backgroundColor: "#2B2B2B",
      border: "none",
      "input:checked ~ &": {
        backgroundColor: "#2B2B2B",
      },
    },

    sliderLabel: {
      color: "white",
    },

    switchThumb: {
      backgroundColor: "#A9AAAD",
      border: "none",
      color: "white",

      "input:checked + * > &": {
        backgroundColor: "#fd7e14",
      },
    },

    switchWrapperOuter: {
      padding: "1rem",
      width: "100%",
    },

    switchLabel: {
      color: "white",
      paddingRight: "1rem",
      fontSize: "0.875rem", // 14px to rem
    },
    radioLabel: {
      color: "white",
      fontSize: "0.875rem",
    },

    radioButton: {
      border: "none",
      height: "1rem",
      width: "1rem",
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm, // Ensure this is defined in rem in your theme
      padding: `${theme.spacing.xs} ${theme.spacing.sm}`, // Ensure spacing is in rem
      borderRadius: theme.radius.sm, // Ensure radius is defined in rem
      fontWeight: 500,
      color: "#ffffff",

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.allColors[7]
            : theme.colors.allColors[9],
        color: "white",
        cursor: "pointer",

        [`& .${icon}`]: {
          color: theme.colors.allColors[7],
        },
      },
    },

    linkIcon: {
      ref: icon,
      marginRight: theme.spacing.sm, // Ensure spacing is in rem
      width: "0.875rem", // 14px to rem
      height: "1.05625rem", // 16.9px to rem
      color: "#ffffff",
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.allColors[7]
            : theme.colors.allColors[9],
        color: "white",
        [`& .${icon}`]: {
          color: theme.colors.allColors[7],
        },
        transitionProperty: "background-color, color",
        transitionDuration: "100ms",
      },
    },
    switchIcon: {
      ref: icon,
      marginRight: theme.spacing.sm, // Ensure spacing is in rem
      width: "0.875rem", // 14px to rem
      height: "1.05625rem", // 16.9px to rem
      color: "#ffffff",
      ":hover": {
        color: theme.colors.allColors[7],
        cursor: "pointer",
      },
    },
    addressesModalheader: {
      backgroundColor: "#fd7e14",
      minHeight: "4.5rem",
      padding: "0.625rem 0.625rem !important", // 10 px to rem, importance preserved
    },
    title: {
      color: "white !important",
      letterSpacing: "0.1rem",
    },
    close: {
      color: "white",
      "&:hover": {
        backgroundColor: "white",
        color: theme.colors.allColors[7],
      },
    },
  };
});

const data = [
  // { link: "", label: "What's New", icon: BellRinging },
  { link: "/team", label: "Team", icon: Users },
  { link: "/settings", label: "Settings", icon: Settings },
  { link: "/contact-us", label: "Contact Us", icon: HeartHandshake },
];

const mapsLinksData = [
  { link: "/maplist", label: "Map Library", icon: Map },
  { link: "/sharable-maps", label: "Shared Maps", icon: ArrowForwardUp },
  { link: "/archived-maps", label: "Archived Maps", icon: Archive },
];

const adminLinksData = [
  { link: "/subscriptions-report", label: "Subscriptions Report", icon: ReportMoney },
  { link: "/adderss-downloads-report", label: "Address Downloads", icon: Report },
];
interface NavbarProps {
  opened: boolean;
  setOpenedNavbar?: any;
  extraClassName?: string;
}

const Navbar: FC<NavbarProps> = ({
  opened,
  setOpenedNavbar,
  extraClassName,
}) => {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Billing");
  const { state, dispatch } = useContext<any>(storeContext);
  const [dropzoneOpened, setDropzoneOpened] = useState(false);
  const [addressesModalOpened, setAddressesModalOpened] = useState(false);
  const [shareMapOpened, setShareMapOpened] = useState<boolean>(false);
  const [isJobSaved, setIsJobSaved] = useState<boolean>(true);
  const [isMapSetsOpened, setMapSetsOpen] = useState(false);
  const navigate = useNavigate();

  const { user, logout } = useAuth();
  const { id } = useParams();
  let { pathname } = useLocation();

  const theme = useMantineTheme();

  useEffect(() => {
    if (state.showMarkers) {
      state.markers.forEach((comment: any) => {
        comment.marker.setMap(state.map);
      });
    } else {
      state.markers.forEach((comment: any) => {
        comment.marker.setMap(null);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showMarkers]);

  useEffect(() => {
    if (state.showBusinessLocation) {
      state.businessLocations.forEach((businessLocation: any) => {
        businessLocation.setMap(state.map);
      });
    } else {
      state.businessLocations.forEach((businessLocation: any) => {
        businessLocation.setMap(null);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showBusinessLocation]);

  useEffect(() => {
    if (state.jobId && (state.jobId > 0 || state.jobId.length > 0)) {
      setIsJobSaved(true);
    } else {
      setIsJobSaved(false);
    }
  }, [isJobSaved, state.jobId]);

  const handleKMLFileDownload = () => {
    if (state.polygons) {
      downloadKML(state.polygons);
    }
  };

  function archiveMap(mapId: string) {
    let formData = new FormData();
    formData.append("map_id", mapId.toString());
    formData.append("archived_flag ", "True");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}set_map_archive_flag/`,
        formData,
        {
          headers: {
            Authorization: `token ${user.key}`,
          },
        }
      )
      .then(() => {
        let unarchivedUserMaps: any[] = [];
        state.userMaps.forEach(function (userMap: any) {
          if (userMap.id !== parseInt(mapId)) unarchivedUserMaps.push(userMap);
        });
        dispatch({ type: "setUserMaps", payload: unarchivedUserMaps });
      });
  }

  const toggleMarkers = () => {
    dispatch({ type: "toggleMarkers", payload: null });
  };

  const toggleBusinessLocation = () => {
    const stylesVisiblePOI = [
      {
        featureType: "poi",
        stylers: [{ visibility: "on" }],
      },
    ]
    const stylesInvisiblePOI = [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
    ]
    let newShowBusinessLocation = !state.showBusinessLocation;
    if(newShowBusinessLocation)
      state.map.setOptions({ styles: stylesVisiblePOI });
    else
      state.map.setOptions({ styles: stylesInvisiblePOI });
      dispatch({ type: "toggleBusinessLocation", payload: newShowBusinessLocation });
    };

  const setBenchmark = (newBenchmark: string) => {
    dispatch({ type: "setBenchmark", payload: newBenchmark });
  };

  const checkIfMapIsSaved = () => {
    if (state.jobId && (state.jobId > 0 || state.jobId.length > 0)) {
      return true;
    } else {
      return false;
    }
  };

  const currentRoute = useLocation().pathname;

  const links = data.map((item) => (
    <a
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      // href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        if (currentRoute === "/") {
          if (checkIfMapIsSaved()) {
            setActive(item.label);
            navigate(item.link);
          } else {
            const confirmResult = window.confirm(
              "Your map is unsaved. Are you sure you want to leave?"
            );
            if (confirmResult === true) {
              setActive(item.label);
              navigate(item.link);
            }
          }
        } else {
          setActive(item.label);
          navigate(item.link);
        }
      }}
    >
      <item.icon className={classes.linkIcon} />
      <span>{item.label}</span>
    </a>
  ));

  const mainLinks1 = (
    <div>
      <a
        className={classes.link}
        key="Load KML"
        onClick={(event) => {
          event.preventDefault();
          setDropzoneOpened(true);
        }}
      >
        <CloudUpload className={classes.linkIcon} />
        <span>Load KML</span>
      </a>
      <Modal
        centered
        opened={dropzoneOpened}
        onClose={() => setDropzoneOpened(false)}
        padding={0}
        withCloseButton={true}
        title="Upload KML"
        size="lg"
      >
        <DropZoneModal
          setOpenedNavbar={setOpenedNavbar}
          setDropzoneOpened={() => setDropzoneOpened(false)}
        />
      </Modal>

      <a
        className={classes.link}
        key="Export KML"
        onClick={(event) => {
          event.preventDefault();
          handleKMLFileDownload();
        }}
      >
        <FileDownload className={classes.linkIcon} />
        <span>Export KML</span>
      </a>

      <a
        className={classes.link}
        key="Generate Address List"
        onClick={(event) => {
          event.preventDefault();
          setAddressesModalOpened(true);
        }}
      >
        <AddressBook className={classes.linkIcon} />
        <span>Generate Address List</span>
      </a>
      <Modal
        opened={addressesModalOpened}
        onClose={() => setAddressesModalOpened(false)}
        title="Adresses List"
        size="auto"
        classNames={{
        }}
      >
        <AddressesModal
          opened={addressesModalOpened}
          setOpenedNavbar={setOpenedNavbar}
          setAddressesModalOpened={() => setAddressesModalOpened(false)}
        />
      </Modal>

      {isJobSaved && (
        <a
          className={classes.link}
          key="Duplicate"
          onClick={(event) => {
            navigate(`/${state.jobId}/copy`);
          }}
        >
          <Copy className={classes.linkIcon} />
          <span>Duplicate</span>
        </a>
      )}

      {isJobSaved && (
        <a
          className={classes.link}
          key="Share"
          onClick={(event) => {
            event.preventDefault();
            setShareMapOpened(true);
          }}
        >
          <ArrowForwardUp className={classes.linkIcon} />
          <span>Share</span>
        </a>
      )}
      {/* Share Map */}
      <Modal
        centered
        padding={0}
        opened={shareMapOpened}
        onClose={() => setShareMapOpened(false)}
        title="Share this Map"
      >
        <ShareMapModal close={setShareMapOpened} sharedMapId={state.jobId} />
      </Modal>

      {isJobSaved && (
        <a
          className={classes.link}
          key="Archive"
          onClick={(event) => {
            event.preventDefault();
            if (id) {
              archiveMap(id);
            }
          }}
        >
          <Archive className={classes.linkIcon} />
          <span>Archive</span>
        </a>
      )}
    </div>
  );

  <></>;

  const mapsLinks = mapsLinksData.map((item) => (
    <Link
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      to={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        if (currentRoute === "/") {
          if (checkIfMapIsSaved()) {
            // Map is saved, navigate to the link
            setActive(item.label);
            navigate(item.link);
          } else {
            // Map is not saved, prompt user to save or discard
            const confirmResult = window.confirm(
              "Your map is unsaved. Are you sure you want to leave?"
            );
            if (confirmResult === true) {
              setActive(item.label);
              navigate(item.link);
            }
          }
        } else {
          setActive(item.label);
          navigate(item.link);
        }
      }}
    >
      <item.icon className={classes.linkIcon} />
      <span>{item.label}</span>
    </Link>
  ));

  const adminLinks = adminLinksData.map((item) => (
    <Link
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      to={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        if (currentRoute === "/") {
          if (checkIfMapIsSaved()) {
            // Map is saved, navigate to the link
            setActive(item.label);
            navigate(item.link);
          } else {
            // Map is not saved, prompt user to save or discard
            const confirmResult = window.confirm(
              "Your map is unsaved. Are you sure you want to leave?"
            );
            if (confirmResult === true) {
              setActive(item.label);
              navigate(item.link);
            }
          }
        } else {
          setActive(item.label);
          navigate(item.link);
        }
      }}
    >
      <item.icon className={classes.linkIcon} />
      <span>{item.label}</span>
    </Link>
  ));

  return (
    <MUINavbar
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 200, lg: 300 }}
      height={"100vh"}
      p="md"
      style={{
        backgroundColor: "#414042",
        position: "fixed",
        left: 0,
        zIndex: 9,
        top: 0,
      }}
      classNames={{
        hidden: extraClassName ? extraClassName : "nav-main",
      }}
    >
      <MUINavbar.Section grow>
        <Group className={classes.header} position="apart">
          <div className="logo-wrapper">
            <Link
              to="/"
              onClick={() => {
                setOpenedNavbar(false);
              }}
            >
              <Image
                src={
                  process.env.REACT_APP_PUBLIC_FOLDER + "Scopomap_Wide_Dark.svg"
                }
                // src="images/Scopomap_Wide_Dark.svg"
                width={160}
                height={34}
                alt="scopomap-logo"
              />
            </Link>
          </div>
        </Group>
        {/* {pathname === `/` && (
        )} */}
        {(pathname === `/` ||
          pathname === `/${id}` ||
          /^(\/LGA\/[0-9])/.test(pathname) ||
          /^(\/SAL\/[0-9])/.test(pathname)) && (
          <>
            <Group>
              <div className="links-wrapper">{mainLinks1}</div>
            </Group>{" "}
            <Group>
              <div className={classes.switchWrapper}>
                <div className={classes.switchWrapperInner}>
                  <Flex
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="nowrap"
                    sx={{
                      paddingBottom: "1rem",
                      width: "100%",
                    }}
                  >
                    <BuildingStore className={classes.switchIcon} />
                    <Flex
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "80%",
                      }}
                    >
                      <Text size="sm" className={classes.switchLabel}>
                        Display Businesses
                      </Text>
                      <Box>
                        <Switch
                          size="sm"
                          classNames={{
                            track: `${classes.switchTrack}`,
                            thumb: `${classes.switchThumb}`,
                          }}
                          checked={state.showBusinessLocation}
                          onChange={toggleBusinessLocation}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="nowrap"
                    sx={{
                      paddingBottom: "1rem",
                      width: "100%",
                    }}
                  >
                    <MessageDots className={classes.switchIcon}></MessageDots>
                    <Flex
                      direction={"row"}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "80%",
                      }}
                    >
                      <Text size="sm" className={classes.switchLabel}>
                        Display Comments
                      </Text>
                      <Box>
                        <Switch
                          size="sm"
                          classNames={{
                            track: `${classes.switchTrack}`,
                            thumb: `${classes.switchThumb}`,
                          }}
                          checked={state.showMarkers}
                          onChange={toggleMarkers}
                        />
                      </Box>
                    </Flex>
                  </Flex>

                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <ArrowsUpDown className={classes.switchIcon} />
                    <Flex
                      direction={"column"}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "left",
                        width: "80%",
                      }}
                    >
                      <Text size="sm" className={classes.switchLabel}>
                        Benchmarking
                      </Text>

                      <Radio.Group
                        value={state.benchmark}
                        onChange={setBenchmark}
                        size={"xs"}
                      >
                        <Flex
                          style={{
                            boxSizing: "border-box",
                            display: "flex",
                            flexFlow: "wrap",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "12px",
                            paddingTop: "0.3rem",
                          }}
                        >
                          <Radio
                            value="state"
                            label="State"
                            classNames={{ label: classes.radioLabel }}
                          />
                          <Radio
                            value="national"
                            label="National"
                            classNames={{ label: classes.radioLabel }}
                          />
                        </Flex>
                      </Radio.Group>
                    </Flex>
                  </div>
                </div>
              </div>
            </Group>

          </>
        )}

        {/* <Title
          className="maps-title"
          pt="xl"
          style={{ color: "#ffffff", fontSize: "20px" }}
        >
          Maps
        </Title> */}
        <div className="links-wrapper">
          {mapsLinks}
          <div
            className={cx(classes.link, {
              [classes.linkActive]: false,
            })}
            key={"Map Sets"}
            onClick={() => setMapSetsOpen(!isMapSetsOpened)}
          >
            <Polygon className={classes.linkIcon} />
            <span>Map Sets</span>
          </div>
          <Grid>
            <Grid.Col lg={2} sm={2} />
            <Grid.Col lg={10} sm={10}>
              <Collapse in={isMapSetsOpened}>
                <Link
                  className={cx(classes.link, {
                    [classes.linkActive]: false,
                  })}
                  to={"/LGAs"}
                  key={"LGAs"}
                >
                  <AddressBook className={classes.linkIcon} />
                  <span>LGAs</span>
                </Link>
                <Link
                  className={cx(classes.link, {
                    [classes.linkActive]: false,
                  })}
                  to={"/SALs"}
                  key={"SALs"}
                >
                  <AddressBook className={classes.linkIcon} />
                  <span>Suburbs</span>
                </Link>
              </Collapse>
            </Grid.Col>
          </Grid>
        </div>

        <div className={classes.switchWrapperInner}>
          <div className="links-wrapper">
            {links}

            {(pathname === `/` ||
              pathname === `/${id}` ||
              /^(\/LGA\/[0-9])/.test(pathname) ||
              /^(\/SAL\/[0-9])/.test(pathname)) && (
              <a
                className={classes.link}
                key={"help"}
                onClick={(event) => {
                  localStorage.removeItem("tour");
                  dispatch({ type: "START" });
                  setOpenedNavbar(false);
                }}
              >
                <Lifebuoy className={classes.linkIcon} />
                <span>Help</span>
              </a>
            )}
          </div>
        </div>

        <div className={classes.switchWrapperInner}>
          <div className="links-wrapper">
            {adminLinks}
          </div>
        </div>
      </MUINavbar.Section>

      <MUINavbar.Section className={classes.footer}>
        <Group position="left">
          <Menu
            position="top-end"
            arrowPosition="side"
            trigger="hover"
            openDelay={100}
            closeDelay={400}
            zIndex={1001}
          >
            <Menu.Target>
              <UserButton
                name={user?.username}
                image={""}
                email="hspoonlicker@outlook.com"
              />
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item icon={<Users size={14} />}>
                <Anchor href="/team" color="black">
                  Team
                </Anchor>
              </Menu.Item>

              <Menu.Item
                icon={<Logout size={14} />}
                onClick={() => {
                  dispatch({ type: "setCheck", payload: false });
                  logout();
                }}
              >
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </MUINavbar.Section>
    </MUINavbar>
  );
};
export default Navbar;
