import { FC } from "react";

import PricingTable from "../components/PricingTable";
import AppHeader from "../components/AppHeader";

const Pricing: FC = () => {
  return (
    <AppHeader>
      <PricingTable />
    </AppHeader>
  );
};

export default Pricing;
