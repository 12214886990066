/* FilterBuilderModal.tsx */

import {
  Modal,
  Paper,
  Flex,
  Stack,
  Text,
  Button,
  ScrollArea,
  Tabs,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import storeContext from "../../../store/store";
import {
  getAvaliableCatalogue,
  UpdateSettingsData,
} from "../../../map-utils/filterbarAPI";
import { useAuth } from "../../../hooks/useAuth";
import OptionsList from "./OptionsList";
import FiltersPanel from "./FiltersPanel";
import Sidebar from "./Sidebar";
import { showNotification } from "@mantine/notifications";
import { updateDemographicsSettings } from "../../../map-utils/filterbarAPI";
import { FilterTable, SavedFilter } from "../../../types";
import FilterSaveModal from "../FilterSaveModal";

interface FilterBuilderModalProps {
  setFilterList: (filters: any) => void;
  filterList: FilterTable[];
  listOfUserSavedFilters: SavedFilter[];
  selectedFilterName: string | null;
  setSelectedFilterName: (name: string | null) => void;
}

const FilterBuilderModal: React.FC<FilterBuilderModalProps> = ({
  setFilterList,
  filterList,
  listOfUserSavedFilters,
  selectedFilterName,
  setSelectedFilterName,
}) => {
  const { state, dispatch } = useContext<any>(storeContext);
  const [filters, setFilters] = useState<FilterTable[]>([]);
  const [isEdited, setIsEdited] = useState(false);
  const [demographicsSettingsId, setDemographicsSettingsId] = useState<
    string | null
  >(null);
  const { user } = useAuth();

  useEffect(() => {
    getAvaliableCatalogue().then((response) => {
      setFilters(response);
    });
  }, []);

  const getCheckedFilters = () => {
    const checkedFilters: FilterTable[] = [];

    filters.forEach((table) => {
      table.sub_categories.forEach((subCategory) => {
        subCategory.filters.forEach((filter) => {
          if (filter.checked) {
            const foundTable = checkedFilters.find(
              (t) => t.table_name === table.table_name
            );

            if (foundTable) {
              const foundSubCategory = foundTable.sub_categories.find(
                (s) => s.sub_category_title === subCategory.sub_category_title
              );

              if (foundSubCategory) {
                foundSubCategory.filters.push(filter);
              } else {
                foundTable.sub_categories.push({
                  sub_category_title: subCategory.sub_category_title,
                  filters: [filter],
                });
              }
            } else {
              checkedFilters.push({
                table_name: table.table_name,
                category_title: table.category_title,
                sub_categories: [
                  {
                    sub_category_title: subCategory.sub_category_title,
                    filters: [filter],
                  },
                ],
              });
            }
          }
        });
      });
    });
    return checkedFilters;
  };

  const areFiltersDifferent = (
    currentFilters: FilterTable[],
    settingFilters: FilterTable[]
  ) => {
    const serializeFilters = (filters: any[]) =>
      JSON.stringify(
        filters.sort((a: { table_name: number }, b: { table_name: number }) =>
          a.table_name > b.table_name ? 1 : -1
        )
      );

    return (
      serializeFilters(currentFilters) !== serializeFilters(settingFilters)
    );
  };

  // function to get a list of user saved filters by id
  const getFilterPresetByID = (id: string) => {
    return listOfUserSavedFilters.find(
      (filter) => filter.demographics_settings_id === parseInt(id)
    );
  };

  // get  demographics settings from the list of user saved filters by id
  useEffect(() => {
    if (demographicsSettingsId) {
      const matchedFilter = listOfUserSavedFilters.find(
        (filter) =>
          filter.demographics_settings_id === parseInt(demographicsSettingsId)
      );
      setSelectedFilterName(matchedFilter?.settings_name ?? "custom");
    }
  }, [demographicsSettingsId]);

  // check if checked filters are different from the ones secleted by the demographic preset id
  useEffect(() => {
    if (demographicsSettingsId) {
      const matchedFilter = getFilterPresetByID(demographicsSettingsId);
      if (matchedFilter) {
        const matchedFilters = JSON.parse(
          matchedFilter.demographics_settings_json
        );
        setIsEdited(areFiltersDifferent(getCheckedFilters(), matchedFilters));
        // log the matched filter
        //console.log("Matched filter: ", matchedFilters);
        //console.log("Filters: ", filters);
      }
    }
  }, [filters, demographicsSettingsId]);

  const applyFiltersAndClose = () => {
    const checkedFilters = getCheckedFilters();

    setFilterList(checkedFilters);

    if (selectedFilterName === "custom") {
      setSelectedFilterName("custom");
    }

    dispatch({ type: "setDemographicBuilderOpen", payload: false });
    setIsEdited(areFiltersDifferent(filters, checkedFilters));
  };

  const handleApply = () => {
    applyFiltersAndClose();
  };

  const handleSaveAndApply = async () => {
    const checkedFilters = getCheckedFilters();

    if (!selectedFilterName || selectedFilterName === "custom") {
      showNotification({
        title: "Filter Selection Required",
        message:
          "Please choose an existing filter or create a new one to continue.",
        color: "red",
      });
      return;
    }

    if (!demographicsSettingsId) {
      showNotification({
        title: "Missing demographicsSettingsId",
        message: "Demographics settings ID is missing",
        color: "red",
      });
      return;
    }

    let matchedFilter = listOfUserSavedFilters.find(
      (filter) =>
        filter.demographics_settings_id === parseInt(demographicsSettingsId)
    );

    if (!matchedFilter) {
      showNotification({
        title: "Error",
        message: "Selected filter not found",
        color: "red",
      });
      return;
    }

    if (matchedFilter.organisation_default) {
      showNotification({
        title: "Cannot Update Default Filter",
        message:
          "To update the default filter, please create a new filter and set it as default.",
        color: "yellow",
      });
      return;
    }

    const userFilter: UpdateSettingsData = {
      demographicsSettingsId,
      demographicsSettingsJson: JSON.stringify(checkedFilters),
      settingsName: selectedFilterName,
      organisationDefault: matchedFilter.organisation_default
        ? "True"
        : "False",
    };

    try {
      await updateDemographicsSettings(userFilter, user.key);

      const updatedList = listOfUserSavedFilters.map((filter) => {
        if (
          filter.demographics_settings_id === parseInt(demographicsSettingsId)
        ) {
          return {
            ...filter,
            settings_name: selectedFilterName,
            demographics_settings_json: JSON.stringify(checkedFilters),
          };
        }
        return filter;
      });

      setSelectedFilterName(selectedFilterName);

      dispatch({
        type: "setUserDemographicSettingsList",
        payload: updatedList,
      });

      showNotification({
        title: "Filter saved",
        message: "Your filter has been saved",
        color: "green",
      });
      applyFiltersAndClose();
    } catch (error) {
      showNotification({
        title: "Error saving filter",
        message: "There was an error saving the filter",
        color: "red",
      });
    }
  };

  const handleModalClose = () => {
    dispatch({ type: "setDemographicBuilderOpen", payload: false });
    setIsEdited(false);
  };

  const handleSaveClick = () => {
    dispatch({ type: "setDemographicSaveOpen", payload: true });

    const checkedFilters = getCheckedFilters();
    dispatch({ type: "updateCheckedFilters", payload: checkedFilters });
  };

  if (!state.demographicBuilderVisible) return null;

  return (
    <>
      <Modal.Root
        opened={state.demographicBuilderVisible}
        onClose={handleModalClose}
        size="70%"
      >
        <Modal.Overlay blur={1} />
        <Modal.Content style={{ padding: "0px", gap: "0px" }}>
          <Modal.Header style={{ margin: "0px" }}>
            <FiltersPanel
              filters={filters}
              isEdited={isEdited}
              handleSaveClick={handleSaveClick}
            />
          </Modal.Header>

          <Modal.Body style={{ padding: "0px", gap: "0px" }}>
            <Flex
              dir="row"
              gap="md"
              style={{
                alignItems: "flex-start",
                justifyContent: "space-between",
                maxHeight: "460px",
              }}
            >
              <Paper
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flex: "1 0 0",
                  alignSelf: "stretch",
                }}
              >
                <ScrollArea style={{ flexGrow: 1, height: "100%" }}>
                  <Tabs
                    defaultValue={filters[0]?.category_title || ""}
                    orientation="vertical"
                    unstyled
                    styles={{
                      root: {
                        display: "flex",
                        flexDirection: "row",
                      },
                      tabsList: {
                        alignSelf: "stretch",
                        minWidth: "175px",
                        maxWidth: "250px",
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        height: "460px",
                        gap: "5px",
                        borderRight: "1px solid #D9D9D9",
                      },
                      tab: {
                        overflow: "hidden",
                        alignSelf: "stretch",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "10px 20px",
                        border: "none",
                        backgroundColor: "transparent",
                        color: "#000",
                        fontSize: "14px",
                        lineHeight: "155%",
                        fontFamily: "'DIN 2014'",

                        "&:hover": {
                          backgroundColor: "#f0f0f0",
                        },

                        "&[data-active]": {
                          fontWeight: "bold",
                          borderLeft: "2px solid #fd7e14",
                          backgroundColor: "#f3f3f3",
                        },
                      },
                      panel: {
                        flex: "1 0 0",
                        display: "flex",
                        padding: "0px 25px 0px 40px",
                        flexDirection: "column",
                        gap: "24px",
                        alignSelf: "stretch",
                      },
                    }}
                  >
                    <Tabs.List>
                      {filters.map((table, idx) => (
                        <Tabs.Tab key={idx} value={table.category_title}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                textOverflow: "ellipsis",
                                textAlign: "left",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {table.category_title}
                            </div>
                            {table.sub_categories.some((subCategory) =>
                              subCategory.filters.some(
                                (filter) => filter.checked
                              )
                            ) && (
                              <div
                                style={{
                                  width: "4px",
                                  height: "4px",
                                  backgroundColor: "#fd7e14",
                                  borderRadius: "50%",
                                  marginLeft: "8px",
                                }}
                              />
                            )}
                          </div>
                        </Tabs.Tab>
                      ))}
                    </Tabs.List>

                    {filters.map((table, idx) => (
                      <Tabs.Panel
                        key={`${table.table_name}-${idx}`}
                        value={table.category_title}
                      >
                        <OptionsList
                          table={table}
                          filters={filters}
                          setFilters={setFilters}
                          setIsEdited={setIsEdited}
                        />
                      </Tabs.Panel>
                    ))}
                  </Tabs>
                </ScrollArea>
              </Paper>
              <ScrollArea mah={460} mx="auto">
                <Sidebar
                  listOfUserSavedFilters={listOfUserSavedFilters}
                  selectedFilterName={selectedFilterName}
                  setSelectedFilterName={setSelectedFilterName}
                  filters={filters}
                  setFilters={setFilters}
                  setIsEditing={setIsEdited}
                  setDemographicsSettingsId={setDemographicsSettingsId}
                  selectedFilterID={demographicsSettingsId}
                />
              </ScrollArea>
            </Flex>
            <Stack>
              <Flex
                justify="space-between"
                align="center"
                style={{
                  height: "70px",
                  padding: "10px 25px",
                  borderTop: "1px solid #D9D9D9",
                }}
              >
                <Flex>
                  <Text size="sm" color="gray">
                    Selected filter: {selectedFilterName ?? "None selected"}
                  </Text>
                </Flex>
                <Flex direction="row" gap="md">
                  {isEdited && (
                    <Text size="sm" color="red">
                      You have unsaved changes
                    </Text>
                  )}
                </Flex>
                <Flex direction="row" gap="md">
                  <Button
                    variant="outline"
                    onClick={() =>
                      dispatch({
                        type: "setDemographicBuilderOpen",
                        payload: false,
                      })
                    }
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleSaveAndApply} color="orange">
                    Save & Apply
                  </Button>
                </Flex>
              </Flex>
            </Stack>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <FilterSaveModal />
    </>
  );
};

export default FilterBuilderModal;
