import { FC, useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronDown, Forms } from "tabler-icons-react";
import {
  Grid,
  TextInput,
  Text,
  Group,
  Button,
  Divider,
  Anchor,
  Checkbox,
  Select,
  MediaQuery,
  Stack,
  Flex,
} from "@mantine/core";
import "../css/signup.css";
import { GLogin } from "../components/GoogleAuth";

import axios from "axios";
import AxiosError from "axios";
import { useAuth } from "../hooks/useAuth";
import { margin } from "@mui/system";

const AcceptTeamInvitation: FC = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [inviteCanBeAccepted, setInviteCanBeAccepted] = useState(true);
  const { login } = useAuth();
  const params: any = useParams();
  const navigate = useNavigate();

  axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}subscriptions/invite_can_be_accepted/${params.guid}/`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((inviteCanBeAcceptedResponse) => {
    setInviteCanBeAccepted(JSON.parse(inviteCanBeAcceptedResponse.data));
    if (JSON.parse(inviteCanBeAcceptedResponse.data))
      console.log(inviteCanBeAcceptedResponse.data);
    else console.log("Not allowed");
  });

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      organisationName: "",
      mailingListOptIn: false,
    },

    validate: {
      firstName: (val) =>
        val.length < 2 ? "First name must be at least 2 characters long" : null,
      lastName: (val) =>
        val.length < 2 ? "Last name must be at least 2 characters long" : null,
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      username: (val) =>
        val.length < 6 ? "Username must be at least 6 characters long" : null,
      password: (val) =>
        val.length < 6 ? "Password must be at least 6 characters long" : null,
    },
  });

  const handleSubmit = async (values: any) => {
    setShowAlert(false);
    if (values.password != values.confirmPassword) {
      form.setErrors({
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "Passwords don't match",
        mailingListOptIn: "",
      });
      setShowAlert(true);
      return;
    }

    let formdata = new FormData();
    try {
      formdata.append("username", values.username);
      formdata.append("password1", values.password);
      formdata.append("password2", values.confirmPassword);
      formdata.append("email", values.email);
      formdata.append("organisation_creator_first_name", values.firstName);
      formdata.append("organisation_creator_last_name", values.lastName);
      formdata.append("mailing_list_opt_in", values.mailingListOptIn);

      let registerFormData = {
        username: values.username,
        password1: values.password,
        password2: values.confirmPassword,
        email: values.email,
      };
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}dj-rest-auth/registration/`,
          registerFormData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then(async (response) => {
          const user_data = await axios({
            method: "get",
            url: `${process.env.REACT_APP_BASE_URL}dj-rest-auth/user/`,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Token " + response.data.key,
            },
          });

          const joiTeamResponse = await axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}subscriptions/accept_invite_to_team/${params.guid}/`,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Token " + response.data.key,
            },
          });
          login({
            key: response.data.key,
            username: user_data.data.username,
            user_id: user_data.data.pk,
            redirect: "/maplist",
          });
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data);
          form.setErrors({
            firstName: "",
            lastName: "",
            email: error.response.data.email
              ? error.response.data.email[0]
              : "",
            username: error.response.data.username
              ? error.response.data.username[0]
              : "",
            password: error.response.data.password1
              ? error.response.data.password1[0]
              : "",
            confirmPassword: error.response.data.password2
              ? error.response.data.password2[0]
              : "",
            mailingListOptIn: "",
          });
          setShowAlert(true);
        });
    } catch (error) {
      console.log("error start");
      console.log("name: " + (error as Error).name);
      console.log("message: " + (error as Error).message);
      console.log("cause: " + (error as Error).cause);
      let a = error as Error;
      let b = AxiosError;
      console.log(error);
      console.log("error finish");
      setShowAlert(true);
    }
  };

  return (
    // <section className="signup-wrap">
    //   <Container my="md" mt={0} mb={0} className="main-container">
    <Grid p={0} m={0}>
      <Grid.Col xs={12} sm={6} lg={7} p={0}>
        {!inviteCanBeAccepted && (
          <div className="log-left">
            <div className="error-box-error" style={{ padding: "30px" }}>
              <Text size={"xl"} weight={500} style={{ fontSize: 30 }}>
                The team you were invited to cannot take more members.
              </Text>
              <Text size={"xl"} weight={500} style={{ fontSize: 24 }}>
                Please discuss this with the member you were invited by.
              </Text>
              <Text size={"xl"} weight={500} style={{ fontSize: 24 }}>
                A Scopomap subscription upgrade may be required.
              </Text>
            </div>
          </div>
        )}
        {inviteCanBeAccepted && (
          <div className="log-left" style={{ padding: "20px" }}>
            <div
              className="main-box"
              style={{ padding: "20px"}}
            >
              <Text
                size={"xl"}
                weight={500}
                style={{ fontSize: 40, marginBottom: "20px" }}
              >
                Join Scopomap
              </Text>

              {/* Uncomment if needed:
                <GLogin title="Sign Up" />
                <Divider
                  label="Or"
                  labelPosition="center"
                  my="lg"
                  styles={{
                    label: { fontSize: 16, color: "black !important" },
                  }}
                />
                */}

              <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Stack spacing="md">
                  <Flex
                    gap="md"
                    dir="horizontal"
                    style={{ marginBottom: "20px" }}
                  >
                    <TextInput
                      required
                      placeholder="First Name"
                      value={form.values.firstName}
                      onChange={(event) =>
                        form.setFieldValue(
                          "firstName",
                          event.currentTarget.value
                        )
                      }
                      error={form.errors.firstName}
                    />
                    <TextInput
                      required
                      placeholder="Last Name"
                      value={form.values.lastName}
                      onChange={(event) =>
                        form.setFieldValue(
                          "lastName",
                          event.currentTarget.value
                        )
                      }
                      error={form.errors.lastName}
                    />
                  </Flex>

                  <TextInput
                    required
                    placeholder="Email"
                    value={form.values.email}
                    onChange={(event) =>
                      form.setFieldValue("email", event.currentTarget.value)
                    }
                    error={form.errors.email}
                    style={{ marginBottom: "20px" }}
                  />

                  <TextInput
                    required
                    placeholder="Username"
                    value={form.values.username}
                    onChange={(event) =>
                      form.setFieldValue("username", event.currentTarget.value)
                    }
                    error={form.errors.username}
                    style={{ marginBottom: "20px" }}
                  />

                  <TextInput
                    type="password"
                    required
                    placeholder="Password"
                    value={form.values.password}
                    onChange={(event) =>
                      form.setFieldValue("password", event.currentTarget.value)
                    }
                    error={form.errors.password}
                    style={{ marginBottom: "20px" }}
                  />

                  <TextInput
                    type="password"
                    required
                    placeholder="Confirm Password"
                    value={form.values.confirmPassword}
                    onChange={(event) =>
                      form.setFieldValue(
                        "confirmPassword",
                        event.currentTarget.value
                      )
                    }
                    error={form.errors.confirmPassword}
                    style={{ marginBottom: "20px" }}
                  />

                  <Checkbox
                    checked={form.values.mailingListOptIn}
                    label="Sign up to our mailing list"
                    onChange={(event) =>
                      form.setFieldValue(
                        "mailingListOptIn",
                        event.currentTarget.checked
                      )
                    }
                    size="sm"
                    style={{ marginBottom: "20px" }}
                  />

                  <Button
                    type="submit"
                    color={"orange"}
                    style={{ marginBottom: "20px" }}
                  >
                    Register
                  </Button>
                </Stack>

                <Divider
                  labelPosition="center"
                  my="lg"
                  styles={{
                    label: { fontSize: 16, color: "black !important" },
                  }}
                />

                <Text size="md" weight={300} className="welcome-text" pb={20}>
                  By signing up you agree to the{" "}
                  <Anchor underline={true} color="orange">
                    terms of service
                  </Anchor>{" "}
                  and{" "}
                  <Anchor underline={true} color="orange">
                    privacy policy
                  </Anchor>
                </Text>
              </form>
            </div>
          </div>
        )}
      </Grid.Col>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Grid.Col xs={12} sm={6} lg={5} p={0}>
          <div className="signup-right">
            <div className="signup-img">
              <div className="loc-img loc-1">
                <img src="/images/location-icon.svg" alt="location icon" />
              </div>
              <div className="loc-img loc-2">
                <img src="/images/location-icon.svg" alt="location icon" />
              </div>
              <div className="loc-img loc-3">
                <img src="/images/location-icon.svg" alt="location icon" />
              </div>
              <div className="logo">
                <img src="/images/logo.png" alt="logo" />
              </div>
              <div className="text-box">
                <Text className="text" color={"#fff"}>
                  Powerful.
                  <br /> Insights.
                  <br /> Easy.
                  <br />
                </Text>
              </div>
              <img src="/images/login-bg.svg" alt="" />
            </div>
          </div>
        </Grid.Col>
      </MediaQuery>
    </Grid>
    //   </Container>
    // </section>
  );
};

export default AcceptTeamInvitation;
