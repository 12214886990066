import { InfoWindow } from "../types";

export const closePreviouslyOpenedInfowindow = function (infowindow: InfoWindow) {
    if (window.prevInfowindow) {
        window.prevInfowindow.close();
        google.maps.event.clearListeners(infowindow, "domready");
    }
}

export const openNewlyCreatedInfowindow = function (infowindow: InfoWindow, map: any, anchor: any) {
    window.prevInfowindow = infowindow;
    infowindow.open({
        anchor: anchor,
        map: map,
        shouldFocus: true,
    });
}

export const createAnnotationInfoWindow = function (
    commentsHtml: String,
    user: any,
) {
    let htmlMsg = `
        <div class="modal-ano">
        <div class="ma-body">
            <div class="ma-user">
            <div class="ma-user-info">
                <h6 class="username">${user?.username || "name"}</h6>
                <span id="new-annotation-time" class="annotation-time"></span>
                <p id="new-comment-text" class="comment-text"></p>
            </div>
            </div>
            <hr class="annotation-hr"/>
            <div class="comments-box" id="comments">
            ${commentsHtml || ''}
            </div>
        </div>
        <div class="ma-form">
        <input id="annotation-input" class="comment-input" type="text" onInput="onChangeHandler()" placeholder="Enter comment..."/>
            <button id="send-btn" disabled class="comment-btn">send</button>
        </div>
        </div>
        `;

    const infowindow: InfoWindow = new google.maps.InfoWindow({
        content: htmlMsg,
    });

    return {
        infowindow,
    };
}


export const attachEventCommentInfowindow = function (
    marker: any,
    infoWindow: InfoWindow,
    map: any,
    newElement: any,
    index: number,
) {
    marker.addListener("click", () => {
        infoWindow.addListener("domready", () => {
            newElement = document.getElementById(
                "annotation_input"
            ) as HTMLInputElement;
            newElement?.addEventListener("keydown", (e: any) => {
                if (e.key === "Enter") {
                    window.sendComment(index);
                }
            });
        });
        closePreviouslyOpenedInfowindow(infoWindow);
        openNewlyCreatedInfowindow(infoWindow, map, marker);
    });
}
