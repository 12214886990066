import buffer from '@turf/buffer';
import { lineString } from '@turf/helpers';

const createBufferPolygon = (linePath: google.maps.LatLng[], bufferSize: number, map: google.maps.Map) => {
  // Convert linePath to a GeoJSON LineString
  const coordinates = linePath.map((point) => [point.lng(), point.lat()]);
  const lineGeoJSON = lineString(coordinates);

  // Buffer the line using Turf.js
  const bufferedGeoJSON = buffer(lineGeoJSON, bufferSize, { units: 'meters' });

  // Extract the coordinates of the Polygon from the buffer result
  let paths;
  if (bufferedGeoJSON.geometry.type === 'Polygon') {
    paths = bufferedGeoJSON.geometry.coordinates[0].map((coord) => {
      const [lng, lat] = coord; // TypeScript should infer that coord is [number, number]
      return new google.maps.LatLng(lat, lng);
    });
  } else {
    // Handle MultiPolygon or other types if necessary
    throw new Error('Buffered geometry is not a Polygon.');
  }

  // Create the buffer polygon on the map
  const bufferPolygon: google.maps.Polygon = new google.maps.Polygon({
    paths,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    map,
  });

  return bufferPolygon;
};


// This function will be called when the user opts in for smoothing the polyline
// The function will take the polyline path and return a smooth polyline path
// calculated using bezier curve
// This function will be called when the user opts in for smoothing the polyline
const smoothPolyline = (linePath: google.maps.LatLng[], map: google.maps.Map): google.maps.Polyline => {
  // The number of iterations determines the degree of smoothing
  const numIterations = 2;

  const chaikinSmooth = (points: google.maps.LatLng[]): google.maps.LatLng[] => {
    if (points.length < 3) {
      return points; // We cannot smooth lines with fewer than 3 points
    }

    let newPoints = [];

    // Retain the first point
    newPoints.push(points[0]);

    for (let i = 0; i < points.length - 1; i++) {
      const p0 = points[i];
      const p1 = points[i + 1];

      // Calculate new points Q and R
      const Q = new google.maps.LatLng(
        0.75 * p0.lat() + 0.25 * p1.lat(),
        0.75 * p0.lng() + 0.25 * p1.lng()
      );
      const R = new google.maps.LatLng(
        0.25 * p0.lat() + 0.75 * p1.lat(),
        0.25 * p0.lng() + 0.75 * p1.lng()
      );

      newPoints.push(Q, R);
    }

    // Retain the last point
    newPoints.push(points[points.length - 1]);

    return newPoints;
  }

  let smoothedPath = linePath;

  // Apply multiple iterations of the smoothing algorithm
  for (let i = 0; i < numIterations; i++) {
    smoothedPath = chaikinSmooth(smoothedPath);
  }

  // Create the smoothed polyline
  const smoothedPolyline = new google.maps.Polyline({
    path: smoothedPath,
    strokeColor: '#ff0000',
    strokeOpacity: 1.0,
    strokeWeight: 3,
  });

  return smoothedPolyline;
};

export {  createBufferPolygon, smoothPolyline };