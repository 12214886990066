import html2canvas from "html2canvas";
import { StringLiteral } from "typescript";

const downloadAsImage = async (elementId: string, fileName: string) => {
    const element = document.getElementById(elementId);

    if (!element) {
        console.error(`Element with ID ${elementId} not found`);
        return;
    }

    await html2canvas(element, {
        useCORS: true,
        allowTaint: true,
        ignoreElements: (node) => {
            return node.nodeName === 'IFRAME';
        }
    }).then(canvas => {
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        link.href = data;
        link.download = `${fileName}.jpg`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });

}

//returns a blob

const downloadAsBlob = async (elementId: string) => {
    const element = document.getElementById(elementId);

    if (!element) {
        console.error(`Element with ID ${elementId} not found`);
        return;
    }

    await html2canvas(element, {
        useCORS: true,
        allowTaint: true,
        ignoreElements: (node) => {
            return node.nodeName === 'IFRAME';
        }
    }).then(canvas => {
        canvas.toBlob((blob) => {
            return blob;
        }
        );
    }
    );
}

const downloadAsBase64 = async (elementId: string) => {
    const element = document.getElementById(elementId);
  
    if (!element) {
      console.error(`Element with ID ${elementId} not found`);
      return null;
    }
  
    try {
      const canvas = await html2canvas(element, {
        useCORS: true,
        allowTaint: true,
        ignoreElements: (node) => {
          return node.nodeName === 'IFRAME';
        }
      });
      return canvas.toDataURL('image/jpg');
    } catch (error) {
      console.error('Error occurred while converting to base64:', error);
      return null;
    }
  };

export { downloadAsImage, downloadAsBlob, downloadAsBase64 };
