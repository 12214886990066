import axios from "axios";

export const getPolygonSA1s = async (allPolygons: any, catalog: any) => {
  let formData = new FormData();
  //formData.append("polygon_in_request", encodedPolygon);
  formData.append("all_polygons", allPolygons);

  try {
    //console.log(catalog.tables);
    let selectedFiltersToSend = { tables: catalog.tables };
    formData.append("selected_filters", JSON.stringify(selectedFiltersToSend));

    const newAPIResponse = await axios.post(
      `${process.env.REACT_APP_BASE_URL}demographics/sa1_data`,
      formData
    );

    return {
      oldAPI: {}, //response.data,
      newAPI: newAPIResponse.data,
    };
  } catch (error) {
    console.log(error);
  }
};
