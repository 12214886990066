import axios from "axios";
import {
  Aside,
  Title,
  Divider,
  Group,
  LoadingOverlay,
  Text,
  Grid,
  createStyles,
  ActionIcon,
  Menu,
  Button,
  Tooltip,
  Dialog,
  Textarea,
  Stack,
  Skeleton,
  Flex,
  Collapse,
  Progress,
} from "@mantine/core";
import React, { Fragment, useContext, useState } from "react";
import {
  Mailbox,
  BuildingStore,
  Inbox,
  Users,
  ArrowBarLeft,
  ArrowBarRight,
  Download,
  FileSpreadsheet,
  FileAnalytics,
  File3d,
  ChartBubble,
  ChevronDown,
  ChevronUp,
} from "tabler-icons-react";
import storeContext from "../store/store";
import useElemetHeight from "../hooks/useElementHeight";
import useElementSize from "../hooks/useElementSize";
import ValueAndComparisonToAverage from "./ValueAndComparisonToAverage";
import AIChatWindow from "./AIChatWindow";
import {
  downloadSummary,
  downloadMapAsBase64,
} from "../map-utils/downloadSummary";
import { useAuth } from "../hooks/useAuth";
import {
  Dataset,
  FilterData,
  FilterDataWithResponses,
  FilterType,
  SubCategory,
  Table,
} from "./filterbar-utils/filtertypes";

const useStyles = createStyles((theme) => ({
  show: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      transform: "translateX(0%);",
    },
  },
  hide: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      transform: "translateX(100%);",
    },
  },
  summaryToggleBtn: {
    display: "none",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      transform: "translateX(-0%);",
      display: "block",
      height: "37px",
      position: "fixed",
      top: "37.3%",
      right: "0",
      padding: "3px ",
      width: "33px",
      zIndex: 1000,
      backgroundColor: theme.colors.allColors[2],
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
    },
  },
}));

type Context = {
  state: any;
  dispatch: any;
};

function downloadAsCSV(context: Context, user: any) {
  downloadSummary("CSV", context, user);
}

async function downloadAsWord(context: Context, user: any, dispatch: any) {
  dispatch({ type: "setReportGenerating", payload: true });
  try {
    await downloadSummary("Word", context, user);
  } catch (e) {
    console.log(e);
  }
  dispatch({ type: "setReportGenerating", payload: false });
}

function downloadSA1sData(context: Context, user: any) {
  downloadSummary("sa1s", context, user);
}

interface SummarySectionProps {
  content: React.ReactNode;
  title: string;
}

const SummarySection: React.FC<SummarySectionProps> = ({ content, title }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(true);

  return (
    <Flex w="100%" direction="column" pt="16px">
      <Flex align="center" justify="space-between" direction="row" w="100%">
        <Flex pl="10px" pt="4px" pb="4px">
          <Text ta="left" fw={700} fz="md" tt="capitalize">
            {title}
          </Text>
        </Flex>
        <ActionIcon
          onClick={() => setIsDropDownOpen(!isDropDownOpen)}
          sx={(theme) => ({
            color: theme.colors.allColors[7],
            "&:hover": {
              color: "#f76707",
            },
          })}
        >
          {isDropDownOpen ? <ChevronUp /> : <ChevronDown />}
        </ActionIcon>
      </Flex>
      <Collapse
        in={isDropDownOpen}
        style={{
          width: "100%",
          padding: "0px 0px 0px 10px",
        }}
      >
        {content}
      </Collapse>
    </Flex>
  );
};

const buildSummaryValueComparison = (context: Context) => {
  const data: Dataset = context.state.summaryDataNewAPI;

  if (data === undefined || !data.tables) {
    return (
      <>
        {
          // Skeleton for loading
          Array.from({ length: 5 }).map((_, index) => (
            <Fragment key={index}>
              <Flex w="100%" direction="column" pt="16px">
                <Skeleton height={24} width="50%" mb={16} pt="16px" />
                <Skeleton height={16} mb={8} />
                <Skeleton height={16} mb={8} />
                <Skeleton height={16} mb={8} />
                <Skeleton height={16} mb={8} />
              </Flex>
            </Fragment>
          ))
        }
      </>
    );
  }
  //console.log("data.tables", data.tables);
  return (
    <Fragment>
      {data.tables.map((table, index) => {
        return (
          <Fragment key={index}>
            <Flex pl="4px" pt="16px" pb="8px">
              <Text ta="left" fw={700} fz="lg" tt="capitalize">
                {table.category_title}
              </Text>
            </Flex>

            {table.sub_categories.map((subCategory: SubCategory, idx) => {
              return (
                <Fragment key={idx}>
                  <SummarySection
                    title={subCategory.sub_category_title}
                    content={
                      <Fragment>
                        {subCategory.filters.map((filter, idx) => {
                          return subCatList(filter, idx);
                        })}
                      </Fragment>
                    }
                  />
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

const subCatList = (filter: FilterType, idx: number) => {
  if ("responses" in filter) {
    // Handle filters with responses
    const filterData = filter as FilterDataWithResponses;
    return filterData.responses
      .sort((a, b) => b.area - a.area)
      .slice(0, filterData.top_answers)
      .map((response, resIdx) => (
        <ValueAndComparisonToAverage
          key={`${idx}_${resIdx}_${response.filter_name}`} // Use a unique key including response index
          title={response.filter_name}
          value={response.area * 100}
          stateAverageDiff={response.state * 100}
          nationalAverageDiff={response.national * 100}
          prefixChar={""}
          suffixChar={"%"}
        />
      ));
  } else {
    // Handle regular filters
    const filterData = filter as FilterData;
    return (
      <ValueAndComparisonToAverage
        key={`${idx}_${filterData.filter_title}`} // Ensure the key is unique
        title={filterData.filter_title}
        value={filterData.value ?? 0}
        stateAverageDiff={filterData.state ?? 0}
        nationalAverageDiff={filterData.national ?? 0}
        prefixChar={filterData.units === "$" ? "$" : ""}
        suffixChar={filterData.units === "%" ? "%" : ""}
      />
    );
  }
};

const SummaryBar = () => {
  // const [summaryData, setSummaryData] = useState<any>([]);
  const [showHideOnSmScreen, setShowHideOnSmScreen] = useState<boolean>(false);
  const [interogationChatOpened, setInterogationChatOpened] = useState(false);

  const context = useContext<any>(storeContext);
  const { state, dispatch } = context;

  const elementHeight = useElemetHeight();
  // calling hook by passing element Id to get width and height of that element.
  const { height } = useElementSize("header-main");
  const { classes } = useStyles();
  const { user } = useAuth();

  const premiumTierSubscription = true;
  // user &&
  // user.subscriptionDetails &&
  // user.subscriptionDetails.subscription_name !== "Trial" &&
  // user.subscriptionDetails.subscription_name !== "Starter";

  const properties = {
    polygonList: state.mapPolygons,
    paddingPercent: [25, 25, 25, 25],
    context: { state, dispatch },
  };

  return (
    <>
      {state.showSummaryBar ? (
        <>
          <ActionIcon
            onClick={() => {
              setShowHideOnSmScreen(!showHideOnSmScreen);
            }}
            className={classes.summaryToggleBtn}
          >
            {showHideOnSmScreen ? (
              <ArrowBarRight color="#fff" />
            ) : (
              <ArrowBarLeft color="#fff" />
            )}
          </ActionIcon>
          <Aside
            className={`${showHideOnSmScreen ? classes.show : classes.hide}`}
            hiddenBreakpoint="sm"
            sx={(theme) => ({
              height: `calc(100vh  - ${height}px)`,
              top: `${height}px)`,
              overflowY: "auto",
              overflowX: "hidden",
              transition: "0.3s all ease-in-out",
              width: "100%",
              maxWidth: "350px",
              [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                position: "fixed",
                top: `${height}px !important`,
                maxWidth: "300px",
              },
              [`@media (max-width: 425px)`]: {
                maxWidth: "250px",
              },
            })}
          >
            <Grid>
              <Grid.Col span={8} mb="md">
                <Title
                  order={6}
                  p="lg"
                  align="left"
                  style={{ textTransform: "uppercase" }}
                >
                  Summary
                </Title>
              </Grid.Col>
              <Grid.Col
                span={4}
                mb="md"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Menu
                  styles={{
                    itemIcon: { color: "#414042" },
                    itemLabel: { color: "#414042" },
                  }}
                >
                  <Menu.Target>
                    <Tooltip
                      label="Currently in Beta"
                      position="left"
                      withArrow
                    >
                      <Button
                        color="#FF8E16"
                        sx={(theme) => ({
                          backgroundColor: theme.colors.allColors[7],
                          "&:hover": {
                            backgroundColor: "#f76707",
                          },
                        })}
                      >
                        <Download />
                      </Button>
                    </Tooltip>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Label>Download formats</Menu.Label>

                    <Menu.Item
                      icon={<File3d size={14} />}
                      onClick={() => {
                        downloadSA1sData(context, user);
                      }}
                    >
                      Detailed Report
                    </Menu.Item>
                    <Menu.Item
                      icon={<FileSpreadsheet size={14} />}
                      onClick={() => {
                        downloadAsCSV(context, user);
                      }}
                    >
                      Summary CSV
                    </Menu.Item>
                    <Menu.Item
                      icon={<FileAnalytics size={14} />}
                      onClick={() => {
                        downloadAsWord(context, user, dispatch);
                      }}
                    >
                      MS Word
                    </Menu.Item>
                    <Tooltip
                      label={"Only available to premium subscribers"}
                      withArrow
                    >
                      <Menu.Item
                        icon={<ChartBubble size={14} />}
                        onClick={() => {
                          setInterogationChatOpened(true);
                        }}
                        disabled={!premiumTierSubscription}
                      >
                        AI Interaction
                      </Menu.Item>
                    </Tooltip>
                    {/*<Menu.Item 
                    icon={<Photo size={14} />}
                    onClick={() => {
                      downloadMapAsBase64(state, properties); // FOR DEBUGGING MAPS ONLY
                      console.log("Base64Test");
                    }
                    }>
                    Base64Test
                  </Menu.Item>?*/}
                  </Menu.Dropdown>
                </Menu>
                <Dialog
                  opened={interogationChatOpened && premiumTierSubscription}
                  onClose={() => setInterogationChatOpened(false)}
                  radius="md"
                  withCloseButton={true}
                  size={400}
                  position={{ top: 150, right: 350 }}
                >
                  <AIChatWindow />
                </Dialog>
              </Grid.Col>
            </Grid>

            <Divider />
            <Grid p="md" pb="lg" pt="lg" my={0}>
              <Grid.Col span={6} mb="md">
                <div>
                  <LoadingOverlay
                    visible={state.summaryDataLoading || state.reportGenerating}
                    loaderProps={{ size: "xl", color: "orange" }}
                    overlayOpacity={0.3}
                  />
                </div>

                <Group spacing="xs" align={"flex-start"}>
                  <span>
                    <Mailbox size={19} strokeWidth={1} color={"black"} />
                  </span>
                  <Stack spacing="sm">
                    <span>
                      <Text size="sm">Residential</Text>
                    </span>
                    <Title order={2}>
                      {state.letterBoxData?.residential_count || 0}
                    </Title>
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span={6}>
                <Group spacing="xs" align={"flex-start"} position="right">
                  <span>
                    <BuildingStore size={19} strokeWidth={1} color={"black"} />
                  </span>
                  <Stack spacing="sm">
                    <span>
                      <Text size="sm">Businesses</Text>
                    </span>
                    <Title order={2}>
                      {state.businessesCount?.businesses_count || 0}
                    </Title>
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span={6}>
                <Group spacing="xs" align={"flex-start"}>
                  <span>
                    <Inbox size={19} strokeWidth={1} color={"black"} />
                  </span>
                  <Stack spacing="sm">
                    <span>
                      <Text size="sm">Letterboxes</Text>
                    </span>
                    <Title order={2}>
                      {state.letterBoxCountPAF?.letterbox_count_PAF || 0}
                    </Title>
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span={6}>
                <Group spacing="xs" align={"flex-start"} position="right">
                  <span>
                    <Users size={19} strokeWidth={1} color={"black"} />
                  </span>
                  <Stack spacing="sm">
                    <span>
                      <Text size="sm">Population</Text>
                    </span>
                    <Title order={2}>
                      {state.summaryDataNewAPI.population || 0}
                    </Title>
                  </Stack>
                </Group>
              </Grid.Col>
            </Grid>
            <Divider />

            <Grid p="lg" pb="lg" pt="lg" my={0}>
              {state.datarequeststate > 0 && state.datarequeststate != 100 && (
                <Flex w={"100%"} align={"center"} gap={"1rem"}>
                  <Text size="sm" style={{ color: "gray" }}>
                    Updating Data:
                  </Text>{" "}
                  <Progress
                    color="orange"
                    radius="xl"
                    size="sm"
                    animate
                    value={state.datarequeststate}
                    sx={{ flex: "1" }}
                    striped
                  />
                </Flex>
              )}
              {
                // use list to control the order and visibility of the summary values
                buildSummaryValueComparison(context)
              }
            </Grid>
          </Aside>
          {/* To export the unsplit polygons, without displaying the modal: */}
          {/* <ExportMapModal polygonList={state.polygons} display={false}/> */}
        </>
      ) : (
        <div
          style={{
            height: `calc(100vh - var(--mantine-header-height, 0px) - ${elementHeight}px)`,
          }}
        ></div>
      )}
    </>
  );
};

export default SummaryBar;
