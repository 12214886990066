import { useReducer, useEffect, useContext } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import storeContext from "../../store/store";

// Define the steps
const TOUR_STEPS = [
  {
    target: "#drawing-tools",
    content: (
      <div className="step-1">
        <h4
          style={{
            textAlign: "center",
            margin: "4px 3px",
            color: "#FF8E16",
          }}
        >
          Select Tool!
        </h4>
        <div>You can select any of these tools to start drawing on map.</div>
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    spotlightClicks: true,
    style: {
      option: {
        margin: 0,
        padding: 0,
      },
    },
  },
  {
    target: ".map",
    content: (
      <div className="step-2">
        <h4
          style={{
            textAlign: "center",
            margin: 0,
            padding: 0,
            color: "#FF8E16",
          }}
        >
          Start Drawing!
        </h4>
        <div>
          Click or drag mouse on map to draw polygon, circle, measurment and
          marker.
        </div>
      </div>
    ),
    disableBeacon: false,
    disableOverlayClose: true,
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    placement: "center",
  },
  {
    target: "#save-btn",
    content: (
      <div className="step-3">
        <h4
          style={{
            textAlign: "center",
            margin: 0,
            padding: 0,
            color: "#FF8E16",
          }}
        >
          Save Map!
        </h4>
        <div>
          You can now save the map by clicking on save button if you have
          completed your drawing.
        </div>
      </div>
    ),
  },
  {
    target: "#toggle-menu",
    content: (
      <div className="step-4">
        <h4
          style={{
            textAlign: "center",
            margin: 0,
            padding: 0,
            color: "#FF8E16",
          }}
        >
          More map options...
        </h4>
        <div>
          There's more! You can import a KML file, export your map, collaborate with colleagues, export addresses, show business locations and more.
        </div>
      </div>
    ),
  },
];

// Define our state
const INITIAL_STATE = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Set up the reducer function
const reducer = (stateOfTour = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case "START":
      return { ...stateOfTour, run: true };
    case "RESET":
      return { ...stateOfTour, stepIndex: 0 };
    case "STOP":
      return { ...stateOfTour, run: false };
    case "NEXT_OR_PREV":
      return { ...stateOfTour, ...action.payload };
    case "RESTART":
      return {
        ...stateOfTour,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return stateOfTour;
  }
};

// Define the Tour component
export const MainMapTour = () => {
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { state } = useContext<any>(storeContext);

  useEffect(() => {
    if (!localStorage.getItem("tour")) {
      dispatch({ type: "START" });
    }
  }, [state.startTour]);

  const callback = (data: any) => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
    window.localStorage.setItem(
      "tour",
      JSON.stringify({ action, index, type, status })
    );
  };

  // const startTour = () => {
  //   dispatch({ type: "RESTART" });
  // };

  return (
    <>
      {/* <button className="btn btn-primary" onClick={startTour}>
        Start Tour
      </button> */}
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={true}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          tooltipFooter: {
            margin: 0,
            padding: 0,
          },
          tooltipContent: {
            padding: "18px 10px",
          },

          buttonBack: {
            marginRight: 10,
            backgroundColor: "#FF8E16",
            color: "#ffffff",
            borderRadius: "3px",
          },
          buttonNext: {
            backgroundColor: "#FF8E16",
          },
        }}
        locale={{
          last: "End tour",
        }}
      />
    </>
  );
};

export default MainMapTour;
