import React, { FC, useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { AppShell, Drawer, useMantineTheme, Modal, Button, Group, createStyles } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import HeaderMain from "../components/HeaderMain";
import FiltersBar from "../components/FiltersBar";
import SummaryBar from "../components/SummaryBar";
import Navbar from "../components/Navbar";
import HeatMap from "../components/HeatMap";
import MapWrapper from "../components/MapWrapper";
import storeContext from "../store/store";
import MainMapTour from "../components/tours/MainMapTour";
import ExportMapModal from "../components/report-export-components/ExportMapModal";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";

const dummyLinks = [
  { link: "/about", label: "Features" },
  { link: "/pricing", label: "Pricing" },
  { link: "/learn", label: "Learn" },
  { link: "/community", label: "Community" },
];

interface MainProps {
  isSharedMap: boolean;
  isLGAMap: boolean;
  isSALMap: boolean;
  isSA1Map: boolean;
  loggedIn: boolean;
}

const useStyles = createStyles((theme: any) => ({

  customDrawer: {
    height: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: "rgb(65, 64, 66)",
  },

  drawerCloseButton: {
    // Set color scheme sensitive color
    color: "#fff",

    '&:hover': {
      color: theme.colors.gray[3],
      backgroundColor: theme.colors.gray[7],
    },

    // Set size
    '&:svg': {
      width: "1.5rem",
      height: "1.5rem",
    },

    // Set absolute position with z-index
    position: "absolute",
    right: "1rem",
    top: "1.5rem",
    zIndex: 2222,
  },

  header: {
    // set to background color of the header to invisible
    backgroundColor: "transparent",
  }

}));


const Main: FC<MainProps> = ({ isSharedMap, isLGAMap, isSALMap, isSA1Map, loggedIn, }) => {
  const { state, dispatch } = useContext<any>(storeContext);
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [openFiltersBar, setOpenFiltersBar] = useState(false);
  const [openNavbar, setOpenNavbar] = useState(false);
  const [openHeatMap, setOpenHeatMap] = useState(false);
  const { user } = useAuth();
  const [disableApplyFilters, setDisableApplyFilters] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setDisableApplyFilters(isSharedMap);
    if (user && user.subscriptionDetails.subscription_name === 'Trial' && user.subscriptionDetails.subscription_expires < Date.now() / 1000) {
      dispatch({ type: "setTrialExpired", payload: true });
      console.log('Trial expired!!!!!!');
    }
    else
      dispatch({ type: "setTrialExpired", payload: false });
    if (user && user.subscriptionDetails.subscription_expires < Date.now() / 1000) {
      dispatch({ type: "setSubscriptionExpired", payload: true });
      console.log('Subscription expired!!!!!!');
    }
    else
      dispatch({ type: "setSubscriptionExpired", payload: false });

  }, [dispatch, user?.key, state.jobName]);

  const toggleNavbar = () => {
    setOpenNavbar(() => !openNavbar);
  };

  const toggleFiltersBar = () => {
    if (openHeatMap) {
      setOpenHeatMap(() => false);
      setOpenFiltersBar(() => true);
      dispatch({ type: "setSplitPolygonVisibility", payload: true });
    } else {
      dispatch({ type: "setSplitPolygonVisibility", payload: !openFiltersBar });
      setOpenFiltersBar(() => !openFiltersBar);
    }
  };

  const toggleHeatMap = () => {
    if (openFiltersBar) {
      setOpenFiltersBar(() => false);
      setOpenHeatMap(() => true);
      dispatch({ type: "setSplitPolygonVisibility", payload: true });
    } else {
      dispatch({ type: "setSplitPolygonVisibility", payload: !openHeatMap });
      setOpenHeatMap(() => !openHeatMap);
    }
  };

  // setOpened={() => setOpenFiltersBar((open: boolean) => !openFiltersBar)}
  return (
    <AppShell
      padding={0}
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          paddingLeft: openNavbar ? 0 : "0",
          position: "relative",
        },
        body: {
          height: "calc(100vh - 5.8125rem)",
          [`@media (max-width: ${theme.breakpoints.sm}rem)`]: {
            height: "calc(100vh - 6.875rem)",
          },
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed={false}
      navbar={
        <>
          {" "}
          {loggedIn && <Drawer
            size={300}
            opened={openNavbar}
            onClose={() => setOpenNavbar(false)}
            overlayProps={{
              opacity: 0.25,
              color: theme.colorScheme === "dark" ? theme.colors.dark[9] : "#292A2C"
            }}
            classNames={{
              header: classes.header,
              content: classes.customDrawer,
              close: classes.drawerCloseButton,
            }}
          >
            <Navbar
              opened={false}
              setOpenedNavbar={() => setOpenNavbar(false)}
            />
          </Drawer>}

          <FiltersBar
            openFiltersBar={openFiltersBar}
            openHeatMap={openHeatMap}
            disableApplyFilters={disableApplyFilters}
          />
          <HeatMap openHeatMap={openHeatMap} openFiltersBar={openFiltersBar} />
        </>
      }
      aside={<SummaryBar />}
      header={
        <HeaderMain
          links={dummyLinks}
          toggleNavbar={toggleNavbar}
          toggleFiltersBar={toggleFiltersBar}
          toggleHeatMap={toggleHeatMap}
          showLinks={true}
        />
      }
    >
      {state.trialExpired && (
        <Modal
          opened={true}
          closeOnClickOutside={false}
          onClose={() => { }}
          closeOnEscape={false}
          withCloseButton={false}
          title="Your trial is expired"
        >
          <p>
            Your trial is expired. You will need to get a subscription in order to continue using Scopomap.
          </p>
          <Group position='center' pt={5}>
            <Link to="/settings">
              <Button style={{ minWidth: '14.375rem' }} variant="filled">Subscription settings</Button>
            </Link>
          </Group>
        </Modal>
      )}
      {state.subscriptionExpired && (
        <Modal
          opened={true}
          closeOnClickOutside={false}
          onClose={() => { }}
          closeOnEscape={false}
          withCloseButton={false}
          title="Your subscription is expired"
        >
          <p>
            Your subscription is expired. You will need to renew your subscription in order to continue using Scopomap.
          </p>
          <Group position='center' pt={5}>
            <Link to="/settings">
              <Button style={{ minWidth: '14.375' }} variant="filled" >Subscription settings</Button>
            </Link>
          </Group>
        </Modal>
      )}
      <MapWrapper />
      {loggedIn && 
      <MainMapTour />
      }
      {/* To export the unsplit polygons, without displaying the modal: */}
      {/* <ExportMapModal polygonList={state.polygons} display={false}/> */}
      <ExportMapModal polygonList={state.mapPolygons} display={state.viewExportMapModal.visible} />
    </AppShell>
  );
};

export default Main;
