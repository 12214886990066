import { FC } from "react";
import { useForm } from "@mantine/form";
import {
  Grid,
  TextInput,
  Text,
  Group,
  Button,
  createStyles,
  Avatar,
  Textarea,
  Stack,
} from "@mantine/core";
import "../css/login.css";
import BrandSection from "../components/BrandSection";
import { Check, Messages } from "tabler-icons-react";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import AppShellLayout from "../components/AppShellLayout";

const useStyles = createStyles((theme) => ({
  mainBox: {
    maxWidth: "350px",
    width: "100%",
  },
  subText: {
    margin: "0",
    width: "100%",
    fontSize: "16px",
    color: "#222B45",
    textAlign: "center",
  },
}));
const RequestDemoOrQuote: FC = () => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      name: "",
      organisation: "",
      phoneNum: "",
      email: "",
      message: "",
    },

    validate: {
      name: (value) =>
        value.length < 3 ? "Your name must be at least 3 characters long." : null,
      organisation: (value) =>
        value.length < 2 ? "Your organisation name must be at least 2 characters long." : null,
      email: (value) =>
        value === ""
          ? "Email must not be empty."
          : /^\S+@\S+$/.test(value)
          ? null
          : "Invalid email",
      phoneNum: (value) =>
        value === ""
          ? "Email must not be empty."
          : /^(\+)?([\d\s\.\-]{7,15}?)$/.test(value)
          ? null
          : "Invalid phone number",
      message: (value) =>
        value.length < 4 ? "Message must be at least 4 characters long." : null,
    },
  });
  const handleSubmit = async (values: any) => {
    let formdata = new FormData();
    try {
      formdata.append("name", values.name);
      formdata.append("organisation", values.organisation);
      formdata.append("email", values.email);
      formdata.append("phone_number", values.phoneNum);
      formdata.append("message", values.message);
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}book_demo_or_request_quote/`,
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        showNotification({
          id: "contact-us-message",
          title: "Success!",
          message: `Your message sent!`,
          autoClose: 5000,
          color: "green",
          icon: <Check />,
          styles: (theme) => ({
            root: {
              backgroundColor: theme.white,
              borderColor: "green",

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: "black" },
            description: { color: "black" },
            closeButton: {
              color: theme.black,
            },
          }),
        });
        form.setValues({
          name: "",
          organisation: "",
          email: "",
          phoneNum: "",
          message: "",
        });
      }
      console.log("response", response);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <AppShellLayout>
      <Grid p={0} m={0}>
        <Grid.Col p={0} className="left-container">
          <div className="log-left">
            <div className={classes.mainBox}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 15,
                }}
              >
                <Avatar
                  className="avatar"
                  styles={{ placeholder: { backgroundColor: "#fd7e14" } }}
                >
                  <Messages
                    strokeWidth={2}
                    color={"white"}
                    className="icon-main"
                  />
                </Avatar>
              </div>
              <Text
                size={"lg"}
                className="login-text"
                style={{
                  marginBottom: "15px",
                  fontSize: "33px",
                  textAlign: "center",
                }}
              >
                Book a Demo or Request a Quote
              </Text>
              <Text size="md" weight={300} className="sub-text">
                Send us a message if you would like a demo of Scopomap for you and you organisation (online an option), or a quote for a subscription.
              </Text>
              <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <Stack>
                  <TextInput
                    type="text"
                    placeholder="Your name"
                    value={form.values.name}
                    mt={20}
                    onChange={(event) =>
                      form.setFieldValue("name", event.currentTarget.value)
                    }
                    error={form.errors.name}
                  />
                  <TextInput
                    type="text"
                    placeholder="Your company or organisation"
                    value={form.values.organisation}
                    onChange={(event) =>
                      form.setFieldValue("organisation", event.currentTarget.value)
                    }
                    error={form.errors.organisation}
                  />
                  <TextInput
                    type="text"
                    placeholder="Email"
                    value={form.values.email}
                    onChange={(event) =>
                      form.setFieldValue("email", event.currentTarget.value)
                    }
                    error={form.errors.email}
                  />
                  <TextInput
                    type="text"
                    placeholder="Phone number"
                    value={form.values.phoneNum}
                    onChange={(event) =>
                      form.setFieldValue("phoneNum", event.currentTarget.value)
                    }
                    error={form.errors.phoneNum}
                  />
                  <Textarea
                    placeholder="Message"
                    autosize
                    minRows={4}
                    value={form.values.message}
                    onChange={(event) =>
                      form.setFieldValue("message", event.currentTarget.value)
                    }
                    error={form.errors.message}
                  />
                  <Button type="submit" color={"orange"} mt={10}>
                    Submit
                  </Button>
                </Stack>
              </form>
            </div>
          </div>
        </Grid.Col>
      </Grid>
    </AppShellLayout>
  );
};

export default RequestDemoOrQuote;
