import {
  Modal,
  Input,
  Button,
  Stack,
  Checkbox,
  Badge,
  Flex,
  Title,
  Divider,
  HoverCard,
  Text,
  ActionIcon,
  rem,
  Loader,
} from "@mantine/core";
import { useContext, useState } from "react";
import storeContext from "../../store/store";
import { saveDemographicsSettings } from "../../map-utils/filterbarAPI";
import { useAuth } from "../../hooks/useAuth";
import { HoverCardTarget } from "@mantine/core/lib/HoverCard/HoverCardTarget/HoverCardTarget";
import { X } from "tabler-icons-react";
import { setCheckedOutCatalog } from "../FiltersBar";
import { showNotification } from "@mantine/notifications";

interface FilterTable {
  sub_categories: {
    sub_category_title: string;
    filters: any[];
  }[];
}

const removeButton = (
  <ActionIcon size="xs" color="blue" radius="xl" variant="transparent">
    <X size={rem(10)} />
  </ActionIcon>
);

const FilterSaveModal: React.FC = () => {
  const { state, dispatch } = useContext<any>(storeContext);
  const [filterName, setFilterName] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [loading, setLoading] = useState(false); // State for loader
  const { user } = useAuth();

  const removeButton = (
    <ActionIcon size="xs" radius="xl" variant="transparent">
      <X size={rem(10)} />
    </ActionIcon>
  );

  const removeFilter = (filter: any) => {
    console.log(filter);
  };

  const generatePills = (filterList: FilterTable[]) => {
    if (filterList.length < 1)
      return (
        <Badge variant="outline" color="red">
          No filters selected
        </Badge>
      );

    return filterList.flatMap((table) =>
      table.sub_categories.map((subCategory) => (
        <HoverCard
          key={subCategory.sub_category_title}
          position="bottom"
          shadow="sm"
          transitionProps={{ duration: 150, transition: "fade" }}
        >
          <HoverCard.Target>
            <Badge variant="outline" key={subCategory.sub_category_title}>
              {subCategory.sub_category_title}
            </Badge>
          </HoverCard.Target>
          <HoverCard.Dropdown
            m="sm"
            style={{ maxWidth: "350px", backgroundColor: "#FFE8CC" }}
          >
            <Flex gap="sm" wrap="wrap">
              {subCategory.filters.map((filter) => (
                <Badge variant="filled" key={filter.filter_title}>
                  {filter.filter_title}
                </Badge>
              ))}
            </Flex>
          </HoverCard.Dropdown>
        </HoverCard>
      ))
    );
  };

  const saveFilterListHandler = async () => {
    const filtersList = state.checkedFilters;
    let pythonDefault = isDefault ? "True" : "False";
    const data = {
      demographicsSettingsJson: JSON.stringify(filtersList),
      settingsName: filterName,
      organisationDefault: pythonDefault,
    };

    try {
      setLoading(true); // Start the loader
      await saveDemographicsSettings(data, `${user.key}`);
      setCheckedOutCatalog(user.key, state, dispatch, "");
      showNotification({
        title: "Success",
        message: "Settings saved successfully!",
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to save settings. Please try again.",
        color: "red",
      });
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  const handleSaveFilter = () => {
    saveFilterListHandler();
    handleClose();
  };

  const handleClose = () => {
    dispatch({ type: "setDemographicSaveOpen", payload: false });
  };

  return (
    <Modal
      opened={state.demographicSaveVisible}
      onClose={() => handleClose()}
      title="Save Demographic Selection"
      size="lg"
      zIndex={1000}
      overlayProps={{
        blur: 1,
      }}
    >
      <Stack spacing="xl">
        <Stack spacing="sm">
          <Flex wrap="wrap" direction="column" pb="md" gap="sm">
            <Title order={6}>Selected categories:</Title>
            <Flex wrap="wrap" gap="xs">
              {generatePills(
                Object.keys(state.checkedFilters).length > 0
                  ? state.checkedFilters
                  : []
              )}
            </Flex>
          </Flex>

          <Input
            placeholder="Setting Name"
            value={filterName}
            onChange={(event) => setFilterName(event.currentTarget.value)}
            maxLength={48}
          />
          <Text size="xs" color="gray">
            {filterName.length}/48 characters used
          </Text>
        </Stack>

        <Stack>
          <Divider />
          <Flex justify="space-between" align="center" direction="row" gap="md">
            <Checkbox
              checked={isDefault}
              onChange={() => setIsDefault(!isDefault)}
              label="Set as default"
            />
            <Button
              color="orange"
              variant="filled"
              onClick={() => handleSaveFilter()}
              disabled={loading}
            >
              {loading ? <Loader size="sm" /> : "Save Settings"} {/* Spinner */}
            </Button>
          </Flex>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default FilterSaveModal;
