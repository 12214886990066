// import React from "react";
import { useContext, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import storeContext from "../store/store";
import Main from "./Main";

const Home = () => {
  const { user } = useAuth();
  const { state, dispatch } = useContext<any>(storeContext);

  useEffect(() => {
   dispatch({type: 'resetAllLetterboxCounts', payload: null});
  }, [state.jobId]);

  return (
    // <div>Home</div>
    <Main isSharedMap={false} isLGAMap={false} isSALMap={false} isSA1Map={false} loggedIn={user} />
  );
};

export default Home;
